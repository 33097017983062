import React from 'react';
import styles from './Footer.module.css';
import { getCurrentResource } from '../../util/tools';

const Footer = ({ ...props }) => {
	var resource = getCurrentResource().components.footer;
	return (
		<div className={styles.divMain}>
			{resource.title}
		</div>
	)
}


export default Footer;
