import React from 'react';
import stylesPlain from './plain.module.css';

import { getCurrentResource, getDefinedValue } from '../../util/tools';
import { IET_ADD_LIST_ITEM, IET_DELETE_LIST_ITEM } from '../../util/realm/instance';

import iconAdd from '../../assets/icons/png24x24/Add.png';
import iconMoveUp from '../../assets/icons/png24x24/Up.png';
import iconMoveDown from '../../assets/icons/png24x24/Down.png';
import iconDelete from '../../assets/icons/png24x24/Delete.png';

class FieldList_TableGroup extends React.Component {

	field = null;
	disableMoveItems = false;

	/*
		plain
		plainDirtyCounter:         mandatory
		instance                   mandatory
		parentField
		path
		readOnly
		title
		cell
		disableMoveItems
		table
			columns [{
				columnId
				title
				tdHeaderStyle
			}]
			width
			styles
				table
				thead
				trHeader
	*/
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.plain.FieldList_TableGroup;
		if( props.disableMoveItems )
			this.disableMoveItems = props.disableMoveItems;
		this.init();
		this.state = {
			value: this.field.value,
			selectedIndex: -1,
			readOnly: false
		}
	}
	componentDidUpdate(prevProps) {
		var doInit = false;
		doInit = doInit || prevProps.instance !== this.props.instance;
		doInit = doInit || prevProps.parentField !== this.props.parentField;
		doInit = doInit || prevProps.path !== this.props.path;
		if ( doInit ) {
			this.init();
			this.setState({ value: this.field.value });
		}
		if( prevProps.readOnly !== this.props.readOnly )
			this.setState({ readOnly: getDefinedValue( this.props.readOnly, false) });
	}
	componentDidMount() {
		if( this.props.instance ) 
			this.props.instance.addChangeListener( this );
	}
	componentWillUnmount() {
		if( this.props.instance ) 
			this.props.instance.removeChangeListener( this );
  	}
	init = () => {
		if( this.props.instance ) {
			if( this.props.parentField )
				this.field = this.props.instance.getField(this.props.path, this.props.parentField );
			else
				this.field = this.props.instance.getField(this.props.path);
		}
	}

	handleInstanceChange = async (event) => {
		if( event.eventType == IET_ADD_LIST_ITEM && event.field == this.field) {
			var index = this.field.value.length-1;
			this.setState({
				value: this.field.value,
				selectedIndex: index
			});
		} else if( event.eventType == IET_DELETE_LIST_ITEM && event.field == this.field) {
			var index = event.itemIndex;
			if( index >= this.field.value.length )
				index--;
			this.setState({
				value: this.field.value,
				selectedIndex: index
			});
		}
	}
	addItem = () => {
		this.props.instance.addListItem(this.field);
	}
	deleteItem = () => {
		var index = this.state.selectedIndex;
		if( index < 0 || index >= this.field.value.length )
			return;
			
		// TODO show confirmation dialog
		this.props.instance.deleteListItem(this.field, index);
	}	

	handleRowClick = (index) => {
		this.setState({selectedIndex: index})
	}

	render() {
		var headerCellsHtml = this.props.table.columns.map((col) => {
			return (
				<td key={col.columnId} style={col.tdHeaderStyle}>
					{col.title}
				</td>
			)
		});
		var rowsHtml = this.field.value.map((itemField, index) => {
			var trStyle = {backgroundColor: 'lightGray'};
			if( index == this.state.selectedIndex )
				trStyle.backgroundColor = 'lightBlue';
			var cellsHtml = this.props.table.columns.map((col) => {
				return (
					<td key={col.columnId}>
						<this.props.cell 
							instance={this.props.instance} columnId={col.columnId} rowIndex={index} 
							plain={this.props.plain} plainDirtyCounter={this.props.plainDirtyCounter} 
							fieldGroup={itemField}
						/>
					</td>
				)
			});
			return (
				<tr key={itemField.fieldId} style={trStyle} onClick={ () => {this.handleRowClick(index)} }>
					{cellsHtml}
				</tr>
			)
		});
		var tableStyle = {...this.props.table.styles.table};
		tableStyle.width = this.props.table.width;
		if(this.props.header) {
			var headerHtml = (
				<this.props.header table={this} />
			)
		} else {
			var moveItemsButtonsHtml = null;
			if( ! this.disableMoveItems ) {
				moveItemsButtonsHtml = (
					<React.Fragment>
						<button type='button' className={stylesPlain.buttonIcon} style={{marginLeft: '5px'}}
							onClick={this.MoveUpItem}
						>
							<img src={iconMoveUp} />
						</button>
						<button type='button' className={stylesPlain.buttonIcon} style={{marginLeft: '5px'}}
							onClick={this.MoveDownItem}
						>
							<img src={iconMoveDown} />
						</button>
					</React.Fragment>
				)
			}
			var divHeaderStyle = {display: 'flex', alignItems: 'center', marginBottom: '5px'};
			divHeaderStyle.width = this.props.table.width;
			var headerHtml = (
				<div style={divHeaderStyle}>
					<div style={{flexGrow: '0', marginRight: '10px'}}>{this.props.title}</div>
					<div>
						<button type='button' className={stylesPlain.buttonIcon}
							onClick={this.addItem} title={this.resource.buttonAdd}
						>
							<img src={iconAdd} />
						</button>
						{moveItemsButtonsHtml}
						<button type='button' className={stylesPlain.buttonIcon} style={{marginLeft: '15px'}}
							onClick={this.deleteItem}
						>
							<img src={iconDelete} />
						</button>
					</div>
				</div>
			)
		}
		return (
			<div>
				{headerHtml}
				<table border='1' style={tableStyle}>
					<thead style={this.props.table.styles.thead}>
						<tr style={this.props.table.styles.trHeader}>
							{headerCellsHtml}
						</tr>
					</thead>
					<tbody>
						{rowsHtml}
					</tbody>
				</table>
			</div>
		);
	}

}

export default FieldList_TableGroup;
