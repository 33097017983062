import React, { Component } from 'react';
import styles from '../../components/fulldescription/FullDescription.module.css';

import PlainFullDescription from '../../components/fulldescription/PlainFullDescription';
import ModalMessage from '../../components/message/ModalMessage';
import { getCurrentResource } from '../../util/tools';
import { USER_PATH } from '../../util/routes';
//import { performPlainSaveInstance , PLAIN_SAVEINST_API_URL} from '../../util/restapi';
import { FORM_USER_FT } from '../../util/consts/forms';
import { CLASS_USER } from '../../util/consts/classes';

class UserPage extends Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().pages.plain.User;
		this.plainInfo = {
			app: props.app,
			page: this,
			class: CLASS_USER,
			instanceModelId: FORM_USER_FT,
			instanceId: this.props.match.params.id,
			param: {}
		};
		this.messageHandler = {};
	}

/*
	saveForm = async () => {
		console.log( 'User save form' );
		var response = await performPlainSaveInstance(this.plainInfo.instanceModelId, this.plainInfo.instance);
		if( response.success ) {
			var instance = response.data;
			window.open( USER_PATH + '/' + instance.id, '_self' )
		} else {
			this.messageHandler.showMessage( response.message );
		}
	}
*/

	render() {
		return (
			<React.Fragment>
				<div className={styles.divPlainTitle}>
					{this.resource.title}
				</div>
				<PlainFullDescription plain={this.plainInfo} />
				<ModalMessage messageHandler={this.messageHandler} />
			</React.Fragment>
		)
	}
}

function Password(props) {
	if( props.holder.plainInfo.instanceId == 0 )
		return (null);
	return (
		<React.Fragment>
			<div className={styles.divFullDescription}>
				<div className={styles.divFieldGroup}>
					<div>
						Change password
					</div>
					<div className={styles.divSubfields}>
						<div className={styles.divFieldLine}>

							<div className={styles.divFieldScalar}>
								<div className={styles.divFieldScalarLabel}>
									Old password 
								</div>
								<div className={styles.divFieldScalarValue}>
									<input className={styles.inputFieldScalarValue}
										type="text" id={'id'} name={'id'} value={'value'} onChange={ props.holder.handleValueChange}
									/>
								</div>
							</div>

							<div className={styles.divFieldScalar}>
								<div className={styles.divFieldScalarLabel}>
									New password 1 
								</div>
								<div className={styles.divFieldScalarValue}>
									<input className={styles.inputFieldScalarValue}
										type="text" id={'id'} name={'id'} value={'value'} onChange={ props.holder.handleValueChange}
									/>
								</div>
							</div>

							<div className={styles.divFieldScalar}>
								<div className={styles.divFieldScalarLabel}>
									New password 2
								</div>
								<div className={styles.divFieldScalarValue}>
									<input className={styles.inputFieldScalarValue}
										type="text" id={'id'} name={'id'} value={'value'} onChange={ props.holder.handleValueChange}
									/>
								</div>
							</div>
							
							<button type='button'>Change</button>

						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default UserPage;
