import React, { createRef } from 'react';
import { Link } from 'react-router-dom';
import styles from './RateView.module.css';
import config from '../../config/config';

import ButtonIconText from '../button/ButtonIconText';
import ButtonChangeOwner from './ButtonChangeOwner';
import ButtonFieldUpdate from './ButtonFieldUpdate';
import ButtonGlobalIdUpdate from './ButtonGlobalIdUpdate';
import ButtonMergeInstances from './ButtonMergeInstances';
import ButtonViewConditions from './conditions/ButtonViewConditions';
import ButtonUshImport from './button/ButtonUshImport';
import ButtonUshExport from './button/ButtonUshExport';
import RateViewFilters from './filter/RateViewFilters';
import TableView from '../tableview/TableView';
import TreeView from '../treeview/TreeView';
import PopupMenu from '../popupmenu/PopupMenu';
import ConfirmMessage from '../message/ConfirmMessage';

import { clearFilter, findSorting } from './conditions/util';
import { getCurrentResource, validInteger, containsKey, getDefinedValue } from '../../util/tools';
import { 
	getRequestProperties, performApiRequest, 
	QUERY_GETFORM_API_URL,
	QUERY_GETPAGE_API_URL,
	ENTITY_DELETE_API_URL
} from '../../util/restapi';
import { 
	getButtonMode, getUserInfo, 
	canViewForm, canCreateObject, canReadObject, canUpdateObject, canDeleteObject, 
	getRateViewProfile, setRateViewProfile
} from '../../util/localStorage';

import refreshIcon from '../../assets/icons/png24x24/Refresh.png';
import firstPageIcon from '../../assets/icons/png24x24/go-first.png';
import lastPageIcon from '../../assets/icons/png24x24/go-last.png';
import prevPageIcon from '../../assets/icons/png24x24/Left.png';
import nextPageIcon from '../../assets/icons/png24x24/Right.png';
import addIcon from '../../assets/icons/png24x24/Add.png';
import editIcon from '../../assets/icons/png24x24/edit.png';
import deleteIcon from '../../assets/icons/png24x24/Delete.png';
import selectIcon from '../../assets/icons/png24x24/Accept.png';
import transitionIcon from '../../assets/icons/png24x24/go-jump.png';
import htmlIcon from '../../assets/icons/png24x24/Document.png';
import iconShowTemplate from '../../assets/icons/png24x24/view.png';
import icon3Lines from '../../assets/icons/png24x24/3lines.png';

export const MODE_BROWSE = 'tableBrowse';
export const MODE_SELECT = 'tableSelect';

export const MODE_TABLE = 'table';
export const MODE_TREE = 'tree';

class RateView extends React.Component {

	/* props:
			app
			holder
			isReset
			rvOptions
				transition
				autoRefresh
			rvContext
			mode (MODE_BROWSE, MODE_SELECT)
			onInstanceSelected // method
	*/
	constructor(props) {
		super(props);
		this.mode = MODE_BROWSE;
		this.resource = getCurrentResource().components.rateview.RateView;
		this.userInfo = getUserInfo();
		this.refFormShowTemplate = createRef();
		this.refFormTemplate = createRef();
		this.confirmMessageHandler = {};

		this.formInfo = {};
		this.pageData = {};
		this.headers = [];
		this.totalCount = 0;
		this.pageCount = 0;
		this.testStyle = {color: 'red'};
		this.listFilterComponent = [];

		if( props.holder )
			props.holder.rvComponent = this;
			
		this.rvOptions = props.rvOptions;
		if( ! this.rvOptions )
			this.rvOptions = {};
					
		this.rvContext = props.rvContext;
		if( ! this.rvContext )
			this.rvContext = {};
		this.rvContext.rateView = this;
		
		var formId = this.rvContext.form;
		this.rvProfile = getRateViewProfile( formId );
		if( this.rvProfile == null ) {
			this.rvProfile = {};
			setRateViewProfile( formId, this.rvProfile );
		}
		
		var tableTreeMode = this.rvOptions.tableTreeMode;
		if( ! tableTreeMode )
			tableTreeMode = MODE_TABLE;
		if( props.mode )
			this.mode = props.mode;
		this.state = {
			isInited: false,
			isFormValid: true,
			invalidFormReasonMessage: '',
			isLoading: false,
			isValid: true, // valid page
			invalidReasonMessage: '', // valid page
			isLeftSideVisible: getDefinedValue(this.rvProfile.isLeftSideVisible, true),
			isPopulated: false,
			isFirstPage: false,
			isLastPage: false,
			currentPage: null,		
			currentPageValid: true,		
			isReset: true,
			selectedIds: '',
			tableTreeMode: tableTreeMode,
			selectedObjects: [],
			templateId: '',
			hardFilterBodyText: ''
		};
		this.useIcons = getButtonMode() == 'icon';
		
		// TODO make names like componentXXX
		this.componentTableView = null;		
		this.treeViewComponent = null;

		this.query = {
			queryId: '',
			page: 1,
			pageSize: 10,
			
			listColumn: [],
			listPredicate: [],
			listHardFilter: [],
			listSorting: []
		}; 
		if( this.rvOptions.clearDefaultFilter == undefined || this.rvOptions.clearDefaultFilter == null )
			this.rvOptions.clearDefaultFilter = false;
		if( this.rvOptions.transition ) {
			this.rvOptions.autoRefresh = this.rvOptions.transition.autoRefresh;
			this.rvOptions.clearDefaultFilter = this.rvOptions.transition.clearDefaultFilter;
			this.query.queryTransition = { 
				transitionId: this.rvOptions.transition.transitionId,
				selected: this.rvOptions.transition.selected
			};
		}
	}
	componentDidMount = async () => {
		const request = {
			formId: this.props.rvContext.form
		};
		var response = await performApiRequest( QUERY_GETFORM_API_URL, request );
		if( ! response.success ) {
			this.setState( { isInited: true, isFormValid: false,  invalidFormReasonMessage: response.message} );
			return;
		}
		
		this.formInfo = response.data; 
		this.rvContext.className = this.formInfo.masterClass;
		this.query.queryId = this.formInfo.formId;
		if( this.rvContext.explicitConditions ) {
			for(let p of this.rvContext.explicitConditions) {
				this.query.listPredicate.push( p );
			}
		}

		if( this.rvProfile.pageSize == undefined )
			this.rvProfile.pageSize = this.formInfo.pageSize;
		this.query.pageSize = this.rvProfile.pageSize;
		this.currentPageSize = this.rvProfile.pageSize;
		
		if( this.props.rvContext.completeInit ) {
			await this.props.rvContext.completeInit();
		}
		
		this.queryOptions = {
			pageSize: this.rvProfile.pageSize,
			listColumn: [],
			listHardFilter: [],
			listActiveSorting: []
		}		
		for( let f of this.formInfo.listHardFilter ) {
			this.queryOptions.listHardFilter.push({
				filterModel: f,
				title: f.title,
				tooltip: f.tooltip,
				conditionId: 'none',
				listValue: [],
				listValueObject: [],
				active: false,
				bodyText: ''
			});
		}
		
		{
			if( this.rvProfile.listColumn == undefined )
				this.rvProfile.listColumn = [];
			let visible = [];
			let invisible = [];
			for(let col of this.rvProfile.listColumn) {
				if( containsKey(this.formInfo.listColumn, 'id', col.id ) ) {
					if( col.calculateSum == undefined ) col.calculateSum = false;
					if( col.calculateAvg == undefined ) col.calculateAvg = false;
					if( col.calculateMin == undefined ) col.calculateMin = false;
					if( col.calculateMax == undefined ) col.calculateMax = false;
					if( col.visible )
						visible.push( col );
					else {
						col.calculateSum = false;
						col.calculateAvg = false;
						col.calculateMin = false;
						col.calculateMax = false;
						invisible.push( col );
					}
				}
			}
			for(let col of this.formInfo.listColumn) {
				if( ! containsKey(this.rvProfile.listColumn, 'id', col.id ) ) {
					visible.push( {
						id: col.id, 
						visible: col.defaultVisible,
						calculateSum: false,
						calculateAvg: false,
						calculateMin: false,
						calculateMax: false
					} );
				}
			}
			this.rvProfile.listColumn = visible.concat(invisible);
			for(let visibleCol of visible)
				this.queryOptions.listColumn.push( visibleCol );
		}
		if( this.rvProfile.listActiveSorting == undefined ) {
			this.rvProfile.listActiveSorting = [];
			for( const sortingItem of this.formInfo.listInitialSorting) {
				this.rvProfile.listActiveSorting.push( {sortingId: sortingItem.itemId, ascending: sortingItem.ascending} );
			}
		}
		for(let profileSorting of this.rvProfile.listActiveSorting ) {
			let modelSorting = findSorting(this.formInfo.listSorting, profileSorting.sortingId);
			if( modelSorting != null ) {
				this.queryOptions.listActiveSorting.push({
					sortingId: modelSorting.sortingId,
					title: modelSorting.title,
					tooltip: modelSorting.tooltip,
					ascending: profileSorting.ascending
				});
			}
		}
		
		this.setState( { isInited: true } );
		
		if( this.rvOptions.clearDefaultFilter ) {
			for(var filter of this.listFilterComponent) {
				filter.clearFilter();
			}
			for(var hardFilter of this.queryOptions.listHardFilter) {
				clearFilter( hardFilter );
			}
		}

		if( this.rvOptions.autoRefresh )
			this.handleRefresh();
	}

	setLeftSideVisible = (visible) => {
		this.rvProfile.isLeftSideVisible = visible;
		setRateViewProfile(this.formInfo.formId, this.rvProfile);	
		this.setState({isLeftSideVisible: visible});
	}
	registerFilter = ( filter ) => {
		this.listFilterComponent.push( filter );
	}
	clearAllFilters = () => {
		for(var filter of this.listFilterComponent) {
			filter.clearFilter();
		}
		for(var hardFilter of this.queryOptions.listHardFilter) {
			clearFilter( hardFilter );
		}
		this.rvOptions.transition = null;
		this.query.queryTransition = null;
		this.setState({hardFilterBodyText: ''})
		this.clearData();		
	}
	clearQueryFilter = ( predicateId ) => {
		var indexFilter = -1;
		for(var i=0; i < this.query.listPredicate.length; i++) {
			if( this.query.listPredicate[i].predicateId == predicateId ) {
				indexFilter = i;
				break;
			}
		}
		if( indexFilter >= 0 ) {
			this.query.listPredicate.splice( indexFilter, 1 );
		}
	}
	setFilterValue = (predicateId, value) => {
		for(var f of this.listFilterComponent) {
			if( f.props.filter.predicateId == predicateId ) {
				f.setValue( value );
				break;
			}
		}
	}

	populatePage = async () => {
		this.setState( { 
			isReset: false, 
			isLoading: true, 
			isPopulated: false, 
			isFirstPage: false, 
			isLastPage: false 
		} );
		
		this.prepareQuery();
		
		var response = await performApiRequest( QUERY_GETPAGE_API_URL, this.query );
		if( response.success ) {
			var data = response.data;
			this.currentPageSize = this.query.pageSize;
			this.updateData( data )
		} else {
			this.setState({
				isReset: false,
				isLoading: false,
				isValid: false, 
				invalidReasonMessage: response.message,
				isPopulated: false, 
			});
		}
	}
	prepareQuery = () => {
		this.query.page = this.state.currentPage;
		this.query.listColumn = [];
		for(let col of this.queryOptions.listColumn) {
			this.query.listColumn.push(col);
		}
		this.query.listHardFilter = [];
		for(let f of this.queryOptions.listHardFilter) {
			if( f.active ) {
				this.query.listHardFilter.push({
					predicateId: f.filterModel.predicateId,
					conditionId: f.conditionId,
					listValue: [...f.listValue]
				});
			}
		}
		this.query.listSorting = [];
		for(let s of this.queryOptions.listActiveSorting) {
			this.query.listSorting.push({
				sortingId: s.sortingId,
				ascending: s.ascending
			})
		}		
	}
	updateData = (data) => {
		this.pageData = data;
		this.headers = data.headers;
		this.props.rvContext.data = data.data;
		this.totalCount = data.totalCount;
		this.pageCount = data.pageCount;
		var currentPage = this.state.currentPage 
		if( ! currentPage )
			currentPage = 1;
		if( this.pageCount < currentPage )
			currentPage = this.pageCount;
		this.setState( { 
			isReset: false,
			isLoading: false,
			isValid: true,
			invalidReasonMessage: '',
			isPopulated: true, 
			isFirstPage: (currentPage == 1), 
			isLastPage: (currentPage == this.pageCount ),
			currentPage: currentPage, 
			currentPageValid: true 
		}, this.handleRowSelectionChange );
	}
	handleRefresh = () => {
		if( this.state.tableTreeMode == MODE_TREE ) {
			if( this.treeViewComponent ) {
				this.treeViewComponent.refresh();
				return;
			}
		} else {
			if( this.state.currentPage == null || this.state.currentPage == '' || ! this.state.currentPageValid )
				this.setState({currentPage: 1, currentPageValid: true}, this.populatePage);
			else
				this.populatePage();
		}
	}
	firstPage = () => {
		if( this.state.isFirstPage )
			return;
		this.setState({currentPage: 1, currentPageValid: true}, this.populatePage);
	}
	prevPage = () => {
		if( this.state.isFirstPage )
			return;
		this.setState({currentPage: Number(this.state.currentPage)-1, currentPageValid: true}, this.populatePage);
	}
	nextPage = () => {
		if( this.state.isLastPage )
			return;
		this.setState({currentPage: Number(this.state.currentPage)+1, currentPageValid: true}, this.populatePage);
	}
	lastPage = () => {
		if( this.state.isLastPage )
			return;
		this.setState({currentPage: this.pageCount, currentPageValid: true}, this.populatePage);
	}

	clearData = () => {
		this.setState( { 
			isReset: true, 
			isPopulated: false, 
			isValid: true,
			invalidReasonMessage: '',
			isFirstPage: false, 
			isLastPage: false,
		} );
	}

	getSelectedIds = () => {
		var ids = [];
		if( this.state.tableTreeMode == MODE_TABLE ) {
			var rows = this.getSelectedRows();
			for(let r of rows) {
				ids.push( r.id );
			}
		} else {
			if( this.treeViewComponent ) {
				var nodes = this.treeViewComponent.getSelectedNodes();
				for(let n of nodes) {
					ids.push( n.id );
				}
			}
		}
		return ids;
	}
	getSelectedObjects = () => {
		var ids = [];
		if( this.state.isReset )
			return ids;
		if( this.state.tableTreeMode == MODE_TABLE ) {
			var rows = this.getSelectedRows();
			for(let r of rows) {
				ids.push( r.objectMaster );
			}
		} else {
			if( this.treeViewComponent ) {
				var nodes = this.treeViewComponent.getSelectedNodes();
				for(let n of nodes) {
					ids.push( n );
				}
			}
		}
		return ids;
	}
	getSelectedRows = () => {
		var rows = [];
		if( ! this.props.rvContext.data )
			return rows;
		for( var row of this.props.rvContext.data ) {
			if( row.checked ) {
				rows.push( row );
			}
		}		
		return rows;
	}
	handleRowSelectionChange = () => {
		var selectedObjects = this.getSelectedObjects();
		this.setState({selectedObjects: selectedObjects});
	}	
	handleCurrentPageValueChange = (e) => {
		var value = e.target.value;
		var valid = value == null || value == '' || validInteger( value ) && value > 0;
		this.clearData();
		this.setState({currentPage: value, currentPageValid: valid});
	}
	
	makeTransition = (transition) => {
		var ids = this.getSelectedIds();
		if( ids.length == 0 )
			return;
			
		var param = {
			transition: {
				transitionId: transition.transitionId, 
				autoRefresh: transition.autoRefresh,
				clearDefaultFilter: transition.clearDefaultFilter,
				description: transition.description, 
				selected: ids
			}
		}
		var paramEncoded = encodeURIComponent( JSON.stringify(param) );
		var url = `${config.PATH}/` + transition.tableFormId + '/' + paramEncoded;
		window.open(url);
	}
	
	submitTemplateShowForm = (templateId) => {
		var rows = this.getSelectedRows();
		var selectedIds = '';
		var first = true;
		for( var row of rows) {
			if( first ) {
				first = false;
			} else {
				selectedIds += ',';
			}
			selectedIds += row.id;
		}
		this.setState(
			{selectedIds: selectedIds, templateId: templateId}, 
			() => {this.refFormShowTemplate.current.submit()} );
	}	
	submitTemplateForm = (templateId) => {
		var rows = this.getSelectedRows();
		var selectedIds = '';
		var first = true;
		for( var row of rows) {
			if( first ) {
				first = false;
			} else {
				selectedIds += ',';
			}
			selectedIds += row.id;
		}
		this.setState(
			{selectedIds: selectedIds, templateId: templateId}, 
			() => {this.refFormTemplate.current.submit()} );
	}	
	
	getPlainFormEdit = () => {
		return this.getPlainForm( false );
	}
	getPlainFormView = () => {
		return this.getPlainForm( true );
	}
	getPlainForm = (view) => {
		for( var form of this.formInfo.listPlainForm ) {
			if( form.view == view && canViewForm( form.formId ) ) {
				return form;
			}
		}
		return null;
	}
	setTableTreeMode = (event) => {
		this.clearData();
		var value = event.target.value;
		this.setState({tableTreeMode: value, selectedObjects: [] });
	}
	
	handleInputEdit = (event) => {
		this.clearData();
		var value = event.target.value;
		this.currentPageSize = value;
		if( ! value )
			value = this.rvProfile.pageSize;
		
		this.query.pageSize = value;
		this.rvProfile.pageSize = value;
		setRateViewProfile(this.formInfo.formId, this.rvProfile);	
	} 
	selectInstance = () => {
		var ids = this.getSelectedIds();
		var listObjectMaster = this.getSelectedObjects();
		this.props.onInstanceSelected( ids, listObjectMaster );
	}
	
	addInstance = () => {
		var form = this.getPlainFormEdit();
		var addInstanceURL = `${config.PATH}/` + form.formId + '/0';
		window.open(addInstanceURL);
	}
	editInstance = () => {
		var ids = this.getSelectedIds();
		if( ids.length > 0 ) {
			var form = this.getPlainFormEdit();
			if( form != null ) {
				var editInstanceURL = `${config.PATH}/` + form.formId + '/' + ids[0];
				window.open(editInstanceURL);
			}
		} 
	}
		
	deleteInstances = () => {
		if( ! this.props.rvContext.data )
			return;
		
		this.confirmMessageHandler.confirm = null;
		this.confirmMessageHandler.doAfterClose = this.realDeleteInstances;
		this.confirmMessageHandler.showMessage( this.resource.msgConfirmDeleteMarked );
	}
	realDeleteInstances = async () => {
		if( ! this.confirmMessageHandler.confirm )
			return;

		var request = {
			formId: this.formInfo.formId,
			listInstanceId: []
		}
		var rows = this.getSelectedRows();
		for( var row of rows ) {
			request.listInstanceId.push( row.id )
		}

		var response = await performApiRequest( ENTITY_DELETE_API_URL, request );
		
		if( response.success ) {
			for( var row of rows ) {
				row.deleted = true;
				row.checked = false;
			}
			this.forceUpdate();
		} else {
			this.props.app.showResponse( response );
		}	
	}

	renderData = () => {
		if( this.state.isReset ) {
			return (
				<div style={{marginBottom: '100px'}}>
					{this.resource.msgSetConditionsAndRefresh} ( <img src={refreshIcon} /> )
				</div>
			);
		} else if( this.state.isLoading) {
			return (
				<div>
					{this.resource.msgLoading}
				</div>
			);
		} else if( ! this.state.isValid ) {
			return (
				<div>
					{this.resource.msgError}
					<p>
					{this.state.invalidReasonMessage}
					</p>
				</div>
			);
		} else {
			return (
				<TableView rateView={this} tableHeaders={this.headers} tableData={this.props.rvContext.data} />
			);
		}
	}

	render() {
		if( ! this.state.isInited ) {
			return (
				<div>
					{this.resource.msgInitForm}
				</div>
			);
		} 

		if( ! this.state.isFormValid ) {
			return (
				<div style={{margin: '50px', padding: '50px', color: 'red', fontSize: '16pt'}}>
					{this.state.invalidFormReasonMessage}
				</div>
			);
		} 

		if( this.state.tableTreeMode == MODE_TABLE ) {
			var data = this.renderData();
		} else {
			var data = (<TreeView treeId={this.formInfo.treeFormId} rateView={this} />)
		}
		
		if( this.props.mode == MODE_SELECT)
			var styleData = {width: 'calc(100% - 330px)', overflowX: 'scroll'};
		else
			var styleData = {};
		
		return (
			<div className={styles.divRateView}>
				<ControlPanel holder={this} />
				<div className={styles.divRateViewBody}>
					<LeftSide holder={this} />
					<div className={styles.divData} style={styleData}>
						{data}
					</div>
				</div>
				<ConfirmMessage messageHandler={this.confirmMessageHandler} />
			</div>
		);
	}
}

function LeftSide(props) {
	if( ! props.holder.state.isLeftSideVisible )
		return null;
	var cmbTableTreeMode = (null);
	if( props.holder.formInfo.treeFormId && props.holder.rvContext.disableTree != true) {
		cmbTableTreeMode = (
			<select 
				defaultValue={props.holder.state.tableTreeMode} 
				onChange={props.holder.setTableTreeMode}
				style={{marginLeft: '10px'}}
			>
				<option value={MODE_TABLE}>{props.holder.resource.optionModeTable}</option>
				<option value={MODE_TREE}>{props.holder.resource.optionModeTree}</option>
			</select>
		)
	}
	if( props.holder.state.tableTreeMode == MODE_TABLE ) {
		if( props.holder.rvOptions.transition ) {
			var transition = props.holder.rvOptions.transition;
			var filters = (
				<React.Fragment>
					<div style={{marginTop: '10px', marginBottom: '10px', padding: '5px', textAlign: 'left'}}>
						{transition.description}
					</div>
					<RateViewFilters rateView={props.holder} hardFilterBodyText={props.holder.state.hardFilterBodyText} />
				</React.Fragment>
			); 
		} else {
			var filters = (<RateViewFilters rateView={props.holder} hardFilterBodyText={props.holder.state.hardFilterBodyText} />);
		}
	} else {
		var filters = (null);
	}
	return (
		<div className={styles.divLeftSide}>
			<div className={styles.divConditions}>
				<div className={styles.divConditionHeader}>
					<div className={styles.divConditionTitle}>
						{props.holder.resource.labelViewConditions}
					</div>
					<ButtonViewConditions rateView={props.holder} />
				</div>
				<div className={styles.divRowsTableTreeMode}>
					{props.holder.resource.labelPageSize}
					<input 
						type='number' name='rowcount' value={props.holder.currentPageSize} 
						onChange={ props.holder.handleInputEdit} 
						style={{width: '70px', marginLeft: '5px'}}
					/>
					{cmbTableTreeMode}
				</div>
				{filters}
			</div>
			<div className={styles.divTools}>
				<LeftSideExternalTools holder={props.holder} />
			</div>
		</div>
	);
}

function ControlPanel(props) {
	var rateView = props.holder;
	var res = rateView.resource;
	if( props.holder.mode == MODE_SELECT ) {
		return (
			<div className={styles.divControlPanel}>
				<ButtonIconText 
					onClick={props.holder.handleRefresh}
					buttonType={'button'} useIcon={props.holder.useIcons} styles={styles}
					text={res.buttonRefresh} tooltip={res.buttonRefreshTooltip} icon={refreshIcon}
				/>
				<div><ButtonsNavigation holder={props.holder} /></div>
				<div><InfoPanel holder={props.holder} isReset={props.holder.state.isReset} /></div>
				<div><ButtonsSelect holder={props.holder} /></div>
			</div>
		)
	} else {
		return ( 
			<div className={styles.divControlPanel}>
				<div style={{
					padding: '5px 7px 0 20px',backgroundColor: 'rgb(200,230,200)',
					fontWeight: 'bold', fontSize: '14pt'
				}}>
					{rateView.rvOptions.formTitle}
				</div>
				<ButtonParameters holder={props.holder} />
				<ButtonIconText 
					onClick={props.holder.handleRefresh}
					buttonType={'button'} useIcon={props.holder.useIcons} styles={styles}
					text={res.buttonRefresh} tooltip={res.buttonRefreshTooltip} icon={refreshIcon}
				/>
				<div><ButtonsNavigation holder={props.holder} /></div>
				<div><ButtonsModify holder={props.holder} /></div>

				<ButtonShowHtml holder={props.holder} />
				<ButtonHtml holder={props.holder} />
				<ButtonPdf holder={props.holder} />
				<ButtonXml holder={props.holder} />

				<ButtonTransition holder={props.holder} />

				<ButtonUshExport holder={props.holder} buttonStyle={{marginLeft:'20px'}}/>
				<ButtonUshImport holder={props.holder} buttonStyle={{marginLeft:'5px'}}/>

				<ControlPanelExternalTools holder={props.holder} />
				<div><InfoPanel holder={props.holder} isReset={props.holder.state.isReset} /></div>
			</div>
		)
	}
}

function ButtonParameters(props) {
	var rateView = props.holder;
	var button = {
		text: rateView.resource.buttonParameters, 
		tooltip: rateView.resource.buttonParametersTooltip,
		icon: icon3Lines
	};
	var menu = { items: [] };
	if( rateView.state.isLeftSideVisible ) {
		menu.items.push( {
			title: rateView.resource.menuHideLeftSide,
			method: () => { rateView.setLeftSideVisible(false) }
		} )
	} else {
		menu.items.push( {
			title: rateView.resource.menuShowLeftSide,
			method: () => { rateView.setLeftSideVisible(true) }
		} )
	}
	return (
		<PopupMenu holder={rateView} style={{marginRight: '15px'}} button={button} menu={menu} show1ItemMenu={true} />
	);
}


function ButtonsNavigation(props) {
	var res = props.holder.resource;

	var visibility = (props.holder.state.tableTreeMode == MODE_TABLE ) ? "" : "hidden";
	var bgCurrentPage = props.holder.state.currentPageValid ? 'White' : 'LightYellow';

	var buttonFirstPageDisabled = ! props.holder.state.isPopulated || props.holder.state.isFirstPage;
	var buttonPrevPageDisabled = ! props.holder.state.isPopulated || props.holder.state.isFirstPage;
	var buttonNextPageDisabled = ! props.holder.state.isPopulated || props.holder.state.isLastPage;
	var buttonLastPageDisabled = ! props.holder.state.isPopulated || props.holder.state.isLastPage;

	var page = props.holder.state.currentPage;
	if( page == null )
		page = '';
		
	return (
		<div style={{marginLeft: '30px', visibility: visibility, display: 'flex'}}>
			<ButtonIconText 
				onClick={props.holder.firstPage} disabled={buttonFirstPageDisabled}
				buttonType={'button'} useIcon={props.holder.useIcons} styles={styles}
				text={res.buttonFirstPage} tooltip={res.buttonFirstPageTooltip} icon={firstPageIcon}
			/>
			<ButtonIconText 
				onClick={props.holder.prevPage} disabled={buttonPrevPageDisabled}
				buttonType={'button'} useIcon={props.holder.useIcons} 
				styles={styles} style={{marginLeft: '3px'}}
				text={res.buttonPrevPage} tooltip={res.buttonPrevPageTooltip} icon={prevPageIcon}
			/>
			<input 
				type='text' size='3' value={page} 
				style={{height: '25px', marginLeft: '10px', marginRight: '10px', backgroundColor: bgCurrentPage}}
				onChange={props.holder.handleCurrentPageValueChange}
			/>
			<ButtonIconText 
				onClick={props.holder.nextPage} disabled={buttonNextPageDisabled}
				buttonType={'button'} useIcon={props.holder.useIcons} 
				styles={styles} style={{marginRight: '3px'}}
				text={res.buttonNextPage} tooltip={res.buttonNextPageTooltip} icon={nextPageIcon}
			/>
			<ButtonIconText 
				onClick={props.holder.lastPage} disabled={buttonLastPageDisabled}
				buttonType={'button'} useIcon={props.holder.useIcons} styles={styles}
				text={res.buttonLastPage} tooltip={res.buttonLastPageTooltip} icon={lastPageIcon}
			/>
		</div>
	);
}

function ButtonsModify(props) {
	return (
		<React.Fragment>
			<div className={styles.divButtonsModify}>
				<ButtonAdd holder={props.holder} />
				<ButtonEdit holder={props.holder} />
				<ButtonChangeOwner holder={props.holder} />
				<ButtonFieldUpdate holder={props.holder} />
				<ButtonGlobalIdUpdate holder={props.holder} />
				<ButtonMergeInstances holder={props.holder} />
				<ButtonDelete holder={props.holder} />
			</div>
		</React.Fragment>
	);
}

function LeftSideExternalTools(props) {
	var holder = props.holder;
	if( holder.rvOptions.leftSideTools )
		return holder.rvOptions.leftSideTools;
	else
		return ( null )
}
function ControlPanelExternalTools(props) {
	var holder = props.holder;
	if( holder.rvOptions.controlPanelTools )
		return holder.rvOptions.controlPanelTools;
	else
		return ( null )
}

function ButtonAdd(props) {
	if( props.holder.rvOptions.buttonAdd )
		return props.holder.rvOptions.buttonAdd
	var form = props.holder.getPlainFormEdit();
	if( form == null ) {
		return(null);
	} else {
		var allowed = canCreateObject(props.holder.formInfo.masterClass);
		var disabled = props.holder.state.tableTreeMode == MODE_TREE;
		if( allowed ) {
			var url = `${config.PATH}/` + form.formId + '/0'; 
			if( props.holder.useIcons ) {
				return (
					<React.Fragment>
						&nbsp;
						<button className={styles.buttonToolbarIcon} type="button" disabled={disabled} title={props.holder.resource.buttonAddInstanceTooltip}>
							<Link to={url} target='_blank'><img src={addIcon} /></Link>
						</button> 
					</React.Fragment>
				);
			}
			return (
				<React.Fragment>
					&nbsp;
					<button className={styles.buttonToolbarText} type="button" disabled={disabled}>
						<Link to={url} target='_blank' title={props.holder.resource.buttonAddInstanceTooltip}>{props.holder.resource.buttonAddInstance}</Link>
					</button> 
				</React.Fragment>
			);
		} else {
			return (null);
		}
	}
}
function ButtonEdit(props) {
	var holder = props.holder;
	var userInfo = holder.userInfo;
	var form = holder.getPlainFormEdit();
	if( form == null ) {
		return(null);
	} else {
		if( holder.state.selectedObjects.length == 0 ) {
			var disabled = true;
		} else {
			var obj = holder.state.selectedObjects[0];
			var disabled = ! canUpdateObject(userInfo, obj.className, obj.ownerGroup, obj.ownerUser);
		}
		return (
			<ButtonIconText 
				onClick={props.holder.editInstance} disabled={disabled}
				buttonType={'button'} useIcon={props.holder.useIcons} styles={styles}
				text={props.holder.resource.buttonEditInstance} 
				tooltip={props.holder.resource.buttonEditInstanceTooltip} icon={editIcon}
			/>
		);
	}
}
function ButtonDelete(props) {
	var holder = props.holder;
	var userInfo = holder.userInfo;
	var disabled = false;
	if( holder.state.selectedObjects.length == 0 )
		disabled = true;
	else {
		for( let obj of holder.state.selectedObjects) {
			disabled = disabled || ! canDeleteObject(userInfo, obj.className, obj.ownerGroup, obj.ownerUser);
		} 
	}
	var disabled = disabled || holder.state.tableTreeMode == MODE_TREE;
	return (
		<ButtonIconText 
			onClick={holder.deleteInstances} disabled={disabled}
			buttonType={'button'} useIcon={holder.useIcons} styles={styles}
			text={holder.resource.buttonDeleteInstances} 
			tooltip={holder.resource.buttonDeleteInstancesTooltip} icon={deleteIcon}
		/>
	);
}

function ButtonsSelect(props) {
	var res = props.holder.resource;
	return (
		<ButtonIconText 
			onClick={props.holder.selectInstance}
			buttonType={'button'} useIcon={props.holder.useIcons} 
			styles={styles} style={{marginLeft: '20px'}}
			text={res.buttonSelect} tooltip={res.buttonSelectTooltip} icon={selectIcon}
		/>
	);
}

function ButtonTransition(props) {
	var listTransition = props.holder.formInfo.listTransition;
	var style = {marginLeft: '20px'};
	var button = {
		text: props.holder.resource.buttonTransition, 
		tooltip: props.holder.resource.buttonTransitionTooltip, 
		icon: transitionIcon
	};
	var menu = { items: [] };
	if( listTransition ) {
		for(let transition of listTransition) {
			menu.items.push( {
				title: transition.menuTitle,
				method: () => { props.holder.makeTransition(transition) }
			} )
		}
	}
	return (
		<PopupMenu holder={props.holder} style={style} button={button} menu={menu} />
	);
}

function ButtonShowHtml(props) {
	var holder = props.holder;
	var userInfo = holder.userInfo;
	var template = holder.formInfo.showTemplate;
	if( ! template ) {
		var disabled = true;
		template = { templateId: '', title: '', description: ''};
	} else {
		if( holder.state.selectedObjects.length == 0 ) {
			var disabled = true;
		} else {
			var disabled = false;
			for(let obj of holder.state.selectedObjects)
				var disabled = disabled || ! canReadObject(userInfo, obj.className, obj.ownerGroup, obj.ownerUser);
		}
	}
	var formUrl = 'output/html';
	var requestProperties = getRequestProperties( formUrl );
	return (
		<div style={{marginLeft: '30px'}}>
			<form ref={holder.refFormShowTemplate} action={requestProperties.url} method='post' target='_blank'>
				<input type='hidden' name='objects' value={holder.state.selectedIds} />
				<input type='hidden' name='auth' value={requestProperties.authorization} />
				<input type='hidden' name='lang' value={requestProperties.language} />
				<input type='hidden' name='mode' value={''} />
				<input type='hidden' name='template' value={template.templateId} />
				<ButtonIconText 
					useIcon={holder.useIcons} styles={styles} disabled={disabled}
					text={template.title} tooltip={template.description} icon={iconShowTemplate}
					onClick={ () => {holder.submitTemplateShowForm(template.templateId)} }
				/>
			</form>
		</div>
	);		
}
function ButtonHtml(props) {
	return buttonReport(
		props.holder, 'output/html', '', {marginLeft: '0'}, 
		props.holder.resource.buttonHtml, props.holder.resource.buttonHtmlTooltip, htmlIcon);
}
function ButtonPdf(props) {
	return null;
/*	
	return buttonReport(
		props.holder, props.holder.formInfo.pdfFormUrl, '', {marginLeft: '5px'}, 
		props.holder.resource.buttonPdf, props.holder.resource.buttonPdfTooltip, pdfIcon);
*/
}
function ButtonXml(props) {
	return null;
/*
	return buttonReport(
		props.holder, props.holder.formInfo.xmlFormUrl, '', {marginLeft: '5px'}, 
		props.holder.resource.buttonXml, props.holder.resource.buttonXmlTooltip, htmlIcon);
*/
}
function buttonReport(holder, formUrl, mode, style, text, tooltip, icon) {
	var userInfo = holder.userInfo;
	var listTemplate = holder.formInfo.listTemplate;
	if( ! listTemplate )
		listTemplate = [];
	var requestProperties = getRequestProperties( formUrl );

	var disabled = false;
	if( holder.state.selectedObjects.length == 0 ) {
		disabled = true;
	} else {
		for(let obj of holder.state.selectedObjects)
			var disabled = disabled || ! canReadObject(userInfo, obj.className, obj.ownerGroup, obj.ownerUser);
	}
	if( listTemplate.length == 1) {
		var templateId = listTemplate[0].templateId;
		return (
			<React.Fragment>
				<div style={style}>
					<form ref={holder.refFormTemplate} action={requestProperties.url} method='post' target='_blank'>
						<input type='hidden' name='objects' value={holder.state.selectedIds} />
						<input type='hidden' name='auth' value={requestProperties.authorization} />
						<input type='hidden' name='lang' value={requestProperties.language} />
						<input type='hidden' name='mode' value={mode} />
						<input type='hidden' name='template' value={templateId} />
						<ButtonIconText 
							useIcon={holder.useIcons} styles={styles} disabled={disabled}
							text={text} tooltip={tooltip} icon={icon}
							onClick={ () => {holder.submitTemplateForm(templateId)} }
						/>
					</form>
				</div>		
			</React.Fragment>
		);
	} else { 
		var disabled = disabled || listTemplate.length == 0;
		var button = { text: text, tooltip: tooltip, icon: icon };
		var menu = { items: [] };
		for(let template of listTemplate) {
			menu.items.push( {
				title: template.title,
				tooltip: template.description,
				method: () => { holder.submitTemplateForm(template.templateId) }
			} )
		}
		return (
			<React.Fragment>
				<div style={style}>
					<form ref={holder.refFormTemplate} action={requestProperties.url} method='post' target='_blank'>
						<input type='hidden' name='objects' value={holder.state.selectedIds} />
						<input type='hidden' name='auth' value={requestProperties.authorization} />
						<input type='hidden' name='lang' value={requestProperties.language} />
						<input type='hidden' name='mode' value={mode} />
						<input type='hidden' name='template' value={holder.state.templateId} />
						<PopupMenu holder={holder} button={button} menu={menu} disabled={disabled} />
					</form>
				</div>		
			</React.Fragment>
		);
	}
}

function InfoPanel(props) {
	var info = " ";  
	if( ! props.isReset ) {
		info += props.holder.resource.labelPageNum + ": ";
		info += props.holder.state.currentPage + " / " + props.holder.pageCount + " ";
		info += props.holder.resource.labelRecordCount + ": " + props.holder.totalCount;
	} 
	return (
		<div className={styles.divInfoPanel}>
			{info}
		</div>		
	); 
}

export default RateView;
