import React from 'react';
import styles from './TableView.module.css';

import acceptIcon from '../../assets/icons/png24x24/Accept.png';

class TableHeader extends React.Component {

	constructor(props) {
		super(props);
		props.tableView.componentTableHeader = this;
	}
	
	handleHeader0Click = () => {
		this.props.tableView.markPage();
	}

	render() {
		var cbIndex = 100000;
		const headers = this.props.tableHeaders.map((header, index) => {
			const cssStyle = JSON.parse( header.cssStyle );
			var tooltipHtml = header.tooltip ? <span className={styles.tooltiptext}>{header.tooltip}</span> : null; 
			return (
				<td key={index} style={{textAlign: 'center'}}>
					<div className={styles.divColumnHeaderWithTooltip} style={cssStyle}>
						{header.text}
						{tooltipHtml}
					</div>
				</td>
			)
		});
		return (
			<thead>
				<tr>
					<td key={cbIndex} onClick={this.handleHeader0Click} style={{textAlign: 'center'}}>
						<img src={acceptIcon}/>
					</td>
					{headers}
				</tr></thead>
		);
	}
}

export default TableHeader;
