import React from 'react';
import stylesRV from '../RateView.module.css';

import UshExportModal from '../modal/UshExportModal';

import { getCurrentResource } from '../../../util/tools';
import { canPerformAction, getButtonMode } from '../../../util/localStorage';
import { ACTION_USH_EXPORT } from '../../../util/consts/actions';

import exportIcon from '../../../assets/icons/png24x24/Upload.png';

class ButtonUshExport extends React.Component {
	
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.rateview.button.ButtonUshExport;
		this.rateView = props.holder;
		this.componentUshExportModal = null;
		this.state = {
			isExportModalOpen: false
		}
	}

	doExport = () => {
		var rvContext = this.props.holder.rvContext
		var hasSelected = false;
		if( rvContext.data ) {
			for( var row of rvContext.data ) {
				if( row.checked ) {
					hasSelected = true;
					break;
				}
			}
		}
		if( ! hasSelected ) {
			this.rateView.props.app.showMessage( {mainMessage: this.resource.msgSelectDataForExport} );
			return;
		}
		if( this.componentUshExportModal )
			this.componentUshExportModal.init();
		this.setState({ isExportModalOpen: true });	
	}	
	closeExport = () => {
		this.setState({ isExportModalOpen: false });	
	}
	
	render() {
		if( ! canPerformAction( ACTION_USH_EXPORT ) )
			return ( null );
		var useIcons = getButtonMode() == 'icon';
		var buttonStyle = this.props.buttonStyle ? this.props.buttonStyle : {}; 
	
		var buttonStyle = this.props.buttonStyle ? this.props.buttonStyle : {}; 
		if( getButtonMode() == 'icon' ) {
			var button = (
				<button 
					type="button"
					className={stylesRV.buttonToolbarIcon} style={buttonStyle} 
					onClick={this.doExport} 
					title={this.resource.buttonExportTooltip}
				>
					<img src={exportIcon} />
				</button>
			);
		} else {
			var button = (
				<button 
					type="button"
					className={stylesRV.buttonToolbarText} style={buttonStyle} 
					onClick={this.doExport}
					title={this.resource.buttonExportTooltip}
				>
					{this.resource.buttonExport}
				</button>
			);
		}
	
		return (
			<React.Fragment>
				{button}
				<UshExportModal 
					isModalOpen={this.state.isExportModalOpen} 
					close={this.closeExport} 
					holder={this} rvContext={this.props.holder.rvContext} />
			</React.Fragment>
		);
	}
	
}

export default ButtonUshExport;
