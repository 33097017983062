import React from 'react';
import styles from './Exim.module.css';
import OnixExportModal from '../../modal/exim/OnixExportModal';
import ButtonIconText from '../../components/button/ButtonIconText';
import ModalMessage from '../message/ModalMessage';
import { getCurrentResource } from '../../util/tools';
import { canPerformAction } from '../../util/localStorage';
import { ACTION_ONIX_EXPORT } from '../../util/consts/actions';

import iconExport from '../../assets/icons/png24x24/send_message.png';

class OnixExport extends React.Component {
	
	/*
		props:
			holder
			mode (leftSide, controlPanel)
	*/
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.exim.OnixExport;
		this.componentOnyxExportModal = null;
		this.state = {
			isExportModalOpen: false
		}
		this.messageHandler = {};
	}

	doExport = () => {
		var rvContext = this.props.holder.rvContext
		var hasSelected = false;
		if( rvContext.data ) {
			for( var row of rvContext.data ) {
				if( row.checked ) {
					hasSelected = true;
					break;
				}
			}
		}
		if( ! hasSelected ) {
			this.messageHandler.showMessage( this.resource.msgSelectDataForExport );
			return;
		}
		if( this.componentOnixExportModal )
			this.componentOnixExportModal.init();
		this.setState({ isExportModalOpen: true });	
	}	
	closeExport = () => {
		this.setState({ isExportModalOpen: false });	
	}
	
	render() {
		if( ! canPerformAction( ACTION_ONIX_EXPORT ) )
			return ( null );
			
		if( this.props.mode == 'controlPanel' ) {
			var buttonHtml = (			
				<ButtonIconText 
					onClick={this.doExport} style={{marginLeft: '5px'}}
					text={this.resource.buttonExport} 
					tooltip={this.resource.buttonExportTooltip} icon={iconExport}
				/>
			);
		} else {
			var buttonHtml = (
				<button type="button" onClick={this.doExport} title={this.resource.buttonExportTooltip} >
					{this.resource.buttonExport}
				</button>);			
		}
	
		return (
			<React.Fragment>
				{buttonHtml}
				<ModalMessage messageHandler={this.messageHandler} />
				<OnixExportModal 
					isModalOpen={this.state.isExportModalOpen} 
					close={this.closeExport} 
					holder={this} rvContext={this.props.holder.rvContext} />
			</React.Fragment>
		);
	}
	
}

export default OnixExport;
