import React from 'react';
import styles from '../RateView.module.css';
import SelectInstance from '../../../modal/selectinstance/SelectInstance';
import { getCurrentResource } from '../../../util/tools';
import { performApiRequest, MASTER_LOAD_REF_API_URL } from '../../../util/restapi';
import FilterLabel from './FilterLabel';

import searchIcon from '../../../assets/icons/png24x24/Search.png';
import deleteIcon from '../../../assets/icons/png24x24/Delete.png';

class FilterObjectRef extends React.Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.rateview.filter.FilterObjectRef;		
		var rateView = props.rateView;
		rateView.registerFilter( this );

		this.selectInstanceRvContext = {
			rateView: null,
			form: props.filter.selectObjectRefFormId,
			formTitle: props.filter.selectObjectRefFormTitle, 
			data: null
		};

		var filterValue = null; // ObjectRef
		if( rateView.rvContext && rateView.rvContext.filtersInitData ) {
			for(var f of rateView.rvContext.filtersInitData) {
				if( f.predicateId == props.filter.predicateId ) {
					filterValue = f.value;
					break;
				}
			}
		}

		this.state = {
			value: filterValue,
			isSelectValueModalOpen: false
		}
		if( filterValue )
			this.updateQueryByValue( filterValue );
	}

	clearFilter = () => {
		this.props.rateView.clearQueryFilter( this.props.filter.predicateId )
		this.setState({value: null});
	}
	
	selectValue = () => {
		this.setState({ isSelectValueModalOpen: true });
	}
	onValueSelected = async (rows) => {
		this.props.rateView.clearData();
		var request = {
			id: rows[0]
		}
		var response = await performApiRequest(MASTER_LOAD_REF_API_URL, request);
		if( ! response.success ) {
			this.props.rateView.props.app.showResponse( response );
			this.closeSelectValue();
			return;
		}
		var objectRef = response.data;
		this.setState({value: objectRef});
		this.closeSelectValue();
		this.updateQueryByValue( objectRef );
	}
	closeSelectValue = () => {
		this.setState({ isSelectValueModalOpen: false });
	}

	updateQueryByValue = (value) => {
		var filterValue = ( value ) ? value.id : '';
		var q = this.props.rateView.query;
		var indexFilter = -1;
		var predicate;
		for(var i=0; i < q.listPredicate.length; i++) {
			if( q.listPredicate[i].predicateId == this.props.filter.predicateId ) {
				indexFilter = i;
				break;
			}
		}
		if( indexFilter < 0 ) {
			q.listPredicate.push( {
				predicateId: this.props.filter.predicateId,
				conditionId: this.props.filter.conditionId,
				value: filterValue
			} );
		} else {
			q.listPredicate[indexFilter].value = filterValue;
		}
	}

	
	render() {
		var filter = this.props.filter;
		var card = ( this.state.value ) ? this.state.value.card : '';
		return (
			<div className={styles.divFilter}>
				<FilterLabel title={filter.title} tooltip={filter.tooltip} />
				<div className={styles.divFilterObjectRef}>
					<input className={styles.inputObjectRefCard} type='text' value={card} readOnly={true} />
					<button 
						className={styles.buttonObjectRefSelect} 
						type='button' onClick={this.selectValue}
						title={this.props.filter.selectObjectRefButtonTooltip}
					>
						<img src={searchIcon} />
					</button>
					<button 
						className={styles.buttonObjectRefClear} 
						type={'button'} onClick={this.clearFilter}
						title={this.resource.buttonClearTooltip}
					>
						<img src={deleteIcon} />
					</button>
				</div>
				<SelectInstance 
					isModalOpen={this.state.isSelectValueModalOpen} 
					closeModal={this.closeSelectValue}
					title={this.props.filter.selectObjectRefFormTitle}
					onInstanceSelected={this.onValueSelected}
					rvContext = {this.selectInstanceRvContext}
				/>
			</div>
		);
	}	

}

export default FilterObjectRef;
