import React from 'react';
import styles from '../RateView.module.css';
import ButtonIconText from '../../button/ButtonIconText';
import Filter from "./Filter";
import { getCurrentResource } from '../../../util/tools';

import clearIcon from '../../../assets/icons/png24x24/Delete.png';

class RateViewFilters extends React.Component {

	/*
		props
			rateView
	*/
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.rateview.filter.RateViewFilters;	
		this.state = {
			showFilterBodyText: false
		}	
	}
	
	clearFilters = () => {
		var rateView = this.props.rateView; 
		rateView.clearAllFilters();		
	}
	
	render() {
		var hardFilterBodyTextHtml = null;
		var hardFilterBodyText = this.props.hardFilterBodyText;
		if( hardFilterBodyText ) {
			var hardFilterBodyTextHtml = (
				<div style={{marginLeft: '5px'}}>
					<textarea readonly={true} wrap='off' rows='3' 
						style={{width: '273px', fontSize: '12pt'}} 
						value={hardFilterBodyText} 
					/>
				</div>
			);
		}
		 
		var listFilter = this.props.rateView.formInfo.listFilter;
		const filters = listFilter.map((filter, index) => {
			return (
				<Filter key={index} rateView={this.props.rateView} filter={filter} />
			)
		});
		
		return (
			<React.Fragment>
				<div style={localStyles.divFilterTitle}>
					<div style={{marginRight: '10px'}}>
						{this.resource.title}
					</div>
					<ButtonIconText 
						onClick={this.clearFilters}
						styles={styles}
						tooltip={this.resource.buttonClearTooltip}
						text={this.resource.buttonClear}
						icon={clearIcon}
					/>
				</div>
				{hardFilterBodyTextHtml}
				{filters}
			</React.Fragment>
		);
  }
		
}

const localStyles = {

	divFilterTitle: {
		display: 'flex',
		marginTop: '4px',
		marginLeft: '5px',
		marginBottom: '3px',
		maxHeight: '38px',
		alignItems: 'end',
		textAlign: 'left',
		fontWeight: 'bold',
		overflow: 'hidden'
	}	
}



export default RateViewFilters;