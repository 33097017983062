import React from 'react';
import styles from '../RateView.module.css';
import { getCurrentResource } from '../../../util/tools';
import FilterLabel from './FilterLabel';

class FilterOwnEntities extends React.Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.rateview.filter.FilterOwnEntities;		
		props.rateView.registerFilter( this );

		var initialValue = props.filter.initialValue;
		if( initialValue == null )
			initialValue = '';
		var checked = (initialValue == 'true'); 

		var q = props.rateView.query;
		var indexFilter = -1;
		for(var i=0; i < q.listPredicate.length; i++) {
			if( q.listPredicate[i].predicateId == this.props.filter.predicateId ) {
				indexFilter = i;
				break;
			}
		}
		if( indexFilter < 0 ) {
			var predicate = {
					predicateId: props.filter.predicateId,
					value: checked ? '1' : '0'
				};
				q.listPredicate.push( predicate );
		}
		
		this.state = {
			checked: checked
		}
	}

	clearFilter() {
		console.log('Clear own filter')
		this.setFilterValue(false);
		this.setState({checked: false});
	}	
	handleCheckBoxChange = e => {
		this.props.rateView.clearData();
		const checked = e.target.checked;
		this.setFilterValue(checked);
		this.setState({checked: checked});
	}
	setFilterValue = (checked) => {
		var q = this.props.rateView.query;
		var indexFilter = -1;
		for(let i=0; i < q.listPredicate.length; i++) {
			if( q.listPredicate[i].predicateId == this.props.filter.predicateId ) {
				indexFilter = i;
				break;
			}
		}
		q.listPredicate[indexFilter].value = checked ? '1' : '0';
	}	
	
	render() {
		var filter = this.props.filter;
		return (
			<div className={styles.divFilter}>
				<div className={styles.divFilterInput}>
					<input type="checkbox" onChange={this.handleCheckBoxChange} checked={this.state.checked}/>
					<FilterLabel title={filter.title} tooltip={filter.tooltip} />
				</div>
			</div>
		);
	}	
}

export default FilterOwnEntities;
