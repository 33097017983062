import React from 'react';
import Modal from 'react-responsive-modal';
import styles from './modal.module.css';

import { getCurrentResource, validInteger, validDecimal, getEnumeration, getEnumerationItem } from '../../../util/tools';
import { ENTITY_UPDATE_FIELD_API_URL, performApiRequest } from '../../../util/restapi';
import { ENUMERATION_LANGUAGE } from '../../../util/consts/common';

const FORM_STATE_READY = 'ready'; 
const FORM_STATE_WAITING = 'wait'; 

class UpdateFieldModal extends React.Component {

	constructor(props) {
		super(props);
		this.rateView = this.props.rateView;
		this.listFieldUpdateModel = this.rateView.formInfo.listFieldUpdateModel;
		this.resource = getCurrentResource().components.rateview.modal.UpdateFieldModal;
		this.tryUpdate = false;
		this.modalResponse = null;
		this.rateView.prepareQuery();
		var currentFieldId = this.listFieldUpdateModel && this.listFieldUpdateModel.length > 0 ? 
			this.listFieldUpdateModel[0].fieldId : ''; 
		var request = {
			classId: props.rateView.formInfo.masterClass,
			mode: 'marked',
			listMarked: this.props.rateView.getSelectedIds(),
			querySelected: this.rateView.query,
			rebuildObjects: false,
			downloadImages: false,
			rebuildCards: false,
			rebuildWords: false,
			rebuildLinks: false,
			rebuildFiles: false,
			listField: [
				{
					fieldId: currentFieldId,
					fieldValue: '',
					lang: '',
					onlyEmpty: false
				}
			]
		};
		this.state = {
			formState: FORM_STATE_READY,
			request: request,
			selectedIndex: 0,
			selectedFieldId: currentFieldId
		};
	}

	init = () => {
		this.rateView.prepareQuery();
		var currentFieldId = this.listFieldUpdateModel && this.listFieldUpdateModel.length > 0 ? 
			this.listFieldUpdateModel[0].fieldId : ''; 
		var request = {
			classId: this.props.rateView.formInfo.masterClass,
			mode: 'marked',
			listMarked: this.props.rateView.getSelectedIds(),
			querySelected: this.rateView.query,
			rebuildObjects: false,
			downloadImages: false,
			rebuildCards: false,
			rebuildWords: false,
			rebuildLinks: false,
			rebuildFiles: false,
			listField: [
				{
					fieldId: currentFieldId,
					fieldValue: '',
					lang: '',
					onlyEmpty: false
				}
			]
		};
		this.setState({
			formState: FORM_STATE_READY,
			request: request,
			selectedIndex: 0,
			selectedFieldId: currentFieldId
		});
		this.tryUpdate = false;
		this.modalResponse = null;
	}
	
	findFieldModel = (fieldId) => {
		for(let fm of this.listFieldUpdateModel) {
			if( fm.fieldId == fieldId )
				return fm;
		}
		return null;
	}

	handleModeChange = (e) => {
		var request = this.state.request;
		request.mode = e.target.value;
		this.setState({request: request});
	}	
	handleCheckBoxRebuildChange = (e) => {
		const {name, checked} = e.target;
		let request = this.state.request;
		if( name == 'cbObject' ) 
			request.rebuildObjects = checked;
		else if( name == 'cbDownloadImages' ) 
			request.downloadImages = checked;
		else if( name == 'cbCard' ) 
			request.rebuildCards = checked;
		else if( name == 'cbWord' ) 
			request.rebuildWords = checked;
		else if( name == 'cbLink' ) 
			request.rebuildLinks = checked;
		else if( name == 'cbFile' ) 
			request.rebuildFiles = checked;
		this.setState({request: request});
	}
	handleFieldChange = (e) => {
		var request = this.state.request;
		var selectedIndex = this.state.selectedIndex;
		request.listField[selectedIndex].fieldId = e.target.value; 
		this.setState({request: request, selectedFieldId: e.target.value});
	}
	handleLanguageChange = (e) => {
		var request = this.state.request;
		var selectedIndex = this.state.selectedIndex;
		request.listField[selectedIndex].lang = e.target.value; 
		this.setState({request: request});
	}
	handleCheckBoxChange = (e, index) => {
		const {name, checked} = e.target;
		let request = this.state.request;
		if( name == 'cbClear' ) 
			request.listField[index].clear = checked;
		else if( name == 'cbOnlyEmpty' ) 
			request.listField[index].onlyEmpty = checked;
		this.setState({request: request});
	}
	handleFieldStringValueChange = (e) => {
		let request = this.state.request;
		let selectedIndex = this.state.selectedIndex;
		request.listField[selectedIndex].fieldValue = e.target.value;
		this.setState({request: request});
	}
	handleFieldEnumerationValueChange = (e) => {
		let request = this.state.request;
		let selectedIndex = this.state.selectedIndex;
		request.listField[selectedIndex].fieldValue = e.target.value;
		this.setState({request: request});
	}
	handleFieldIntegerValueChange = (e) => {
		let request = this.state.request;
		let selectedIndex = this.state.selectedIndex;
		request.listField[selectedIndex].fieldValue = e.target.value;
		this.setState({request: request});
	}
	handleFieldDecimalValueChange = (e) => {
		let request = this.state.request;
		let selectedIndex = this.state.selectedIndex;
		request.listField[selectedIndex].fieldValue = e.target.value;
		this.setState({request: request});
	}
	handleFieldDateValueChange = (e) => {
		var value = e.target.value;
		if( value )
			value = value.slice(0,4) + value.slice(5,7) + value.slice(8,10);
		let request = this.state.request;
		let selectedIndex = this.state.selectedIndex;
		request.listField[selectedIndex].fieldValue = value;
		this.setState({request: request});
	}

	handleRowClick = (rowIndex) => {
		let selectedIndex = this.state.selectedIndex; 
		if( rowIndex != selectedIndex ) {
			selectedIndex = rowIndex;
			this.setState({selectedIndex: selectedIndex});
		}
	}
	addField = () => {
		var field = {
			fieldId: this.listFieldUpdateModel[0].fieldId,
			fieldValue: '',
			lang: '',
			onlyEmpty: false
		};
		var request = this.state.request;
		var selectedIndex = request.listField.length;
		request.listField.push( field );
		this.setState({selectedIndex: selectedIndex, request: request});
	}
	deleteField = () => {
		var request = this.state.request;
		if( request.listField.length <= 1 )
			return;
		var selectedIndex = this.state.selectedIndex;
		request.listField.splice( selectedIndex, 1 );
		if( selectedIndex == request.listField.length )
			selectedIndex--;
		this.setState({selectedIndex: selectedIndex, request: request});
	}
	
	doUpdate = async () => {
		this.setState({formState: FORM_STATE_WAITING});
		var response = await performApiRequest( ENTITY_UPDATE_FIELD_API_URL, this.state.request );
		this.tryUpdate = true;
		this.modalResponse = response;
		this.setState({formState: FORM_STATE_READY});
		this.props.holder.closeModal();
	}

	render() {
		var rateView = this.props.rateView;
		var waitingHtml = this.state.formState == FORM_STATE_WAITING ?
			(<div style={{marginBottom: '20px', textAlign: 'center'}}>{this.resource.msgPleaseWait}</div>) : (null) ;
		var buttonDisabled = (this.state.formState == FORM_STATE_WAITING);

		if( this.listFieldUpdateModel && this.listFieldUpdateModel.length > 0 ) {
			var listTableRowHtml = this.state.request.listField.map( (field, index) => {
				if( index == this.state.selectedIndex )
					var style = {backgroundColor: 'lightblue'}
				else
					var style = {backgroundColor: 'white'}
				var fieldModel = this.findFieldModel( field.fieldId );
				var fieldValue = field.fieldValue;
				if( fieldModel.valueType == "date" ) {
					if( fieldValue != null && fieldValue.length == 8) {
						fieldValue = fieldValue.slice(6,9) + '.' + fieldValue.slice(4,6) + '.' + fieldValue.slice(0,4);
					}
				} else if( fieldModel.valueType == "enumeration" ) {
					if( fieldValue != null && fieldValue != '') {
						var enumeration = getEnumeration( this.rateView.formInfo.listEnumeration, fieldModel.enumerationId );
						var item = getEnumerationItem( enumeration.items, fieldValue )
						fieldValue = ( item == null ) ? '' : item.label;
					}
				}
				
				var listFieldHtml = this.listFieldUpdateModel.map( (fieldModel, index) => {
					return ( 
						<option key={index} value={fieldModel.fieldId} selected={this.state.request.selectedFieldId == fieldModel.fieldId}>
							{fieldModel.title}
						</option>
					);
				} );
				var langEnumeration = getEnumeration(rateView.formInfo.listEnumeration, ENUMERATION_LANGUAGE);		
				var listLanguageHtml = langEnumeration.items.map( (item, index) => {
					return ( 
						<option key={index} value={item.value}>
							{item.label}
						</option>
					);
				});
				
				
				return (
					<tr key={index} style={style} onClick={() => {this.handleRowClick(index) } }>
						<td>
							<select onChange={this.handleFieldChange}>
								{listFieldHtml}
							</select>
						</td>
						<td>
							<select value={field.lang} onChange={this.handleLanguageChange}>
								<option value={''}>&nbsp;</option>
								{listLanguageHtml}
							</select>
						</td>
						<td>{fieldValue}</td>
						<td align='center'>
							<input type='checkbox' name='cbOnlyEmpty' checked={field.onlyEmpty} 
								onChange={ (e) => {this.handleCheckBoxChange(e, index)}} 
							/>
						</td>
					</tr>
				);
			} );		
			
			var request = this.state.request;
			var selectedIndex = this.state.selectedIndex;
			var selectedField = request.listField[ selectedIndex ];
			var fieldModel = this.findFieldModel( selectedField.fieldId );
			
			var tableFieldsHtml = (
				<React.Fragment>
					<div style={{display: 'flex', marginTop: '15px'}}>
						<table style={{flexGrow: '100'}} border='1'>
							<thead>
								<tr>
									<th style={{width: '10%'}}>{this.resource.columnField}</th>
									<th style={{width: '15%'}}>{this.resource.columnLang}</th>
									<th>{this.resource.columnValue}</th>
									<th style={{width: '10%'}}>{this.resource.columnOnlyEmpty}</th>
								</tr>
							</thead>
							<tbody>
								{listTableRowHtml}
							</tbody>
						</table>
						<div style={{textAlign: 'right', marginLeft: '20px'}}>
							<div style={{width: '100px'}}>
								<button type='button' onClick={this.addField} style={{width: '100%'}}>{this.resource.buttonAdd}</button><br/>
							</div>
							<div style={{width: '100px', marginTop: '20px'}}>
								<button type='button' onClick={this.deleteField} style={{width: '100%'}}>{this.resource.buttonDelete}</button>
							</div>
						</div>
					</div>
					<div style={{border: '1px solid blue', marginTop: '15px', marginBottom: '20px', padding: '10px'}}>
						<span style={{fontWeight: 'bold'}}>{this.resource.labelNewValue}</span>
						<div style={{marginTop: '5px'}}>
							<InputNewValue modal={this} field={selectedField} fieldModel={fieldModel}/>
						</div>
					</div>
				</React.Fragment>
			);
		} else {
			tableFieldsHtml = null;
		}
		return (
			<Modal 
				open={this.props.isModalOpen} onClose={this.props.close} center styles={styleModal}
				closeOnOverlayClick={false} 
			>
				<div>
					<div className={styles.divHeader}>
						{this.resource.title}
					</div>
					<div style={{padding: '20px'}}>
						<div>
							<span style={{marginRight: '10px'}}>{this.resource.labelChange}</span>
							<select name='cmbMode' onChange={this.handleModeChange}>
								<option value="marked" selected={this.state.request.mode == 'marked'}>{this.resource.optionMarked}</option>
								<option value="selected" selected={this.state.request.mode == 'selected'}>{this.resource.optionSelected}</option>
							</select>
							<span style={{marginLeft: '40px', marginRight: '10px'}}>{this.resource.labelRebuild}</span>
							<input 
								type='checkbox' name='cbObject' 
								style={{marginLeft: '20px', marginRight: '5px'}}
								checked={this.state.request.rebuildObjects}
								onChange={this.handleCheckBoxRebuildChange} 
							/>
							{this.resource.cbObjects}
							<span style={{marginLeft: '4px'}}>(</span>
							<input 
								type='checkbox' name='cbDownloadImages' 
								style={{marginLeft: '2px'}}
								checked={this.state.request.downloadImages}
								onChange={this.handleCheckBoxRebuildChange} 
							/>
							<span style={{marginLeft: '2px', marginRight: '10px'}}>
								{this.resource.cbDownloadImages})
							</span>
							
							<input 
								type='checkbox' name='cbCard' 
								style={{marginLeft: '20px', marginRight: '5px'}}
								checked={this.state.request.rebuildCards}
								onChange={this.handleCheckBoxRebuildChange} 
							/>
							{this.resource.cbCards}
							<input 
								type='checkbox' name='cbWord' 
								style={{marginLeft: '20px', marginRight: '5px'}} 
								checked={this.state.request.rebuildWords}
								onChange={this.handleCheckBoxRebuildChange} 
							/>
							{this.resource.cbWords}
							<input 
								type='checkbox' name='cbLink' 
								style={{marginLeft: '20px', marginRight: '5px'}} 
								checked={this.state.request.rebuildLinks}
								onChange={this.handleCheckBoxRebuildChange} 
							/>
							{this.resource.cbLinks}
							<input 
								type='checkbox' name='cbFile' 
								style={{marginLeft: '20px', marginRight: '5px'}} 
								checked={this.state.request.rebuildFiles}
								onChange={this.handleCheckBoxRebuildChange} 
							/>
							{this.resource.cbFiles}
						</div>
						{tableFieldsHtml}
						{waitingHtml}
						<div style={{textAlign: 'right'}}>
							<button type='button' disabled={buttonDisabled} onClick={this.doUpdate}>{this.resource.buttonUpdate}</button>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

function InputNewValue(props) {
	var modal = props.modal;
	var field = props.field;
	var fieldModel = props.fieldModel;

	if( fieldModel.valueType == "string" )
		return <StringValue modal={modal} field={field} fieldModel={fieldModel} />;
	if( fieldModel.valueType == "integer" )
		return <IntegerValue modal={modal} field={field} fieldModel={fieldModel} />;
	if( fieldModel.valueType == "decimal" )
		return <DecimalValue modal={modal} field={field} fieldModel={fieldModel} />;
	if( fieldModel.valueType == "enumeration" )
		return <EnumerationValue modal={modal} field={field} fieldModel={fieldModel} />;
	if( fieldModel.valueType == "date" )
		return <DateValue modal={modal} field={field} fieldModel={fieldModel} />;
	if( fieldModel.valueType == "datetime" )
		return <DatetimeValue modal={modal} field={field} fieldModel={fieldModel} />;
	if( fieldModel.valueType == "reference" )
		return <ReferenceValue modal={modal} field={field} fieldModel={fieldModel} />;

	return null;
}
function StringValue(props) {
	var field = props.field;
	var modal = props.modal;
	return <input type='text' value={field.fieldValue} onChange={modal.handleFieldStringValueChange} style={{width: '95%'}}/>
}
function IntegerValue(props) {
	var field = props.field;
	var modal = props.modal;
	var value = field.fieldValue;
	if( value == null )
		value = '';
	var valid = validInteger( value );
	var inputStyle = valid ? {backgroundColor: 'White'} : {backgroundColor: 'LightYellow'}
	inputStyle.width = '95%';
	return <input type='text' name='fieldInteger' value={value} step={1} style={inputStyle} onChange={modal.handleFieldIntegerValueChange} />
}
function DecimalValue(props) {
	var field = props.field;
	var modal = props.modal;
	var value = field.fieldValue;
	if( value == null )
		value = '';
	var valid = validDecimal( value );
	var inputStyle = valid ? {backgroundColor: 'White'} : {backgroundColor: 'LightYellow'}
	inputStyle.width = '95%';
	return <input type='text' name='fieldDecimal' value={value} step={0.01} style={inputStyle} onChange={modal.handleFieldDecimalValueChange} />
}
function EnumerationValue(props) {
	var field = props.field;
	var fieldModel = props.fieldModel;
	var modal = props.modal;
	var value = field.fieldValue;
	if( value == null )
		value = '';
	var enumeration = getEnumeration( modal.rateView.formInfo.listEnumeration, fieldModel.enumerationId );
	var optionsHtml = enumeration.items.map((item, index) => {
		return (<option key={index} value={item.value} title={item.description} >{item.label}</option>);
	});	
	var selectStyle = {
		height: '31px',
		fontFamily: 'Verdana, Arial, Helvetica, sans-serif',
		fontSize: '12pt',
		width: '90%'
	};
	return (
		<select style={selectStyle} value={value} onChange={modal.handleFieldEnumerationValueChange} >
			<option key={-1} value={''} title={''} >&nbsp;</option>
			{optionsHtml}
		</select>
	); 
}
function DateValue(props) {
	var field = props.field;
	var modal = props.modal;
	var value = field.fieldValue;
	if( value != null && value.length == 8) {
		value = value.slice(0,4) + '-' + value.slice(4,6) + '-' + value.slice(6,9);
	}
	
	return <input type='date' value={value} onChange={modal.handleFieldDateValueChange} style={{width: '95%'}}/>
}
function DatetimeValue(props) {
	return <input type='text' style={{width: '95%'}} />
}
function ReferenceValue(props) {
	return <input type='text' style={{width: '95%'}}/>
}

const styleModal = {
	modal: {
		background: '#ffffff',
		padding: '0px',
		borderRadius: '21px',
		maxWidth: '1200px',
		width: '100%',
	},
	closeButton: {
		top: '10px',
		right: '10px',
		fill: '#FF0000',
		outline: 'none',
	},
};

export default UpdateFieldModal;
