import React from 'react';
import Modal from 'react-responsive-modal';
import styles from './SelectInstance.module.css';
import RateView from '../../components/rateview/RateView';
import { MODE_SELECT } from '../../components/rateview/RateView';

class SelectInstance extends React.Component {

	/*
		title
		rvContext
		onInstanceSelected
		
		isModalOpen
		closeModal
	*/
	constructor(props) {
		super(props);
	}
		
	render() {
		var title = this.props.title;
		if( ! title )
			title = '...';
		return (
			<Modal 
				open={this.props.isModalOpen} 
				onClose={this.props.closeModal} center 
				styles={styleModal}
				closeOnOverlayClick={false} animationDuration={1}
			>
				<React.Fragment>
					<div className={styles.divHeader}>
						{title}
					</div>
					<div>
						<RateView 
							rvContext={this.props.rvContext} 
							mode={MODE_SELECT} 
							onInstanceSelected={this.props.onInstanceSelected} 
						/>
					</div>
				</React.Fragment>
			</Modal>
		);
	}
	
}

const styleModal = {
	modal: {
		background: '#ffffff',
		padding: '0px',
		borderRadius: '21px 21px 0 0',
		maxWidth: '4000px',
		width: '95%',
	},
	closeButton: {
		top: '10px',
		right: '10px',
		fill: '#FF0000',
		outline: 'none',
	},
};

export default SelectInstance;