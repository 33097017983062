import React from 'react';
import styles from './PopupMenu.module.css';

import ButtonIconText from '../button/ButtonIconText';

import {getDefinedValue} from '../../util/tools';

class PopupMenu extends React.Component {

	/*
		style
		button
		disabled
		menu
			items[i]
				title
				method
	*/
	constructor(props) {
		super(props);
		this.state = {
			visible: false
		};
	}

	switchPopup = () => {
		this.setState( {visible: ! this.state.visible} );
	}
	hidePopup = () => {
		this.setState( {visible: false} );
	}
	
	handleItemClick = (itemIndex) => {
		this.hidePopup();
		this.props.menu.items[itemIndex].method();
	}

	render() {
		var popupBodyHtml = null;
		var buttonDisabled = this.props.disabled || this.props.menu.items.length == 0;
		var show1ItemMenu = getDefinedValue(this.props.show1ItemMenu, false);
		if( this.props.menu.items.length == 1 && ! show1ItemMenu ) {
			var item = this.props.menu.items[0];
			return (
				<div className={styles.divDropDown} style={this.props.style}>
					<ButtonIconText 
						onClick={item.method}
						buttonType={'button'} styles={styles}
						text={item.title} tooltip={item.description} 
						disabled={buttonDisabled} icon={this.props.button.icon}
					/>
				</div>
			);
		}
		if( this.state.visible && ! buttonDisabled) {
			var itemsHtml = this.props.menu.items.map((item, index) => {
				if( item.tooltip ) {
					return (
					<div>
						<div key={index} className={styles.divDropDownItemWithTooltip} onClick={() => this.handleItemClick(index)}>
							{item.title}
							<span className={styles.tooltiptext}>{item.tooltip}</span>
						</div>
					</div>
					);
				} else {
					return (
						<div key={index} className={styles.divDropDownItem} onClick={() => this.handleItemClick(index)}>{item.title}</div>
					);
				}
			})
			popupBodyHtml = (
				<div className={styles.divDropDownBody}>
					{itemsHtml}
				</div>
			);
		}
		return (
			<div className={styles.divDropDown} style={this.props.style}>
				<ButtonIconText 
					onClick={this.switchPopup}
					buttonType={'button'} styles={styles}
					text={this.props.button.text} tooltip={this.props.button.tooltip} 
					disabled={buttonDisabled} icon={this.props.button.icon}
				/>
				{popupBodyHtml}
			</div>
		);
	}
}

export default PopupMenu;