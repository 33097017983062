import React, { createRef } from 'react';
import SelectionList from '../../selectionList/SelectionList';
import FilterEnum from './FilterEnum';
import FilterString from './FilterString';
import FilterNumber from './FilterNumber';
import FilterDate from './FilterDate';
import FilterDateTime from './FilterDateTime';
import FilterObjectRef from './FilterObjectRef';
import FilterBoolean from './FilterBoolean';
import ActiveFilterList from './ActiveFilterList';
import { findFilterIndex, findFilter, clearFilter } from './util';

class TabFilters extends React.Component {

	/*
		props
			rateView
			queryOptions
	*/	
	constructor(props) {
		super(props);
		this.filters = props.queryOptions.listHardFilter;
		console.log('Filters', this.filters)
		this.refFilters = React.createRef();
		this.refActiveFilters = React.createRef();
		
		this.ignoreCurrentFilterSelection = false;
		this.ignoreActiveFilterSelection = false;
		
		this.listFilterHtml = new Array( this.filters.length );
		this.listFilterComponent = new Array( this.filters.length );
		var listActiveFilter = [];

		var filterIndex = 0;		
		for(let f of this.filters) {
			this.listFilterHtml[filterIndex] = ( 
				<Filter key={f.filterModel.predicateId} holder={this} rateView={props.rateView}
					filter={f} filterIndex={filterIndex} 
				/> 
			);
			if( f.active )
				listActiveFilter.push( f );
			filterIndex++;
		}

		this.state = {
			currentFilterIndex: -1,
			currentFilter: null,
			listActiveFilter: listActiveFilter
		}
	}
	registerFilterComponent = (filterComponent, index) => {
		this.listFilterComponent[index] = filterComponent;
	}
	
	handleCurrentFilterSelection = (index) => {
		if( this.ignoreCurrentFilterSelection )
			return;

		var f = null;
		if( 0 <= index && index < this.filters.length )
			f = this.filters[index];

		if( ! this.ignoreActiveFilterSelection ) {
			this.ignoreActiveFilterSelection = true;
			this.refActiveFilters.current.clearSelection();
			this.ignoreActiveFilterSelection = false;
		}

		this.setState( {currentFilterIndex: index, currentFilter: f} );
	}
	handleFilterChanged = (filter) => {
		var list = [];
		for(let f of this.filters) {
			if( f.active )
				list.push( f );
		}
		this.setState({listActiveFilter: list});
	}	
	handleActiveFilterSelection = (index) => {
		if( this.ignoreActiveFilterSelection )
			return;
		if( 0 <= index && index < this.state.listActiveFilter.length ) {
			var activeFilter = this.state.listActiveFilter[index];
			var filterIndex = findFilterIndex( this.filters, activeFilter.filterModel.predicateId  );
			if( filterIndex >= 0 ) {
				this.ignoreActiveFilterSelection = true;
				this.refFilters.current.setSelectedIndex( filterIndex );
				this.ignoreActiveFilterSelection = false;
			}
		}
	}
	clearActiveFilter = (index) => {
		if( 0 <= index && index < this.state.listActiveFilter.length ) {
			var activeFilter = this.state.listActiveFilter[index];
			var currentFilterIndex = findFilterIndex( this.filters, activeFilter.filterModel.predicateId ); 
			if( currentFilterIndex >= 0 ) {
				var f = this.filters[ currentFilterIndex ];
				clearFilter( f );
				this.listFilterComponent[ currentFilterIndex ].updateFilter( f );
				this.handleFilterChanged( f );
			}
		}
	}
	
	render() {
		var filterHtml = null;
		var filterIndex = this.state.currentFilterIndex; 
		if( 0 <= filterIndex && filterIndex < this.listFilterHtml.length )
			filterHtml = this.listFilterHtml[ filterIndex ];
		return(
			<div style={{minHeight: '500px'}}>
				<div style={{display: 'flex', minHeight: '350px'}}>
					<div style={{width: '250px', border: '1px solid black'}}>
						<SelectionList ref={this.refFilters} items={this.filters} handleItemSelection={this.handleCurrentFilterSelection} />
					</div>
					<div style={{flexGrow: '100', marginLeft: '10px'}}>
						{filterHtml}
					</div>
				</div>
				<ActiveFilterList ref={this.refActiveFilters} holder={this} listActiveFilter={this.state.listActiveFilter} />
			</div>
		)
	}
}

function Filter(props) {
	var filter = props.filter;
	if( filter == null )
		return null;
	var filterIndex = props.filterIndex;
	if( filter.filterModel.type == 'FieldEnum' ) {
		return (
			<FilterEnum filter={filter} filterIndex={filterIndex} 
				handleFilterChanged={props.holder.handleFilterChanged} 
				holder={props.holder} rateView={props.rateView} 
			/> 
		);
	}
	if( filter.filterModel.type == 'FieldString' ) {
		return (
			<FilterString filter={filter} filterIndex={filterIndex} 
				handleFilterChanged={props.holder.handleFilterChanged} 
				holder={props.holder} rateView={props.rateView} 
			/>
		);
	}
	if( filter.filterModel.type == 'FieldNumber' ) {
		return (
			<FilterNumber filter={filter} filterIndex={filterIndex} 
				handleFilterChanged={props.holder.handleFilterChanged} 
				holder={props.holder} rateView={props.rateView} 
			/>
		);
	}
	if( filter.filterModel.type == 'FieldDate' ) {
		if( filter.filterModel.dateTime )
			return (
				<FilterDateTime filter={filter} filterIndex={filterIndex} 
					handleFilterChanged={props.holder.handleFilterChanged} 
					holder={props.holder} rateView={props.rateView} 
				/>
			);
		else
			return (
				<FilterDate filter={filter} filterIndex={filterIndex} 
					handleFilterChanged={props.holder.handleFilterChanged} 
					holder={props.holder} rateView={props.rateView} 
				/>
			);
	}
	if( filter.filterModel.type == 'FieldObjectRef' ) {
		return (
			<FilterObjectRef filter={filter} filterIndex={filterIndex} 
				handleFilterChanged={props.holder.handleFilterChanged} 
				holder={props.holder} rateView={props.rateView} 
			/>
		);
	}
	if( filter.filterModel.type == 'Boolean' ) {
		return (
			<FilterBoolean filter={filter} filterIndex={filterIndex} 
				handleFilterChanged={props.holder.handleFilterChanged} 
				holder={props.holder} rateView={props.rateView} 
			/>
		);
	}
}

export default TabFilters;
