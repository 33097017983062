import React from 'react';
import styles from '../RateView.module.css';
import FilterLabel from './FilterLabel';
import {getNotNullString} from '../../../util/tools';
class FilterDateDiapason extends React.Component {

	constructor(props) {
		super(props);
		props.rateView.registerFilter( this );
		var filter = props.filter;
		this.filterValue = {
			fromAsString: filter.initialValue, // yyyy-mm-dd
			toAsString: filter.initialValue2,
			fromAsNumber: filter.initialValueNumber, // Date as milliseconds
			toAsNumber: filter.initialValueNumber2
		};
		this.state = {
			dirty: false
		};
		if( this.filterValue.fromAsNumber || this.filterValue.toAsNumber )
			this.updateQuery();
	}

	clearFilter() {
		this.props.rateView.clearQueryFilter( this.props.filter.predicateId )
		this.filterValue = {
			fromAsString: '', 
			toAsString: '',
			fromAsNumber: '', 
			toAsNumber: ''
		}
		this.setState({dirty: true});
	}
	handleValueChange = (e) => {
		this.props.rateView.clearData();
		const {name, value, valueAsNumber} = e.target;
		var valueNumber = isNaN( valueAsNumber ) ? null : valueAsNumber;
		
		if( name == 'dateFrom' ) {
			this.filterValue.fromAsString = value;
			this.filterValue.fromAsNumber = valueNumber;
		}
		if( name == 'dateTo' ) {
			this.filterValue.toAsString = value;
			this.filterValue.toAsNumber = valueNumber;
		}
		this.setState({dirty: true});

		if( this.filterValue.fromAsNumber == null && this.filterValue.toAsNumber == null ) {
			this.props.rateView.clearQueryFilter( this.props.filter.predicateId )
			return;
		}
		
		this.updateQuery();
	}
	updateQuery = () => {
		var q = this.props.rateView.query;
		var indexFilter = -1;
		for(var i=0; i < q.listPredicate.length; i++) {
			if( q.listPredicate[i].predicateId == this.props.filter.predicateId ) {
				indexFilter = i;
				break;
			}
		}
		if( indexFilter < 0 ) {
			q.listPredicate.push( {
				predicateId: this.props.filter.predicateId,
				conditionId: this.props.filter.conditionId,
				valueFrom: this.filterValue.fromAsNumber,
				valueTo: this.filterValue.toAsNumber
			} );
		} else {
			q.listPredicate[indexFilter].valueFrom = this.filterValue.fromAsNumber;
			q.listPredicate[indexFilter].valueTo = this.filterValue.toAsNumber;
		}
	}
	
	render() {
		var filter = this.props.filter;
		return (
			<div className={styles.divFilter}>
				<FilterLabel title={filter.title} tooltip={filter.tooltip} />
				<div className={styles.divFilterInput}>
					<input type='date' name='dateFrom' value={getNotNullString(this.filterValue.fromAsString)} onChange={this.handleValueChange}/>
					&nbsp;-&nbsp;
					<input type='date' name='dateTo' value={getNotNullString(this.filterValue.toAsString)} onChange={this.handleValueChange}/>
				</div>
			</div>
		);
	}	
}

export default FilterDateDiapason;