import React from 'react';
import Modal from 'react-responsive-modal';
import styles from './RateView.module.css';

import { getCurrentResource, formatDateTime } from '../../util/tools';
import { ENTITY_MERGE_API_URL, performApiRequest } from '../../util/restapi';

import upIcon from '../../assets/icons/png24x24/Up.png';
import downIcon from '../../assets/icons/png24x24/Down.png';

const FORM_STATE_READY = 'ready'; 
const FORM_STATE_WAITING = 'wait'; 

class MergeInstancesModal extends React.Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().modal.rateview.MergeInstancesModal;
		this.tryMerge = false;
		this.mergeResponse = null;
		this.state = {
			formState: FORM_STATE_READY,
			listObjectMaster: [],
			selectedIndex: -1
		};
	}
	init = (listObjectMaster) => {
		this.setState({
			formState: FORM_STATE_READY,
			listObjectMaster: listObjectMaster,
			selectedIndex: -1
		});
		this.tryMerge = false;
		this.mergeResponse = null;
	}

	handleRowClick = (rowIndex) => {
		let selectedIndex = this.state.selectedIndex; 
		if( rowIndex == selectedIndex )
			selectedIndex = -1
		else
			selectedIndex = rowIndex;
		this.setState({selectedIndex: selectedIndex});
	}
	moveUpRow = () => {
		let selectedIndex = this.state.selectedIndex;
		let listObjectMaster = this.state.listObjectMaster;
		if( selectedIndex <= 0 )
			return; 
		let om = listObjectMaster[selectedIndex];
		listObjectMaster[selectedIndex] = listObjectMaster[selectedIndex-1];
		listObjectMaster[selectedIndex-1] = om;
		selectedIndex--;
		this.setState({selectedIndex: selectedIndex, listObjectMaster: listObjectMaster});
	}	
	moveDownRow = () => {
		let selectedIndex = this.state.selectedIndex;
		let listObjectMaster = this.state.listObjectMaster;
		if( selectedIndex == -1 || selectedIndex == listObjectMaster.length-1)
			return; 
		let om = listObjectMaster[selectedIndex];
		listObjectMaster[selectedIndex] = listObjectMaster[selectedIndex+1];
		listObjectMaster[selectedIndex+1] = om;
		selectedIndex++;
		this.setState({selectedIndex: selectedIndex, listObjectMaster: listObjectMaster});
	}	
	doMerge = async () => {
		this.tryMerge = true;
		this.setState({formState: FORM_STATE_WAITING});
		var request = {
			classId: this.state.listObjectMaster[0].className,
			listObjectId: [],
		};
		for(let om of this.state.listObjectMaster) {
			request.listObjectId.push( om.id );
		}
		this.mergeResponse = await performApiRequest( ENTITY_MERGE_API_URL, request );
		this.setState({formState: FORM_STATE_READY});
		this.props.holder.closeModal();
	}

	render() {
		var waitingHtml = this.state.formState == FORM_STATE_WAITING ?
			(<div style={{marginBottom: '20px', textAlign: 'center'}}>{this.resource.msgPleaseWait}</div>) : (null) ;
		var buttonDisabled = (this.state.formState == FORM_STATE_WAITING); 
		var listRows = this.state.listObjectMaster.map( (om, index) => {
			if( index == this.state.selectedIndex )
				var style = {backgroundColor: 'lightblue'}
			else
				var style = {backgroundColor: 'white'}
			return (
				<tr key={index} style={style} onClick={() => {this.handleRowClick(index) } }>
					<td>{om.cardMaster}</td>
					<td>{om.ownerUser}</td>
					<td>{om.ownerGroup}</td>
					<td>{om.createUser}</td>
					<td>{formatDateTime( new Date(om.createDate) )}</td>
					<td>{om.modifyUser}</td>
					<td>{formatDateTime( new Date(om.modifyDate) )}</td>
					<td>{om.globalId}</td>
				</tr>
			);
		} );
		var styleTableHeader = {fontWeight: 'bold', textAlign: 'center'};
		return (
			<Modal 
				open={this.props.isModalOpen} onClose={this.props.close} center styles={styleModal}
				closeOnOverlayClick={false} 
			>
				<div>
					<div className={styles.divHeader}>
						{this.resource.title}
					</div>
					<div style={{padding: '20px'}}>
						<div style={{display: 'flex', marginBottom: '20px'}}>
							<table border='1'>
								<thead>
									<tr>
										<td style={styleTableHeader}>{this.resource.tdCardMaster}</td>
										<td style={styleTableHeader}>{this.resource.tdOwnerUser}</td>
										<td style={styleTableHeader}>{this.resource.tdOwnerGroup}</td>
										<td style={styleTableHeader}>{this.resource.tdCreateUser}</td>
										<td style={styleTableHeader}>{this.resource.tdCreateDate}</td>
										<td style={styleTableHeader}>{this.resource.tdModifyUser}</td>
										<td style={styleTableHeader}>{this.resource.tdModifyDate}</td>
										<td style={styleTableHeader}>Global id</td>
									</tr>
								</thead>
								<tbody>
									{listRows}
								</tbody>
							</table>
							<div style={{marginLeft: '15px'}}>
								<button type='button' onClick={this.moveUpRow} title={this.resource.buttonMoveUp}>
									<img src={upIcon} />
								</button>
								<p>
								<button type='button' onClick={this.moveDownRow} title={this.resource.buttonMoveDown}>
									<img src={downIcon} />
								</button>
								</p>
							</div>
						</div>
						{waitingHtml}
						<div style={{textAlign: 'right'}}>
							<button type='button' disabled={buttonDisabled} onClick={this.doMerge}>{this.resource.buttonMerge}</button>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

const styleModal = {
	modal: {
		background: '#ffffff',
		padding: '0px',
		borderRadius: '21px',
		maxWidth: '1200px',
		width: '100%',
	},
	closeButton: {
		top: '10px',
		right: '10px',
		fill: '#FF0000',
		outline: 'none',
	},
};

export default MergeInstancesModal;
