import React from 'react';
import { getButtonMode } from '../../util/localStorage';

class ButtonIconText extends React.Component {

	constructor(props) {
		super(props);
		this.initProps( props )
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		this.initProps( nextProps );
	}
	initProps = (p) => {
		this.showButton = p.showButton;
		if( this.showButton === undefined || this.showButton === null )
			this.showButton = true;
		this.useIcon = p.useIcon;
		if( this.useIcon === undefined || this.useIcon === null )
			this.useIcon = getButtonMode() == 'icon';
		this.style = p.style;
		if( ! this.style )
			this.style = {};
		this.disabled = p.disabled ? true : false; 
		this.buttonType = p.buttonType ? p.buttonType : 'button'; 
		this.onClick = p.onClick ? p.onClick : '';
	}
		
	render() {
		if( ! this.showButton )
			return (null);
		if( this.useIcon ) {
			if( ! this.style.width )
				this.style.width = '36px';
			if( ! this.style.height )
				this.style.height = '30px';
			return (
				<button 
					type={this.buttonType} 
					style={this.style}
					disabled={this.disabled} 
					onClick={this.onClick} 
					title={this.props.tooltip}
				>
					<img src={this.props.icon} />
				</button>
			);
		} else {
			if( ! this.style.height )
				this.style.height = '30px';
			return (
				<button 
					type={this.buttonType} 
					style={this.style}
					disabled={this.disabled} 
					onClick={this.onClick} 
					title={this.props.tooltip}
				>
					{this.props.text}
				</button>
			);
		}
	}
}

export default ButtonIconText;
