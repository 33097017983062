import React from 'react';

class TabbedPane extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			activeTabIndex: 0
		}
	}

	setActiveTab = (tabIndex) => {
		this.setState({activeTabIndex: tabIndex})
	}
	
	render() {
		var tabHtml = this.props.tabs.map((tab, index)=> {
			var style = this.state.activeTabIndex == index ? localStyles.divActiveTab : localStyles.divNotActiveTab;
			return (
				<div key={index} style={style} onClick={ ()=>{this.setActiveTab(index)} }>
					{tab.title}
				</div>
			);
		});
		return (
			<div style={this.props.divStyle}>
				<div style={{display: 'flex', background: 'lightgray', padding: '5px 5px 0 5px'}}>
					{tabHtml}
				</div>
				<div>
					{this.props.tabs[ this.state.activeTabIndex ].component}
				</div>					
			</div>
		);
	}
}

const localStyles = {
	divActiveTab: {
		margin: '5px 0px 0 0px', /* top | right | bottom | left */
		padding: '5px 10px 5px 10px',
		fontWeight: 'bold',
		borderLeft: '1px solid grey',
		borderRight: '1px solid grey',
		borderTop: '1px solid grey',
		borderRadius: '20px 0px 0 0',
		background: '#f8f8f8',
		cursor: 'pointer'
	},	
	divNotActiveTab: {
		margin: '5px 0px 0 0px',
		padding: '5px 10px 5px 10px',
		borderLeft: '1px solid grey',
		borderRight: '1px solid grey',
		borderTop: '1px solid grey',
		borderRadius: '20px 0px 0 0',
		background: 'lightgray',
		cursor: 'pointer'
	},	
};

export default TabbedPane; 