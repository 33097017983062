import React, { Component } from 'react';
import NavBar from '../../components/navigation/NavBar';

import {ACTION_ELIBRI_API_URL, performApiRequest} from '../../util/restapi';

class ElibriPage extends Component {
	constructor(props) {
		super(props);
	}
	
	fetch = async () => {
		console.log("Fetch");
		const request = {
			method: 'pop'
		};
		var response = await performApiRequest( ACTION_ELIBRI_API_URL, request );
		console.log( response );
	}	
	
	render() {
		return (
			<React.Fragment>
				<NavBar />
				<div>
					Elibri: <button type='button' onClick={this.fetch}>Fetch</button>
				</div>
			</React.Fragment>
		)
	}
	
}

export default ElibriPage;
