import React, { Component } from 'react';
import NavBar from '../../components/navigation/NavBar';
import RateView from '../../components/rateview/RateView';
import ButtonIconText from '../../components/button/ButtonIconText';
import { getCurrentResource } from '../../util/tools';
import config from '../../config/config';
import { performApiRequest, ACTION_PROVIDER_IMPORT_API_URL } from '../../util/restapi';
import { FORM_PROVIDER_TABLE } from '../../util/consts/forms';
import { ACTION_PROVIDER_EXPORT_API_URL } from '../../util/restapi';
import { ACTION_PROVIDER_EXPORT } from '../../util/consts/actions';

import iconImport from '../../assets/icons/png24x24/database-import.png';

class ProviderListPage extends Component {
	constructor(props) {
		super(props);

		this.resource = getCurrentResource().pages.tableform.ProviderList;
		var param = {};
		var encodedParam = props.match.params.param;
		if( encodedParam ) {
			var strParam = decodeURIComponent(encodedParam);
			param = JSON.parse( strParam );
		}

		this.rvContext = {
			form: FORM_PROVIDER_TABLE,
			data: []
		};
		this.rvOptions = {
			form: FORM_PROVIDER_TABLE,
			formTitle: this.resource.formTitle,
//			leftSideTools: this.leftSideTools(),
//			controlPanelTools: this.controlPanelTools(),
			transition: param.transition
		};
		if( param.transition )
			this.rvOptions.autoRefresh = true;
	}
	
	importProviders = async () => {
		const request = {};
		var response = await performApiRequest(ACTION_PROVIDER_IMPORT_API_URL, request);
		console.log(response);
	}

	leftSideTools = () => {
		return (
			<div style={{textAlign: "left"}}>
				<button type='button' onClick={this.importProviders} title={this.resource.buttonImportTooltip}>{this.resource.buttonImport}</button><br/>
			</div>			
		);
	}
	controlPanelTools = () => {
		// <button type='button' onClick={this.importProviders} title={this.resource.buttonImportTooltip}>{this.resource.buttonImport}</button><br/>
		return (
			<div style={{textAlign: 'left', marginLeft: '20px'}}>
				<ButtonIconText 
					onClick={this.importProviders} style={{marginLeft: '5px'}}
					text={this.resource.buttonImport} 
					tooltip={this.resource.buttonImportTooltip} icon={iconImport}
				/>
			</div>			
		);
	}
	
	render() {
		return (
			<React.Fragment>
				<NavBar app={this.props.app} />
				<RateView rvOptions={this.rvOptions} rvContext={this.rvContext} app={this.props.app} />
			</React.Fragment>
		)
	}
	
}

export default ProviderListPage;
