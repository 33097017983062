import React from 'react';
import styles from './FullDescription.module.css';
import Label from './Label';
import { initFieldStyles } from './util';
import { getCurrentResource, getInstanceFieldValue, setInstanceFieldValue, getEnumeration, getEnumerationItem } from '../../util/tools';

class FieldEnumeration extends React.Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.fulldescription.FieldEnumeration;
		this.enumeration = getEnumeration( this.props.plain.realm.listEnumeration, this.props.field.model.enumerationId );
		if( this.enumeration ) {
			if( this.enumeration.items[0].value != '' ) {
				this.enumeration.items.unshift( {value: '', label: this.resource.labelUndefined} )
			}
		}
		var value = getInstanceFieldValue(props.plain.instance, props.field.path);
		if( value == null )
			value = '';
		this.state = {
			instance: props.plain.instance,
			//value: value
		}
	}
	
	
	handleValueChange = (e) => {
		var value = e.target.value;
		setInstanceFieldValue(this.props.plain.instance, this.props.field.path, value);
		this.setState({ value: value });
	}	

	selectStyle = {
		height: '31px',
		fontFamily: 'Verdana, Arial, Helvetica, sans-serif',
		fontSize: '12pt',
		width: '100%'
	};
	
	render() {
		if( ! this.enumeration )
			return ( <div>{this.props.field.model.title}: Absent enumeration with id {this.props.field.model.enumerationId}</div> );

		if( this.props.listItem ) {
			var listItemTools = this.props.listItem.tools;
		} else {
			var listItemTools = (null);
		}

		var value = getInstanceFieldValue(this.props.plain.instance, this.props.field.path);
		if( value == null )
			value = '';

		var optionsHtml = this.enumeration.items.map((item, index) => {
			//var selected = item.value == value; selected={selected}
			return (<option key={index} value={item.value} title={item.description} >{item.label}</option>);
		});	

		var styleFieldScalar = {display: 'flex'};
		var styleLabel = {};
		initFieldStyles( this.props.field, styleFieldScalar, styleLabel );

		return (
			<React.Fragment>
				<div className={styles.divFieldScalar} style={styleFieldScalar}>
					<Label listItem={this.props.listItem} fieldModel={this.props.field.model} style={styleLabel} />
					<div className={styles.divFieldScalarValue} style={{flexGrow: 100}}>
						<select style={this.selectStyle} value={value} onChange={this.handleValueChange} >
							{optionsHtml}
						</select>
					</div>
					{listItemTools}
				</div>
			</React.Fragment>
		);
	}	
}

export default FieldEnumeration;
