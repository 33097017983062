import React from 'react';
import styles from './FullDescription.module.css';

class Label extends React.Component {

	/*
	props
		fieldModel 
		indexName
	*/
	constructor(props) {
		super(props);
	}

	render() {
		var fieldModel = this.props.fieldModel; 
		if( fieldModel.ignoreLabel && fieldModel.ignoreLabelIndex )
			return ( null );

		var style = this.props.style;
		var width = fieldModel.labelWidth;
		var labelText = '';
		if( ! fieldModel.ignoreLabel)
			labelText += fieldModel.title;  
		if( this.props.listItem && ! fieldModel.ignoreLabelIndex) {
			if( labelText != '' )
				labelText += ' ';
			labelText += '[' + (this.props.listItem.itemIndex + 1) + ']';
		}
		var tooltip = fieldModel.description;
		
		if( width ) {
			style.width = width;
		}
		if( tooltip )
			return (
				<div className={styles.divFieldScalarLabelWithTooltip} style={style}>
					{labelText} 
					<span className={styles.tooltiptext}>{tooltip}</span>
				</div>
			);
		else {
			return (
				<div className={styles.divFieldScalarLabel} style={style}>
					{labelText} 
				</div>
			);
		}
	}

}

export default Label;