import React from 'react';
import styles from './FullDescription.module.css';

import ButtonIconText from '../button/ButtonIconText';

import { getCurrentResource } from '../../util/tools';
import { performApiRequest, MASTER_LOAD_OBJECT_API_URL, ENTITY_LOAD_BODY_API_URL } from '../../util/restapi';

import collapseIcon from '../../assets/icons/png24x24/collapse_silver.png';
import expandIcon from '../../assets/icons/png24x24/expand.png';

class SystemData extends React.Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.fulldescription.SystemData;
		props.plain.systemData = this;
		this.state = {
			isCollapsed: true,
			isFetchingData: false,
			isPopulated: false,
			master: null,
			body: null
		};
		this.master = null;
		this.body = null;
	}
	
	expand = async () => {
		if( ! this.state.isPopulated ) {
			this.setState( { isCollapsed: false, isFetchingData: true } );
			var instanceId = this.props.plain.instance.id;
			if( ! instanceId )
				instanceId = 0;
			var response = await performApiRequest( MASTER_LOAD_OBJECT_API_URL, {id: instanceId} );
			if( response.success ) {
				this.master = response.data;
				var bodyRequest = {
					id: instanceId,
					className: this.master.className,
				};
				response = await performApiRequest(ENTITY_LOAD_BODY_API_URL, bodyRequest);
				if( response.success ) {
					this.body = response.data;
				} else {
					this.body = response.message;
				}
			} else {
				this.body = response.message;
				this.master = {
					cardMaster: '',
					id: '',
					globalId: '',
					className: '',
					ownerGroup: '',
					ownerUser: '',
					createUser: '',
					createDate: '',
					modifyUser: '',
					modifyDate: '',
				};
			}
			this.setState( { isFetchingData: false, isPopulated: response.success } );
			if( ! response.success ) {
				this.props.app.showResponse( response );
			}
		} else {
			this.setState( { isCollapsed: false } );
		}
	}
	collapse = () => {
		this.setState( { isCollapsed: true } );
	}
	
	render() {
		if( this.state.isCollapsed ) {
			return (
				<div className={styles.divFieldGroup}>
					<div>
						<ButtonIconText
							onClick={this.expand} styles={styles}
							tooltip={'Expand'} text={'Expand'}
							icon={expandIcon}
						/>
						&nbsp;
						<span>{this.resource.title}</span>
					</div>
				</div>
			);
		} else {
			if( this.state.isFetchingData ) {
				return (
					<div className={styles.divFieldGroup}>
						<div>
							<ButtonIconText 
								onClick={this.collapse} styles={styles}
								tooltip={'Collapse'} text={'Collapse'}
								icon={collapseIcon}
							/>
							&nbsp;
							<span>{this.resource.title}</span>
						</div>
						<div className={styles.divSubfields}>
							...
						</div>
					</div>
				);
			}
			return (
				<div className={styles.divFieldGroup}>
					<div>
						<ButtonIconText
							onClick={this.collapse} styles={styles}
							tooltip={'Collapse11'} text={'Collapse'}
							icon={collapseIcon}
						/>
						&nbsp;
						<span>{this.resource.title}</span>
					</div>
					<div className={styles.divSubfields}>
						<div className={styles.divFieldSystemData}>
							<div className={styles.divFieldLabelSystem}>{this.resource.labelCard}</div>
							<div className={styles.divFieldScalarValue}>{this.master.cardMaster}</div>
						</div>
						<div className={styles.divFieldSystemData}>
							<div className={styles.divFieldLabelSystem}>Id</div>
							<div className={styles.divFieldScalarValue}>{this.master.id}</div>
						</div>
						<div className={styles.divFieldSystemData}>
							<div className={styles.divFieldLabelSystem}>GlobalId</div>
							<div className={styles.divFieldScalarValue}>{this.master.globalId}</div>
						</div>
						<div className={styles.divFieldSystemData}>
							<div className={styles.divFieldLabelSystem}>{this.resource.labelClass}</div>
							<div className={styles.divFieldScalarValue}>{this.master.className}</div>
						</div>
						<div className={styles.divFieldSystemData}>
							<div className={styles.divFieldLabelSystem}>{this.resource.labelOwnerGroup}</div>
							<div className={styles.divFieldScalarValue}>{this.master.ownerGroup}</div>
						</div>
						<div className={styles.divFieldSystemData}>
							<div className={styles.divFieldLabelSystem}>{this.resource.labelOwnerUser}</div>
							<div className={styles.divFieldScalarValue}>{this.master.ownerUser}</div>
						</div>
						<div className={styles.divFieldSystemData}>
							<div className={styles.divFieldLabelSystem}>{this.resource.labelCreateUser}</div>
							<div className={styles.divFieldScalarValue}>{this.master.createUser}</div>
						</div>
						<div className={styles.divFieldSystemData}>
							<div className={styles.divFieldLabelSystem}>{this.resource.labelCreateTime}</div>
							<div className={styles.divFieldScalarValue}>{this.master.createDate}</div>
						</div>
						<div className={styles.divFieldSystemData}>
							<div className={styles.divFieldLabelSystem}>{this.resource.labelModifyUser}</div>
							<div className={styles.divFieldScalarValue}>{this.master.modifyUser}</div>
						</div>
						<div className={styles.divFieldSystemData}>
							<div className={styles.divFieldLabelSystem}>{this.resource.labelModifyTime}</div>
							<div className={styles.divFieldScalarValue}>{this.master.modifyDate}</div>
						</div>
						<div>
							<div className={styles.divFieldLabelSystem}>Body</div>
							<div className={styles.divFieldSystemBody}><pre>{this.body}</pre></div>
						</div>
					</div>
				</div>
			);
		}
	}	
}

export default SystemData;
