import React from 'react';
import Modal from 'react-responsive-modal';
import styles from './Exim.module.css';
import config from '../../config/config';
import { getCurrentResource } from '../../util/tools';
import { ACTION_ONIXEXPORT_API_URL, performExportRequest } from '../../util/restapi';

const FORM_STATE_GET_DATA = 'gd';
const FORM_STATE_WAITING = 'w';
const FORM_STATE_IMPORT_COMPLETE = 'ic';

class OnixExportModal extends React.Component {

	constructor(props) {
		super(props);
		props.holder.componentOnixExportModal = this;
		this.resource = getCurrentResource().modal.exim.OnixExportModal;		
		this.state = {
			formDirty: false,
			formState: FORM_STATE_GET_DATA
		};
		this.init();		
	}
	init = () => {
		this.exportResultMessage = "";
		this.exportOptions = {
			fileName: "",
			listMessageId: []
		}
		this.setState({formState: FORM_STATE_GET_DATA});
	}
	
	styleModal = {
		modal: {
			background: '#ffffff',
			padding: '0px',
			borderRadius: '21px',
			maxWidth: '500px',
			width: '100%',
		},
		closeButton: {
			top: '10px',
			right: '10px',
			fill: '#FF0000',
			outline: 'none',
		},
	};
	
	handleInputEdit = e => {
		const { name, value } = e.target;
		if( name == 'filename' )
			this.exportOptions.fileName = value;
		this.setState({formDirty:true});
	};
	
	startExport = async () => {
		this.setState({formState: FORM_STATE_WAITING});
		this.exportOptions.objectIdList = [];
		for( var row of this.props.rvContext.data ) {
			if( row.checked ) {
				this.exportOptions.listMessageId.push( row.id );
			}
		}

		var response = await performExportRequest(this.exportOptions, ACTION_ONIXEXPORT_API_URL);

		if( response.ok ) {
			var blob = await response.blob();
			blob = new Blob([blob], { type: 'text/xml' })
			const blobUrl = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = blobUrl;
			link.download = this.exportOptions.fileName;
			if( ! link.download )
				link.download = 'export_onix.xml'
			document.body.appendChild(link);
			link.click();

			let message = response.headers.get('ush-message');
			if( message ) {
				this.exportResultMessage = message;
				this.setState({formState: FORM_STATE_IMPORT_COMPLETE});
			} else
				this.props.close();
		} else {
			this.exportResultMessage = response.message;
			this.setState({formState: FORM_STATE_IMPORT_COMPLETE});
		}
	}

	closeModal = () => {
		this.props.close();
	}

	render() {
		return (
			<Modal 
				open={this.props.isModalOpen} 
				onClose={this.closeModal} center 
				styles={this.styleModal}
				closeOnOverlayClick={false} animationDuration={1}
			>
				<ExportForm holder={this} />
			</Modal>
		);
	}
}

function ExportForm(props) {
	var res = props.holder.resource;
	var formData = props.holder.exportOptions; 
	var inputDisabled = props.holder.state.formState != FORM_STATE_GET_DATA;

	if( props.holder.state.formState == FORM_STATE_GET_DATA ) {
		var divWaiting = (null);
		var divMessage = (null);
	} else if( props.holder.state.formState == FORM_STATE_WAITING ) {
		var divWaiting = <div className={styles.divWaiting}>{res.waiting}</div>
		var divMessage = (null);
	} else if( props.holder.state.formState == FORM_STATE_IMPORT_COMPLETE ) {
		var divWaiting = (null)
		var divMessage = (
			<textarea className={styles.areaMessage} rows="4" readOnly={true}>
				{props.holder.exportResultMessage}
			</textarea>		
		);
	}

	return (
		<React.Fragment>
			<div className={styles.divHeader}>
				{res.title}
			</div>
			<div className={styles.divParameters}>
				<p>
					{res.file} <input type="text" name="filename" value={formData.fileName} onChange={ props.holder.handleInputEdit} disabled={inputDisabled}/>
				</p>
			</div>
			{divWaiting}
			{divMessage}
			<div className={styles.divButtons}>
				<button onClick={props.holder.startExport} disabled={inputDisabled} title={res.buttonExportTooltip}>{res.buttonExport}</button>
			</div>
		</React.Fragment>
	);
}

export default OnixExportModal;