import React from 'react';
import { 
	dateInputFormatToRegularFormat, 
	dateRegularFormatToInputFormat 
} from '../../../util/tools';

class FilterValueDateDiapason extends React.Component {

	/*
		props
			filter
			handleFilterChanged
	*/
	constructor(props) {
		super(props);
		this.filter = props.filter;
		if(this.filter.listValue.length == 0)
			this.filter.listValue.push('');
		if(this.filter.listValue.length == 1)
			this.filter.listValue.push('');
		this.state = {
			valueFrom: dateRegularFormatToInputFormat( this.filter.listValue[0] ),
			valueTo: dateRegularFormatToInputFormat( this.filter.listValue[1] ),
		}
	}
	
	handleChangeValue = (e) => {
		const {name, value} = e.target;
		if( name == 'valueFrom' ) {
			this.filter.listValue[0] = dateInputFormatToRegularFormat( value );
			this.setState({valueFrom: value}, this.handleFilterChanged);
		}
		if( name == 'valueTo' ) {
			this.filter.listValue[1] = dateInputFormatToRegularFormat( value );
			this.setState({valueTo: value}, this.handleFilterChanged);
		}
	}
	handleFilterChanged = () => {
		this.props.handleFilterChanged( this.filter );
	}

	render() {
		return (
			<div>
				<div>
					<input type='date' name='valueFrom' style={{width: '100%'}} 
						value={this.state.valueFrom} onChange={this.handleChangeValue} 
					/>
				</div>
				<div style={{marginTop: '30px'}}>
					<input type='date' name='valueTo' style={{width: '100%'}} 
						value={this.state.valueTo} onChange={this.handleChangeValue} 
					/>
				</div>
			</div>
		);
	}
}

export default FilterValueDateDiapason;
