import React from 'react';
import styles from '../RateView.module.css';
import FilterLabel from './FilterLabel';

class FilterNumberDiapason extends React.Component {

	constructor(props) {
		super(props);
		props.rateView.registerFilter( this );
		this.state = {
			valueFrom: '',
			valueTo: ''
		}
	}

	clearFilter() {
		this.props.rateView.clearQueryFilter( this.props.filter.predicateId )
		this.setState({valueFrom: '', valueTo: ''});
	}	
	handleEdit = e => {
		this.props.rateView.clearData();
		var valueFrom = this.state.valueFrom;
		var valueTo = this.state.valueTo;
		
		const { name, value, validity } = e.target;
		if( name == 'from' ) {
			this.setState({valueFrom: value});
			valueFrom = value;
		} else {
			this.setState({valueTo: value});
			valueTo = value;
		}

		if( valueFrom == '' && valueTo == '' ) {
			this.props.rateView.clearQueryFilter( this.props.filter.predicateId )
			return;
		}
		
		var q = this.props.rateView.query;
		var indexFilter = -1;
		var predicate;
		for(var i=0; i < q.listPredicate.length; i++) {
			if( q.listPredicate[i].predicateId == this.props.filter.predicateId ) {
				indexFilter = i;
				break;
			}
		}
		if( indexFilter < 0 ) {
			q.listPredicate.push( {
				predicateId: this.props.filter.predicateId,
				conditionId: this.props.filter.conditionId,
				valueFrom: valueFrom,
				valueTo: valueTo
			} );
		} else {
			q.listPredicate[indexFilter].valueFrom = valueFrom;
			q.listPredicate[indexFilter].valueTo = valueTo;
		}
	}	
	
	render() {
		var filter = this.props.filter;
		var step = this.props.filter.decimal ? 0.01 : 1;
		return (
			<div className={styles.divFilter}>
				<FilterLabel title={filter.title} tooltip={filter.tooltip} />
				<div className={styles.divFilterInput}>
					<input name="from" className={styles.inputFilterPartValue} type='number' step={step} value={this.state.valueFrom} onChange={this.handleEdit}/>
					&nbsp;-&nbsp;
					<input name="to" className={styles.inputFilterPartValue} type='number' step={step} value={this.state.valueTo} onChange={this.handleEdit}/>
				</div>
			</div>
		);
	}	
}

export default FilterNumberDiapason;