import React from 'react';
import FilterOneField from "./FilterOneField";
import FilterEnumField from "./FilterEnumField";
import FilterBooleanField from "./FilterBooleanField";
import FilterNumberDiapason from "./FilterNumberDiapason";
import FilterDateDiapason from "./FilterDateDiapason";
import FilterObjectRef from "./FilterObjectRef";
import FilterOwnEntities from "./FilterOwnEntities";

class Filter extends React.Component {

	constructor(props) {
		super(props);
	}
	
	render() {
			var filter = this.props.filter;
			if( filter.hidden )
				return null;
			switch( filter.type ) {
				case 'OneField': return <FilterOneField rateView={this.props.rateView} filter={filter} />;
				case 'EnumField': return <FilterEnumField rateView={this.props.rateView} filter={filter} />;
				case 'BooleanField': return <FilterBooleanField rateView={this.props.rateView} filter={filter} />;
				case 'NumberDiapason': return <FilterNumberDiapason rateView={this.props.rateView} filter={filter} />;
				case 'DateDiapason': return <FilterDateDiapason rateView={this.props.rateView} filter={filter} />;
				case 'ObjectRef': return <FilterObjectRef rateView={this.props.rateView} filter={filter} />;
				case 'OwnEntities': return <FilterOwnEntities rateView={this.props.rateView} filter={filter} />;
				default: return <div>Unknown filter type {filter.type}</div>;
			}
	}	
}

export default Filter;