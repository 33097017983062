import { getLanguage } from './localStorage';
import RESOURCE from '../resource/Resource';

export const getCurrentResource = () => {
	return getResource( getLanguage() );
}
export const getResource = (lang) => {
	return RESOURCE[lang];
}

export const getDefinedValue = (value, defaultValue) => {
	if( value == null || value === undefined )
		value = defaultValue;
	return value;
}

export const containsKey = ( array, key, keyValue ) => {
	for(let item of array) {
			if( keyValue == item[key] )
				return true;
	}
	return false;
}
export const findByKey = ( array, key, keyValue ) => {
	for(let item of array) {
		if( keyValue == item[key] ) {
			return item;
		}
	}
	return null;
}

export const getEnumeration = ( listEnumeration, enumerationId ) => {
	for(let item of listEnumeration) {
			if( enumerationId == item.enumerationId )
				return item;
	}
	return null;
}

export const getInstanceModel = ( listInstanceModel, modelId ) => {
	for(let item of listInstanceModel) {
			if( modelId == item.instanceModelId )
				return item;
	}
	return null;
}

export const getEnumerationItem = ( listItems, itemValue ) => {
	for(let item of listItems) {
			if( itemValue == item.value )
				return item;
	}
	return null;
}

export const getPathName = path => {
	var pathName = "";
	for (let item of path) {
		pathName += '[' + item + ']';
	}
	return pathName;
}

export const matchPath = (pathMask, path) => {
	if( pathMask.length != path.length )
		return false;
	for(let i=0; i < path.length; i++) {
		if( pathMask[i] == '*' )
			continue;
		if( pathMask[i] != path[i] )
			return false;
	}
	return true;
}

export const getInstanceFields = (instance) => {
	var listField = [];
	populateFields(instance, listField);
	return listField;
}
const populateFields = (field, listField) => {
	if(field == null || field == undefined)
		return;
	if( Array.isArray(field) ) {
		for(let i=0; i < field.length; i++)
			populateFields(field[i], listField);
	} else if( field instanceof Object ) {
		listField.push( field );
		for(let key of Object.keys(field))
			populateFields( field[key], listField );
	}
}

export const getInstanceField = (instance, path) => {
	var field = instance;
	for (let item of path) {
		if( item == 'root' )
			continue;
		field = field[item];
	}
	return field;
}

export const getInstanceFieldValue = (instance, path) => {
	var value = instance;
	for (let item of path) {
		if( item == 'root' )
			continue;
		value = value[item];
		if( value === undefined )
			return null;
	}
	return value;
}

export const setInstanceFieldValue = (instance, path, newValue) => {
	var value = instance;
	for(var i=0; i < path.length-1; i++  ) {
		var item = path[i];
		if( item == 'root' )
			continue;
		if( value[item] === undefined )
			value[item] = {};
		value = value[item];
	}
	value[ path[ path.length-1 ] ] = newValue;
}

export const getInstanceFieldFile = (instance, path) => {
	var field = getInstanceField(instance, path);
	if( field == null ) {
		field = { sourceFileName: '', fileURL:''};
		setInstanceFieldValue (instance, path, field);
	}
	return field;
}

export const getNotNullString = (text) => {
	if( text == undefined || text == null )
		return '';
	return text;
}

export const validDecimal = (text) => {
	if( text == undefined || text == null || text == '' )
		return true;
	var integerPart = true;
	for (let i = 0; i < text.length; i++) {
		var c = text.charAt(i);
		if( i==0 && (c=='+' || c=='-') )
			continue;
		if( c == '.' ) {
			if( integerPart ) {
				integerPart = false;
				continue;	
			} else {
				return false;
			}
		}
		if( isDigit( c ) )
			continue;
		return false;
	}
	return true;
}
export const validInteger = (text) => {
	if( text == undefined || text == null || text == '' )
		return true;
	for (let i = 0; i < text.length; i++) {
		var c = text.charAt(i);
		if( i==0 && (c=='+' || c=='-') )
			continue;
		if( isDigit( c ) )
			continue;
		return false;
	}
	return true;
}
export const isDigit = (c) => {
	return ( c=='0' || c=='1' || c=='2' || c=='3' || c=='4' || c=='5' || c=='6' || c=='7' || c=='8' || c=='9' )
}

export const parseDateTime = (text) => {
	if( text == undefined || text == null || text == '' )
		return null;
	var env = {
		index: 0,
		text: text,
		tokenType: ''
	}
	fetchToken(env);
	var day = env.number
	fetchToken(env); // '.'
	fetchToken(env);
	var month = env.number
	fetchToken(env); // '.'
	fetchToken(env);
	var year = env.number
	fetchToken(env); // ' '
	fetchToken(env);
	var hour = env.number
	fetchToken(env); // ':'
	fetchToken(env);
	var minute = env.number
	fetchToken(env);
	if( env.tokenType == 'end' ) {
		return new Date(year, month, day, hour, minute);
	}
	fetchToken(env);
	var second = env.number
	fetchToken(env);
	if( env.tokenType == 'end' ) {
		return new Date(year, month, day, hour, minute, second);
	}
	fetchToken(env);
	var millisecond = env.number
	fetchToken(env);
	
	return new Date(year, month, day, hour, minute, second, millisecond);
}
export const validDateTime = (text) => {
	if( text == undefined || text == null || text == '' )
		return true;
	var env = {
		index: 0,
		text: text,
		tokenType: ''
	}
	fetchToken(env);
	if( env.tokenType != 'number' )
		return false;
	if( env.number < 1 || env.number > 31 )
		return false;
	
	fetchToken(env);
	if( env.tokenType != 'point' )
		return false;

	fetchToken(env);
	if( env.tokenType != 'number' )
		return false;
	if( env.number < 1 || env.number > 12 )
		return false;

	fetchToken(env);
	if( env.tokenType != 'point' )
		return false;

	fetchToken(env);
	if( env.tokenType != 'number' )
		return false;
	if( env.number < 1 || env.number > 2100 )
		return false;

	fetchToken(env);
	if( env.tokenType != 'space' )
		return false;

	fetchToken(env);
	if( env.tokenType != 'number' )
		return false;
	if( env.number > 24 )
		return false;

	fetchToken(env);
	if( env.tokenType != 'colon' )
		return false;

	fetchToken(env);
	if( env.tokenType != 'number' )
		return false;
	if( env.number > 60 )
		return false;

	fetchToken(env);
	if( env.tokenType == 'end' )
		return true;
	if( env.tokenType != 'colon' )
		return false;

	fetchToken(env);
	if( env.tokenType != 'number' )
		return false;
	if( env.number > 60 )
		return false;

	fetchToken(env);
	if( env.tokenType == 'end' )
		return true;
	if( env.tokenType != 'colon' && env.tokenType != 'point')
		return false;

	fetchToken(env);
	if( env.tokenType != 'number' )
		return false;
	if( env.number > 999 )
		return false;

	fetchToken(env);
	if( env.tokenType != 'end' )
		return false;

	return true;
}
const fetchToken = (env) => {
	if( env.index == env.text.length ) {
		env.tokenType = 'end';
		return;
	}
	var c = env.text.charAt( env.index++ );
	if( c == '.' ) {
		env.tokenType = 'point';
		return;
	}
	if( c == ' ' ) {
		env.tokenType = 'space';
		return;
	}
	if( c == ':' ) {
		env.tokenType = 'colon';
		return;
	}
	if( isDigit( c ) ) {
		env.tokenType = 'number';
		env.number = c;
		while( env.index < env.text.length ) {
			c = env.text.charAt( env.index++ );
			if( isDigit(c) )
				env.number += c;
			else {
				env.index--;
				return;
			}
		}
		return;
	}
	env.tokenType = 'unknown';
}

// Date() => dd.MM.yyyy
export const formatDate = (d) => {
	if( d == undefined || d == null )
		return '';
	let year = d.getFullYear();
	let month = d.getMonth() + 1;
	let day = d.getDate();
	return day + '.' + month + '.' + year;
}
// Date() => dd.MM.yyyy HH:ss
export const formatDateTime = (d) => {
	if( d == undefined || d == null )
		return '';
	let year = d.getFullYear();
	let month = getTwoDigitNumber( d.getMonth() + 1 );
	let day = getTwoDigitNumber( d.getDate() );
	let hours = getTwoDigitNumber( d.getHours() );
	let minutes = getTwoDigitNumber( d.getMinutes() );
	let seconds = getTwoDigitNumber( d.getSeconds() );
	return day + '.' + month + '.' + year + ' ' + hours + ':' + minutes + ':' + seconds;
}
export const getTwoDigitNumber = (n) => {
	if( n == undefined || n == null )
		return '00';
	if( n > 9 )
		return n;
	return '0' + n;
}

// yyyy-mm-dd => yyyymmdd
export const dateInputFormatToRegularFormat = (d) => {
	if( d == undefined || d == null || d.length < 10 )
		return '';
	let year = d.substring(0,4);
	let month = d.substring(5,7);
	let day = d.substring(8,10);
	return year + month + day;
}
// yyyymmdd => yyyy-mm-dd
export const dateRegularFormatToInputFormat = (d) => {
	if( d == undefined || d == null || d.length < 8 )
		return '';
	let year = d.substring(0,4);
	let month = d.substring(4,6);
	let day = d.substring(6,8);
	return year + '-' + month + '-' + day;
}
// yyyymmdd => dd.mm.yyyy
export const dateRegularFormatToShowFormat = (d) => {
	if( d == undefined || d == null || d.length < 8 )
		return '';
	let year = d.substring(0,4);
	let month = d.substring(4,6);
	let day = d.substring(6,8);
	return day + '.' + month + '.' + year;
}
