import React, { Component } from 'react';
import styles from './LoginForm.module.css';
import { getCurrentResource } from '../../util/tools';

class LoginForm extends Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.login.LoginForm;
	}

	state = {
		username: '',
		password: ''
	};
	
	handleChange = (event) => {
		const {name, value} = event.target
		this.setState({
			[name]: value,
		})
	}
	
	onSubmit = () => {
		this.props.tryLogin(this.state.username, this.state.password);
	}
	
	render() {
		return (
			<div className={styles.divLoginForm}>
				<div className={styles.divHeader}>
					{this.resource.title}
				</div>

				<div className={styles.divParameter}>
					<div className={styles.divParamLabel}>
						{this.resource.fieldNameLabel}
					</div>
					<div className={styles.divParamInput}>
						<input
							name="username"
							maxLength="100"
							autoFocus
							type="text"
							placeholder={this.resource.fieldNamePlaceholder}
							onChange={this.handleChange}
						/>
					</div>
				</div>

				<div className={styles.divParameter}>
					<div className={styles.divParamLabel}>
						{this.resource.fieldPasswordLabel}
					</div>
					<div className={styles.divParamInput}>
						<input
							name="password"
							id="password"
							maxLength="100"
							type='password'
							placeholder={this.resource.fieldPasswordPlaceholder}
							onChange={this.handleChange}
						/>
					</div>
				</div>

				<div className={styles.divButtons}>
					<button type="button" onClick={this.onSubmit}>{this.resource.buttonSubmit}</button>
					&nbsp;&nbsp;&nbsp;
					<button onClick={this.props.hideLoginForm}>{this.resource.buttonCancel}</button>
				</div>

			</div>
		)
	}
}

export default LoginForm;
