import React, { createRef } from 'react';
import styles from './FullDescription.module.css';
import { getCurrentResource, getInstanceFieldFile } from '../../util/tools';
import { performApiRequestForm, SAVE_TEMP_IMAGE_API_URL } from '../../util/restapi';

class FieldImage extends React.Component {

	constructor(props) {
		super(props);
		var fieldComponent = {
			field: this,
			path: props.field.path
		}
		props.plain.listFieldComponent.push(fieldComponent);

		this.resource = getCurrentResource().components.fulldescription.FieldImage;
		this.fileRef = createRef();
		
		this.state = {
			dirty: false,
			inst: props.plain.instance
		};	
	}

	clearImage = () => {
		var instance = this.props.plain.instance;
		var fieldFile = getInstanceFieldFile(instance, this.props.field.path);
		fieldFile.fileState = '';
		fieldFile.fileURL = '';
		fieldFile.sourceFileName = '';
		this.setState({ dirty: true });
	}
	handleFileSelect = async (e) => {
		var files = e.target.files;
		if( files && files.length > 0 ) {
			var file = files[0];
//			this.setState({fileName: this.file.name})
			const form = new FormData();
			form.append('file', file);
			var response = await performApiRequestForm(SAVE_TEMP_IMAGE_API_URL, form);
			if( response.success ) {
				var responseFieldFile = response.data;
				var instance = this.props.plain.instance;
				var instanceFieldFile = getInstanceFieldFile(instance, this.props.field.path);
				instanceFieldFile.fileState = responseFieldFile.fileState;
				instanceFieldFile.fileURL = responseFieldFile.fileURL; 
				instanceFieldFile.sourceFileName = responseFieldFile.sourceFileName;
				this.setState({ dirty: true });
			} else {
				this.props.plain.app.showResponse( response );
			}
		}
	}

	render() {
		var fieldFile = getInstanceFieldFile(this.props.plain.instance, this.props.field.path);
		var value = fieldFile.fileURL;
		if( value == null )
			value = '';
		var width = this.props.field.model.imageWidth;
		var height = this.props.field.model.imageHeight;
		return (
			<div>
				<div>
					<Image value={value} width={width} height={height} />
				</div>
				<div>
					<button type='button' onClick={e => this.fileRef.current.click()} title={this.resource.buttonSelectTooltip}>{this.resource.buttonSelect}</button>&nbsp;
					<button type='button' onClick={this.clearImage} title={this.resource.buttonClearTooltip}>{this.resource.buttonClear}</button>
				</div>
				<input ref={this.fileRef} type={'file'} style={{display: 'none'}} onChange={e => this.handleFileSelect(e)} />
			</div>
		);
	}	
}

function Image(props) {
	if( props.width && props.height )
		return (
			<img src={props.value} width={props.width} height={props.height} />
		);
	if( ! props.width && props.height )
		return (
			<img src={props.value} height={props.height} />
		);
	if( props.width && ! props.height )
		return (
			<img src={props.value} width={props.width} />
		);
	if( ! props.width && ! props.height )
		return (
			<img src={props.value} />
		);
}

export default FieldImage;
