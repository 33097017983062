export const AUTHORITY_AUTHOR_CLASS = {
	id: 'AuthorityAuthor',
	hasLinks: true,
	hasFileLinks: true,
	hasWords: true
};

export const AUTHORITY_PUBLISHER = {
	id: 'AuthorityPublisher',
	hasLinks: false,
	hasFileLinks: true,
	hasWords: true
};

export const BOOK = {
	id: 'Book',
	hasLinks: true,
	hasFileLinks: true,
	hasWords: true
};

export const CLASSIFIER = {
	id: 'Classifier',
	hasLinks: false,
	hasWords: false
};

export const CONFIRMATION = {
	id: 'Confirmation',
	hasLinks: false,
	hasFileLinks: true,
	hasWords: false
};

export const CONTACT = {
	id: 'Contact',
	hasLinks: false,
	hasWords: true
};

export const DERIVATIVE = {
	id: 'Derivative',
	hasLinks: false,
	hasFileLinks: true,
	hasWords: false
};

export const DIGITAL_STORE = {
	id: 'DigitalStore',
	hasLinks: false,
	hasWords: false
};

export const ESTIMATION = {
	id: 'Estimation',
	hasLinks: false,
	hasWords: false
};

export const EVENT = {
	id: 'Event',
	hasLinks: false,
	hasWords: false
};

export const HONOR = {
	id: 'Honor',
	hasLinks: false,
	hasFileLinks: true,
	hasWords: false
};

export const LIBRARY = {
	id: 'Library',
	hasLinks: false,
	hasWords: false
};

export const MESSAGE = {
	id: 'Message',
	hasLinks: false,
	hasWords: false
};

export const OFFER = {
	id: 'Offer',
	hasLinks: false,
	hasWords: false
};

export const ORDER = {
	id: 'Order',
	hasLinks: false,
	hasWords: false
};

export const ORGANIZATION = {
	id: 'Organization',
	hasLinks: false,
	hasWords: true
};

export const PROCUREMENT = {
	id: 'Procurement',
	hasLinks: false,
	hasWords: false
};

export const PRODUCT = {
	id: 'Product',
	hasLinks: false,
	hasFileLinks: true,
	hasWords: true
};

export const PROVIDER = {
	id: 'Provider',
	hasLinks: true,
	hasWords: false
};

export const TOPIC = {
	id: 'Topic',
	hasLinks: true,
	hasWords: true
};

export const USER = {
	id: 'User',
	hasLinks: false,
	hasWords: false
};

export const USER_GROUP = {
	id: 'UserGroup',
	hasLinks: false,
	hasWords: true
};

export const LIST_CLASSES = [
	AUTHORITY_AUTHOR_CLASS,
	AUTHORITY_PUBLISHER,
	BOOK,
	CONFIRMATION,
	CONTACT,
	DERIVATIVE,
	DIGITAL_STORE,
	ESTIMATION,
	EVENT,
	HONOR,
	LIBRARY,
	MESSAGE,
	OFFER,
	ORDER,
	ORGANIZATION,
	PROCUREMENT,
	PRODUCT,
	PROVIDER,
	TOPIC,
	USER,
	USER_GROUP
];


export const getClass = (classId) => {
	for(let c of LIST_CLASSES) {
		if( c.id == classId )
			return c;
	}
	return null;
}
