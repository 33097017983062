import React, { Component } from 'react';
import { getCurrentResource } from '../../util/tools';
import PlainFullDescription from '../../components/fulldescription/PlainFullDescription';
import styles from '../../components/fulldescription/FullDescription.module.css';
import { FORM_OFFER_FT } from '../../util/consts/forms';
import { CLASS_OFFER } from '../../util/consts/classes';

class OfferPage extends Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().pages.plain.Offer;
		this.plainInfo = {
			app: props.app,
			page: this,
			class: CLASS_OFFER,
			instanceModelId: FORM_OFFER_FT,
			instanceId: this.props.match.params.id,
			param: {}
		};
	}


	render() {
		return (
			<React.Fragment>
				<div className={styles.divPlainTitle}>
					{this.resource.title}
				</div>
				<PlainFullDescription plain={this.plainInfo} />
			</React.Fragment>
		)
	}
}

export default OfferPage;
