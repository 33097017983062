import React from 'react';
import OnixImportModal from '../../modal/exim/OnixImportModal';
import ButtonIconText from '../../components/button/ButtonIconText';
import { getCurrentResource } from '../../util/tools';
import { canPerformAction } from '../../util/localStorage';
import { ACTION_ONIX_IMPORT } from '../../util/consts/actions';

import iconImport from '../../assets/icons/png24x24/get_message.png';

class OnixImport extends React.Component {
	
	/*
		props:
			holder
			mode (leftSide, controlPanel)
	*/
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.exim.OnixImport;
		this.componentOnixImportModal = null;
		this.state = {
			isImportModalOpen: false
		}
	}
	
	doImport = () => {
		if( this.componentOnixImportModal )
			this.componentOnixImportModal.init();
		this.setState({ isImportModalOpen: true });	
	}	
	closeImport = () => {
		this.setState({ isImportModalOpen: false });	
	}
	
	render() {
		if( ! canPerformAction( ACTION_ONIX_IMPORT ) )
			return ( null );
			
		if( this.props.mode == 'controlPanel' ) {
			var buttonHtml = (			
				<ButtonIconText 
					onClick={this.doImport} style={{marginLeft: '5px'}}
					text={this.resource.buttonImport} 
					tooltip={this.resource.buttonImportTooltip} icon={iconImport}
				/>
			);
		} else {
			var buttonHtml = (
				<button type="button" onClick={this.doImport} title={this.resource.buttonImportTooltip}>
					{this.resource.buttonImport}
				</button>
			);
		}
	
		return (
			<React.Fragment>
				{buttonHtml}
				<OnixImportModal 
					isModalOpen={this.state.isImportModalOpen} 
					close={this.closeImport} 
					holder={this}/>
			</React.Fragment>
		);
	}
	
}

export default OnixImport;
