import React from 'react';

import SelectInstance from '../../../modal/selectinstance/SelectInstance';

import addIcon from '../../../assets/icons/png24x24/Add.png';
import deleteIcon from '../../../assets/icons/png24x24/Delete.png';

class FilterValueObjectList extends React.Component {

	constructor(props) {
		super(props);
		this.filter = props.filter;
		this.selectInstanceRvContext = {
			rateView: null,
			form: props.filter.filterModel.selectInstanceFormId,
			formTitle: props.filter.filterModel.selectInstanceFormTitle, 
			data: null
		};
		this.listMarkedInstanceIndex = [];
		this.state = {
			values: props.filter.listValue,
			isSelectInstanceModalOpen: false
		}
	}

	selectInstances = () => {
		this.setState({ isSelectInstanceModalOpen: true });
	}
	closeSelectInstance = () => {
		this.setState({ isSelectInstanceModalOpen: false });
	}
	onInstanceSelected = async (ids, listObjectMaster) => {
		var filter = this.props.filter;
		for(let om of listObjectMaster) {
			let isUnique = true;
			for(let v of filter.listValue) {
				if( v == om.id ) {
					isUnique = false;
					break;
				}
			}
			if( isUnique ) {
				filter.listValueObject.push( om );
				filter.listValue.push( om.id );
			}
		}
		this.closeSelectInstance();
		this.setState({values: this.filter.listValue}, this.handleFilterChanged);
	}

	markInstances = (e) => {
		this.listMarkedInstanceIndex = [];
		for(let o of e.target.selectedOptions)
			this.listMarkedInstanceIndex.push( o.index )
	}
	clearObjects = () => {
		var filter = this.props.filter;
		for(let i=this.listMarkedInstanceIndex.length-1; i >= 0; i--) {
			let markedIndex = this.listMarkedInstanceIndex[i];
			filter.listValueObject.splice(markedIndex, 1);
			filter.listValue.splice(markedIndex, 1);
		}
		this.listMarkedInstanceIndex = [];
		this.setState({values: this.filter.listValue}, this.handleFilterChanged);
	}
	handleFilterChanged = () => {
		this.props.handleFilterChanged( this.filter );
	}

	render() {
		var filter = this.props.filter;
		var optionsHtml = filter.listValueObject.map( (objectMaster, index) => {
			return (
				<option key={index} id={objectMaster.id}>{objectMaster.card}</option>
			);
		});
		return (
			<div style={{width: '430px'}}>
				<div style={{marginBottom: '10px', textAlign: 'right'}}>
					<button type='button' style={localStyles.button} onClick={this.selectInstances} title={'AA'}>
						<img src={addIcon} />
					</button>
					<button type='button' style={localStyles.button} onClick={this.clearObjects} title={'BB'}>
						<img src={deleteIcon} />
					</button>
				</div>
				<div style={{overflowX: 'auto', overflowY: 'auto', border: '1px solid silver'}}>
					<select multiple={true} size='15' style={{minWidth: '428px'}} onChange={this.markInstances}>
						{optionsHtml}
					</select>
				</div>
				<SelectInstance 
					isModalOpen={this.state.isSelectInstanceModalOpen} 
					closeModal={this.closeSelectInstance}
					title={this.selectInstanceRvContext.formTitle}
					onInstanceSelected={this.onInstanceSelected}
					rvContext = {this.selectInstanceRvContext}
				/>
			</div>
		);
	}
}

const localStyles = {
	button: {
		width: '36px',
		height: '30px',
		marginLeft: '10px'
	}
}

export default FilterValueObjectList;
