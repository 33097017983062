import React from 'react';
import FieldList from './FieldList';
import FieldGroup from './FieldGroup';
import FieldLine from './FieldLine';
import FieldBlock from './FieldBlock';
import FieldString from './FieldString';
import FieldMultiLangString from './FieldMultiLangString';
import FieldInteger from './FieldInteger';
import FieldDecimal from './FieldDecimal';
import FieldDate from './FieldDate';
import FieldDateTime from './FieldDateTime';
import FieldEnumeration from './FieldEnumeration';
import FieldReference from './FieldReference';
import FieldCheckBox from './FieldCheckBox';
import FieldCheckBoxList from './FieldCheckBoxList';
import FieldFile from './FieldFile';
import FieldImage from './FieldImage';
import { getDefinedValue } from '../../util/tools';

class Field extends React.Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		var type = this.props.field.model.type;
		var multiple = this.props.field.model.multiple;
		if( multiple && ! this.props.listItem )
			return ( <FieldList plain={this.props.plain} field={this.props.field} parent={this.props.parent} /> );
		if( type == 'group' )
			return ( <FieldGroup plain={this.props.plain} field={this.props.field} parent={this.props.parent} listItem={this.props.listItem} /> );
		if( type == 'line' )
			return ( <FieldLine plain={this.props.plain} field={this.props.field} parent={this.props.parent} listItem={this.props.listItem} /> );
		if( type == 'block' )
			return ( <FieldBlock plain={this.props.plain} field={this.props.field} parent={this.props.parent} listItem={this.props.listItem} /> );
		if( type == 'string' )
			return ( <FieldString plain={this.props.plain} field={this.props.field} parent={this.props.parent} listItem={this.props.listItem} /> );
		if( type == 'mlstring' )
			return ( <FieldMultiLangString plain={this.props.plain} field={this.props.field} parent={this.props.parent} listItem={this.props.listItem} /> );
		if( type == 'enumeration' )
			return ( <FieldEnumeration plain={this.props.plain} field={this.props.field} parent={this.props.parent} listItem={this.props.listItem} /> );
		if( type == 'integer' )
			return ( <FieldInteger plain={this.props.plain} field={this.props.field} parent={this.props.parent} listItem={this.props.listItem} /> );
		if( type == 'decimal' )
			return ( <FieldDecimal plain={this.props.plain} field={this.props.field} parent={this.props.parent} listItem={this.props.listItem}  /> );
		if( type == 'date' )
			return ( <FieldDate plain={this.props.plain} field={this.props.field} parent={this.props.parent} listItem={this.props.listItem} /> );
		if( type == 'datetime' )
			return ( <FieldDateTime plain={this.props.plain} field={this.props.field} parent={this.props.parent} listItem={this.props.listItem} /> );
		if( type == 'reference' )
			return ( <FieldReference plain={this.props.plain} field={this.props.field} parent={this.props.parent} listItem={this.props.listItem} /> );
		if( type == 'checkbox' )
			return ( <FieldCheckBox plain={this.props.plain} field={this.props.field} parent={this.props.parent} listItem={this.props.listItem} /> );
		if( type == 'checkboxList' )
			return ( <FieldCheckBoxList plain={this.props.plain} field={this.props.field} parent={this.props.parent} listItem={this.props.listItem} /> );
		if( type == 'file' )
			return ( <FieldFile plain={this.props.plain} field={this.props.field} parent={this.props.parent} listItem={this.props.listItem} /> );
		if( type == 'image' )
			return ( <FieldImage plain={this.props.plain} field={this.props.field} parent={this.props.parent} listItem={this.props.listItem} /> );
		return (
			<div>
				Unkknown field type {this.props.field.model.type}
			</div>
		);
	}	
}

export default Field;
