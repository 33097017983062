import React, { Component } from 'react';
import NavBar from '../../components/navigation/NavBar';

class HelloPage extends Component {
	constructor(props) {
		super(props);
	}
	
	render() {
		return (
			<React.Fragment>
				<NavBar app={this.props.app} />
				<div style={{width: '1000px', margin: 'auto', textAlign: 'justify', fontSize: '14pt'}}>
					<p>
					На виконання вимог закону України “Про видавничу справу” Український інститут книги впровадив електронний каталог “Книжки на ринку” .
					</p>
					<p>
					Стаття 1. Електронний каталог "Книжки на ринку" - автоматизована інформаційно-аналітична система, 
					що містить інформацію про книжкові видання, доступні на ринку України, 
					у тому числі запропоновані видавцями для продажу
					</p>
					<p>
					Стаття 5.  Державна політика у видавничій справі спрямовується на підтримку розвитку національного книговидання, 
					наповнення виданнями державною мовою та мовами корінних народів України ринку, 
					фондів бібліотек з використанням електронного каталогу "Книжки на ринку"
					</p>
					<p>
					Стаття 20. Права та обов’язки видавця. 
					Видавець зобов’язаний … забезпечувати наповнення та своєчасне оновлення електронного каталогу "Книжки на ринку"
					</p>
					<p>
					Стаття 25. Поповнення бібліотечних фондів публічних бібліотек за рахунок коштів державного та/або місцевого бюджетів 
					здійснюється виключно книжковою продукцією, відомості про яку включені до електронного каталогу "Книжки на ринку"
					</p>
					<p>
					Для реєстрації в системі перейдіть за <a href="https://docs.google.com/forms/d/e/1FAIpQLSfYoC9gVNAztVTltqD0HNaMx5-69iOXNV3nTZGPwLP2iXMOzQ/viewform">посиланням</a>
					</p>
					<p>
					Адміністратор бази даних: Сергій Опанасенко, Український інститут книги, тел. 067-442-7081, serhiy.opanasenko@ubi.org.ua
					</p>
					<p>
					Технічний адміністратор: ТОВ "Український фондовий дім" тел. 044-353-5248, office@ush.com.ua
					</p>
				</div>
			</React.Fragment>
		)
	}
	
}

export default HelloPage;
