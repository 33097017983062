import { matchPath } from '../../util/tools';
import { getLanguage } from '../../util/localStorage';


export const initStylesOld = (isLineItem, styleFieldScalar, styleLabel) => {
	if( ! isLineItem ) {	
		styleFieldScalar.marginTop = '7px';
		styleLabel.width = '200px';
	}
}

export const initFieldStyles = (field, styleFieldScalar, styleLabel) => {
	if( ! field.isLineItem ) {	
		styleFieldScalar.marginTop = '7px';
		styleLabel.width = '200px';
	}
	
	var fieldModel = field.model;
	if( fieldModel.listCssProperty ) {
		for(var cssProperty of fieldModel.listCssProperty)
			styleFieldScalar[ cssProperty.key ] = cssProperty.value;
	}
	
}

export const addFieldToList = (list, fieldComponent) => {
	for(let i=list.length-1; i >= 0; i--) {
		if( matchPath(list[i].path, fieldComponent.path ) ) {
			list.splice( i, 1 );
		}
	}
	list.push( fieldComponent );
}

export const findFieldByPath = (list, path) => {
	for( let f of list ) {
		if( matchPath(f.path, path ) )
			return f;
	}
	return null;
}

export const populateInstanceField = (field, fieldModel) => {
	for( let subfield of fieldModel.subfields ) {
		if( subfield.defaultValue ) {
//			if( field[subfield.id] === null || field[subfield.id] === undefined )
				field[subfield.id] = subfield.defaultValue;
		} else if( subfield.type == 'group' && ! subfield.multiple ) {
			if( field[subfield.id] === null || field[subfield.id] === undefined )
				field[subfield.id] = {};
			populateInstanceField(field[subfield.id], subfield);
//		} else if( subfield.type == 'mlstring' ) {
//			field[subfield.id] = {lang: getLanguage(), value: ''};
		} else if( subfield.type == 'line' ) {
			populateInstanceField(field, subfield);
		}
	}
}
