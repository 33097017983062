import React, { Component } from 'react';
import ResourceUkr from './ukr/ResourceUkr';
import ResourceEng from './eng/ResourceEng';

const RESOURCE = {
	ukr: ResourceUkr,
	eng: ResourceEng	
}

export default RESOURCE;
