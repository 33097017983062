import { Realm } from '../../util/realm/realm';
import { Instance } from '../../util/realm/instance';

import { performApiRequest, REALM_GET_HASH_API_URL, REALM_GET_REALM_API_URL, ENTITY_LOAD_API_URL } from '../../util/restapi'; 
import { setRealm, getRealm } from '../../util/localStorage';

export const FORM_STATE_INIT_START = 'start';
export const FORM_STATE_INIT_COMPLETE = 'complete';
export const FORM_STATE_INIT_ERROR = 'error';

export const initPlainComponent = async (plain, classId) => {
	var response = await getActualRealm();
	if( ! response.success ) {
		plain.setState({formState: FORM_STATE_INIT_ERROR, message: response.message});
		return;
	}
	plain.realm = new Realm(response.data);
	plain.instanceModel = plain.realm.getInstanceModel(classId);
	if( plain.instanceModel == null ) {
		plain.setState({formState: FORM_STATE_INIT_ERROR, message: 'Absent instance model for class ' + classId});
		return;
	}
	
	var bean = null;
	var instanceId = plain.props.match.params.id;
	if( instanceId > 0) {
		var response = await performApiRequest(ENTITY_LOAD_API_URL, {id: instanceId});
		if( ! response.success ) {
			plain.setState({formState: FORM_STATE_INIT_ERROR, message: response.message});
			return false;
		}
		bean = response.data;
	}

/*
	var loremIpsum = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';
	bean.descrList[0].content = 'Item 1. ' + loremIpsum;	
	bean.descrList.push({language: 'eng', format: '02', content: 'Item 2. ' + loremIpsum});
	bean.descrList.push({language: 'pol', format: '06', content: 'Item 3. ' + loremIpsum});
*/
	
	plain.instance = new Instance(plain.instanceModel, bean);
	console.log( "Plain. Bean", bean ); 
	console.log( "Plain. Instance", plain.instance ); 
	return true;
}

export const renderAbnormalInit = (plain) => {
	if( plain.state.formState == FORM_STATE_INIT_START ) {
		return (
			<div style={{width: '1600px', margin: 'auto', textAlign: 'left'}}>
				<div style={{marginTop: '15px', marginBottom: '10px', textAlign: 'left'}}>
					<span style={{marginRight: '15px', fontSize: '16pt', fontWeight: 'bold'}}>{plain.plainTitle}</span>
					<span style={{marginRight: '15px'}}>Activate form...</span>
				</div>
			</div>
		);
	}
	if( plain.state.formState == FORM_STATE_INIT_ERROR ) {
		return (
			<div style={{width: '1600px', margin: 'auto', textAlign: 'left'}}>
				<div style={{marginTop: '15px', marginBottom: '10px', textAlign: 'left'}}>
					<span style={{marginRight: '15px', fontSize: '16pt', fontWeight: 'bold'}}>{plain.plainTitle}</span>
					<span style={{marginRight: '15px'}}>{plain.state.message}</span>
				</div>
			</div>
		);
	}
}

export const getActualRealm = async () => {
	var response = await performApiRequest(REALM_GET_HASH_API_URL, {})
	if( ! response.success )
		return response;
	var actualHash = response.data;
	var localRealm = getRealm();
	if( localRealm == null || localRealm.hash != actualHash ) {
		response = await performApiRequest(REALM_GET_REALM_API_URL, {})
		if( ! response.success )
			return response;
		setRealm( response.data );
	} else {
		response = {success: true, data: localRealm}
	}
	return response;
}
