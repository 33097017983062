import React from 'react';
import styles from './FullDescription.module.css';
import { getInstanceFieldValue, setInstanceFieldValue } from '../../util/tools';

class FieldCheckBox extends React.Component {
	
	constructor(props) {
		super(props);

		this.state = {
			dirty: false
		}
	}
	
	handleCheckBoxChange = (event) =>	{
		var value = event.target.checked ? 1 : 0;
		setInstanceFieldValue(this.props.plain.instance, this.props.field.path, value);
		this.setState({ dirty: true });
	}
	
	render() {
		var path = this.props.field.path;
		var id = path[ path.length-1 ];
		var value = getInstanceFieldValue(this.props.plain.instance, this.props.field.path);
		if( value == null )
			value = 0;
		var checked = (value == 1);
		
		if( this.props.field.model.description )
			return (
				<div className={styles.divFieldScalar}>
					<input type="checkbox" onChange={this.handleCheckBoxChange} checked={checked} />
					<div className={styles.divCheckBoxLabelWithTooltip} style={{marginLeft: '7px'}}>
						{this.props.field.model.title}
						<span className={styles.tooltiptext}>{this.props.field.model.description}</span>
					</div>
				</div>
			);
		else
			return (
				<div className={styles.divFieldScalar}>
					<input type="checkbox" onChange={this.handleCheckBoxChange} checked={checked} />
					<div style={{marginLeft: '7px'}}>
						{this.props.field.model.title}
					</div>
				</div>
			);
	}
	
}

export default FieldCheckBox;
