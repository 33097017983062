import React from 'react';
import styles from './FullDescription.module.css';
import Label from './Label';
import { initFieldStyles } from './util';
import { getCurrentResource, getInstanceFieldValue, setInstanceFieldValue, getEnumeration } from '../../util/tools';
import { ENUMERATION_LANGUAGE } from '../../util/consts/common';

class FieldMultiLangString extends React.Component {

	constructor(props) {
		super(props);
		var fieldComponent = {
			field: this,
			path: props.field.path
		}
		props.plain.listFieldComponent.push(fieldComponent);

		this.langEnumeration = getEnumeration( this.props.plain.realm.listEnumeration, ENUMERATION_LANGUAGE );
		if( this.langEnumeration ) {
			if( this.langEnumeration.items[0].value != '' ) {
				var res = getCurrentResource().components.fulldescription.FieldEnumeration;
				this.langEnumeration.items.unshift( {value: '', label: res.labelUndefined} )
			}
		}

		var value = getInstanceFieldValue(props.plain.instance, props.field.path);
		if( value == null || value == undefined ) {
			value = { lang: '', value: ''};
			setInstanceFieldValue(props.plain.instance, props.field.path, value);
		}

		this.state = {
			lang: value.lang,
			value: value.value
		}
	}

	handleValueChange = (e) => {
		const {name, value} = e.target;
		var mlsValue = getInstanceFieldValue(this.props.plain.instance, this.props.field.path);
		if( name == 'cmb' ) {
			mlsValue.lang = value;
			this.setState({lang: value})	
		} else if( name == 'str' ) {
			mlsValue.value = value;
			this.setState({value: value})	
		}
	}
	
	divStyle = {
		flexGrow: 100,
		display: 'flex'
	}			
	selectStyle = {
		flexGrow: 0,
		height: '31px',
		maxWidth: '180px',
		fontFamily: 'Verdana, Arial, Helvetica, sans-serif',
		fontSize: '12pt',
		marginRight: '10px'
	};
 	inputStyle = {
 		flexGrow: 100,
		height: '25px',
		fontFamily: 'Verdana, Arial, Helvetica, sans-serif',
		fontSize: '12pt'
	}

	render() {
		var listItem = this.props.listItem;
		var fieldModel = this.props.field.model;
		
		var styleFieldScalar = {};
		var styleLabel = {};
		initFieldStyles( this.props.field, styleFieldScalar, styleLabel );

		if( fieldModel.readOnly ) {
			return (
				<div className={styles.divFieldScalar} style={styleFieldScalar}>
					<Label listItem={listItem} fieldModel={fieldModel} style={styleLabel} />
					<div className={styles.divFieldScalarValue}>
						{this.state.lang} {this.state.value}
					</div>
				</div>
			);
		} else {
			if( listItem ) {
				var listItemTools = listItem.tools;
			} else {
				var listItemTools = (null);
			}
			var maxLength = 524288; // Default html standard value
			if( fieldModel.maxLength )
				maxLength = fieldModel.maxLength;
			var options = this.langEnumeration.items.map((item, index) => {
				return (<option key={index} value={item.value}>{item.label}</option>);
			});	

			return (
				<div className={styles.divFieldScalar} style={styleFieldScalar}>
					<Label listItem={listItem} fieldModel={fieldModel} style={styleLabel} />
					<div style={this.divStyle}>
						<select name='cmb' defaultValue={this.state.lang}
							onChange={this.handleValueChange}
							style={this.selectStyle}
						>
							{options}
						</select>
						<input style={this.inputStyle}
							type='text' name='str' value={this.state.value} size='1' maxLength={maxLength}
							onChange={this.handleValueChange}
						/>
						{listItemTools}
					</div>
				</div>
			);
		}

		return (
			<div>
				ML String
			</div>
		);
	}	
}

export default FieldMultiLangString;
