import React, { Component } from 'react';
import NavBar from '../../components/navigation/NavBar';
import RateView from '../../components/rateview/RateView';
import { getCurrentResource } from '../../util/tools';
import { FORM_ESTIMATION_TABLE } from '../../util/consts/forms';

class EstimationListPage extends Component {
	constructor(props) {
		super(props);

		this.resource = getCurrentResource().pages.tableform.EstimationList;
		var param = {};
		var encodedParam = props.match.params.param;
		if( encodedParam ) {
			var strParam = decodeURIComponent(encodedParam);
			param = JSON.parse( strParam );
		}

		this.rvContext = {
			form: FORM_ESTIMATION_TABLE,
			data: []
		}
		this.rvOptions = {
			form: FORM_ESTIMATION_TABLE,
			formTitle: this.resource.formTitle,
			transition: param.transition
		};
		if( param.transition )
			this.rvOptions.autoRefresh = true;
	}
	
	render() {
		return (
			<React.Fragment>
				<NavBar app={this.props.app} />
				<RateView rvContext={this.rvContext} rvOptions={this.rvOptions} app={this.props.app}  />
			</React.Fragment>
		)
	}
	
}

export default EstimationListPage;
