import React from 'react';
import stylesPlain from '../../components/plain/plain.module.css';

import PlainPage from '../../components/plain/PlainPage';
import Label from '../../components/plain/Label';
import FieldEnum from '../../components/plain/FieldEnum';
import FieldString from '../../components/plain/FieldString';
import FieldList_Rows from '../../components/plain/FieldList_Rows';
import FieldList_CardsPanel from '../../components/plain/FieldList_CardsPanel';
import TabbedPane from '../../components/plain/TabbedPane';

import { FORM_STATE_INIT_COMPLETE } from '../../components/plain/PlainPage';
import { 
	ENUMERATION_LANGUAGE,
	ENUMERATION_TEXT_FORMAT
} from '../../util/consts/common';

import iconSave from '../../assets/icons/png24x24/Save.png';

class EventPlainPage extends PlainPage { 
	
	enumLanguage = null;
	enumTextFormat = null;
	
	constructor(props) {
		super(props);
		this.formTitle = 'EVENT';
	}
	componentDidMount = async () => {
		this.init('Event')
	}
	completeInit() {
		this.enumLanguage = this.realm.getEnumeration( ENUMERATION_LANGUAGE );
		this.enumTextFormat = this.realm.getEnumeration( ENUMERATION_TEXT_FORMAT );
	}

	descriptionCardBuilder = (fieldDescription, index) => {
		var lang = this.realm.getEnumerationItemText(this.enumLanguage, fieldDescription.value.language.value)
		var format = this.realm.getEnumerationItemText(this.enumTextFormat, fieldDescription.value.format.value)
		var card = lang + ' (' + format + ')';
		if( fieldDescription.value.content.value )
			card += ': ' + fieldDescription.value.content.value;
		if( card.length > 80 )
			card = card.substring(0, 77) + '...';
		return card;
	}
	
	render() {
		if( this.state.formState != FORM_STATE_INIT_COMPLETE ) {
			return this.renderAbnormalInit();
		}
		
		var styleDivHeader = {
			marginTop: '10px',
			marginBottom: '10px',
			padding: '10px',
			backgroundColor: 'darkblue',
			color: 'white',
			fontSize: '16pt'
		}
		var tabs_First_All_CardPanel = [
			{
				title: 'First description',
				component: <EventDescription plain={this} fieldGroup={this.instance.rootField.value.descrList.value[0]} listItemInfo={{index: 0}}/>,
			},
			{
				title: 'All descriptions',
				component: 
					<FieldList_Rows path={'/descrList'} 
						divStyle={{marginTop: '10px', padding: '15px', border: '1px solid gray', backgroundColor: 'rgb(220,220,200)'}}
						plain={this} itemComponent={EventDescription}
						title={'Description list'} toolip={'Description list'} collapsed={false}
					/>
			},
			{
				title: 'Card - Panel',
				component: 
					<FieldList_CardsPanel path={'/descrList'} 
						plain={this} itemComponent={EventDescriptionPanel}
						itemCardBuilder={this.descriptionCardBuilder}
						title={'Description list'} toolip={'Description list'}
					/>
			}
		]
		return (
			<div className={stylesPlain.divPlainMain}>
				{/* Header */}
				<div style={{display: 'flex', marginTop: '15px', marginBottom: '10px', alignItems: 'center'}}>
					<span style={{marginRight: '15px', fontSize: '16pt', fontWeight: 'bold'}}>{this.plainTitle}</span>
					<button type='button' className={stylesPlain.buttonIcon} 
						onClick={this.save}
					>
						<img src={iconSave} />
					</button>
				</div>
				
				{/* Body */}
				<div className={stylesPlain.divPlainBody} >
					
					{/*Date, type */}
					<div style={{display: 'flex', alignItems: 'center'}}>
						<Label title={'Date'} tooltip={'Dates of event'} style={{marginRight: '10px'}}/>
						<FieldString path={'/dates'} plain={this} styleInput={{width: '200px'}}	/>
						<Label title={'Type'} tooltip={'Type of event'} style={{marginLeft: '20px', marginRight: '10px'}}/>
						<FieldEnum path={'/type'} plain={this} />
					</div>
					
					{/*Name */}
					<div style={{display: 'flex', alignItems: 'center', marginTop: '10px'}}>
						<Label title={'Name'} tooltip={'Name of event'} style={{marginRight: '10px'}}/>
						<FieldString path={'/name'} plain={this} styleInput={{flexGrow: '1'}} />
					</div>
					
					<div style={styleDivHeader}>
						Variant 0. Cards and panel
					</div>
					<FieldList_CardsPanel path={'/descrList'} 
						plain={this} itemComponent={EventDescriptionPanel}
						itemCardBuilder={this.descriptionCardBuilder}
						title={'Description list'} toolip={'Description list'}
					/>
					
					<div style={styleDivHeader}>
						Variant 1. List of rows
					</div>
					<FieldList_Rows path={'/descrList'} 
						divStyle={{marginTop: '10px', padding: '15px', border: '1px solid gray', backgroundColor: 'rgb(220,220,200)'}}
						plain={this} itemComponent={EventDescription}
						title={'Description list'} toolip={'Description list'} collapsed={false}
					/>
					
					<div style={styleDivHeader}>
						Variant 2. Only first item
					</div>
					<EventDescription plain={this} fieldGroup={this.instance.rootField.value.descrList.value[0]} listItemInfo={{index: 0}}/>
					
					<div style={styleDivHeader}>
						Variant 3. Tabbed pane
					</div>
					<TabbedPane tabs={tabs_First_All_CardPanel} divStyle={{border: '1px solid gray', marginTop: '10px'}}/>
				</div>
			</div>
		)
	}
}

class EventDescription extends React.Component {

	/*
		plain
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
		this.fieldGroup = props.fieldGroup;
		this.state = {}
	}
	
	render() {
		var plain = this.props.plain;
		var listItemInfo = this.props.listItemInfo;
		var divButtonsHtml = listItemInfo.divButtonsHtml;
		// content, format, language, uri
		return (
			<div style={{marginTop: '15px', padding: '10px', backgroundColor: 'rgb(210,230,230)'}}>
				<div style={{display: 'flex', alignItems: 'center'}}>
					<Label title={'Lang' + (listItemInfo.index+1)} style={{marginRight: '10px'}}/>
					<FieldEnum path={'language'} parentField={this.fieldGroup} plain={plain}/>
					<Label title={'Format'} style={{marginLeft: '20px', marginRight: '10px'}}/>
					<FieldEnum path={'format'} parentField={this.fieldGroup} plain={plain}/>
					<div style={{flexGrow: 1}}>
						&nbsp;
					</div>
					<div>
						{divButtonsHtml}
					</div>
				</div>
				<div style={{marginTop: '10px'}}>
					Description
				</div>
				<div>
					<FieldString path={'content'} parentField={this.fieldGroup} plain={plain}
						multiline={true} rows={5} cols={60} styleInput={{width: '99%'}}
					 />
				</div>
			</div>
		)
	}
}
class EventDescriptionPanel extends React.Component {

	/*
		plain
		fieldGroup
		listItemInfo
	*/
	constructor(props) {
		super(props);
	}
	
	render() {
		var fieldGroup = this.props.fieldGroup;
		var plain = this.props.plain;
		// content, format, language, uri
		return (
			<div style={{flexGrow: '1', backgroundColor: 'lightGray'}}>
				<div style={{margin: '10px', padding: '10px', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center'}}>
						<Label title={'Lang'} style={{marginRight: '10px'}}/>
						<FieldEnum path={'language'} parentField={fieldGroup} plain={plain}/>
						<Label title={'Format'} style={{marginLeft: '20px', marginRight: '10px'}}/>
						<FieldEnum path={'format'} parentField={fieldGroup} plain={plain} />
					</div>
					<div style={{marginTop: '10px'}}>
						Description
					</div>
					<div>
						<FieldString path={'content'} parentField={fieldGroup} plain={plain}
							multiline={true} rows={5} cols={60} styleInput={{width: '99%'}}
						 />
					</div>
				</div>
			</div>
		)
	}
}

export default EventPlainPage;
