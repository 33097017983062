import React from 'react';
import styles from '../RateView.module.css';
import FilterLabel from './FilterLabel';

class FilterBooleanField extends React.Component {

	constructor(props) {
		super(props);
		props.rateView.registerFilter( this );
		var initialValue = props.filter.initialValue;
		if( initialValue == null )
			initialValue = '';
		this.state = {
			checked: (initialValue == 'true')
		}
	}
	
	clearFilter() {
		this.props.rateView.clearQueryFilter( this.props.filter.predicateId )
		this.setState({checked: false});
	}	
	handleCheckBoxChange = e => {
		this.props.rateView.clearData();
		const checked = e.target.checked;
		this.setState({checked: checked});
		
		var q = this.props.rateView.query;
		var indexFilter = -1;
		var predicate;
		for(var i=0; i < q.listPredicate.length; i++) {
			if( q.listPredicate[i].predicateId == this.props.filter.predicateId ) {
				indexFilter = i;
				break;
			}
		}
		if( indexFilter < 0 ) {
			if( checked ) {
				predicate = {
					predicateId: this.props.filter.predicateId,
					conditionId: this.props.filter.conditionId,
					value: '1'
				};
				q.listPredicate.push( predicate );
			}
		} else {
			if( checked ) {
				q.listPredicate[indexFilter].value = '1';
			} else {
				q.listPredicate.splice( indexFilter, 1 );
			}
		}
	}	
	
	render() {
		var filter = this.props.filter;
		return (
			<div className={styles.divFilter}>
				<div className={styles.divFilterInput}>
					<input type="checkbox" onChange={this.handleCheckBoxChange} checked={this.state.checked}/>
					<FilterLabel title={filter.title} tooltip={filter.tooltip} />
				</div>
			</div>
		);
	}	
}

export default FilterBooleanField;