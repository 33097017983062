import React, { Component } from 'react';
import styles from './ProfilePage.module.css';
import NavBar from '../../components/navigation/NavBar';
import Language from '../../components/navigation/Language';
import ButtonMode from '../../components/navigation/ButtonMode';
import { getUserInfo } from '../../util/localStorage';
import { getCurrentResource } from '../../util/tools';
import { performApiRequest } from '../../util/restapi';
import {
	USER_PROFILE_API_URL, CHANGE_PASSWORD_API_URL
} from '../../util/restapi';

const STATE_WAITING = 'waiting';
const STATE_READY = 'ready';

class ProfilePage extends Component {
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().pages.profile.ProfilePage;
		this.state = {
			userInfo: getUserInfo(),
			passwordDirty: false,
			formState: STATE_WAITING
		}
		this.userProfile = {};
	}
	componentDidMount = async () => {
		this.refreshProfile();
	}
	
	refreshProfile = async () => {
		this.setState({formState: STATE_WAITING});
		var request = {};
		var response = await performApiRequest( USER_PROFILE_API_URL, request );
		if( ! response.success ) {
			this.props.app.showResponse( response )
		}
		this.userProfile = response.data;
		this.setState({formState: STATE_READY});
	}	
	changePassword = async () => {
		var request = {
			oldPassword: this.password.old,
			newPassword1: this.password.new1,
			newPassword2: this.password.new2
		};
		var response = await performApiRequest( CHANGE_PASSWORD_API_URL, request );
		if( ! response.success ) {
			this.props.app.showResponse( response );
		} else {
			this.password = { old: '', new1: '', new2: '' };
			this.setState({passwordDirty:true})
			this.props.app.showMessage( this.resource.msgPasswordChanged );
		}
	}

	password = {
		old: '',
		new1: '',
		new2: ''
	}
	handlePasswordValueChange = (e) => {
		const { id, value } = e.target;
		if( id == 'old' )
			this.password.old = value;
		else if( id == 'new1' )
			this.password.new1 = value;
		else if( id == 'new2' )
			this.password.new2 = value;
		this.setState({passwordDirty:true})
	}
	
	render() {
		if( this.state.formState == STATE_WAITING )
			return (
				<React.Fragment>
					<NavBar app={this.props.app} />
					<div className={styles.divMain} style={{textAlign: 'center'}}>
					...
					</div>
				</React.Fragment>
			);

		var userInfo = this.userProfile.userInfo;
		var listGroup = '';
		for( var group of userInfo.listGroup ) {
			if( listGroup.length > 0 )
				listGroup += ', ';
			listGroup += group;
		}

		var userCard = '';	
		var organizationCard = '';
		var user = this.userProfile.user;
		if( user ) {
			var userCard = user.contact.cardMaster;	
			var orgRef = user.contact.organizationRef;
			if( orgRef )
				organizationCard = orgRef.card;
		}
		return (
			<React.Fragment>
				<NavBar app={this.props.app} />
				<div className={styles.divMain}>
					<div>
						<div style={{display: 'flex', marginBottom: '5px'}}>
							<div style={{minWidth: '150px'}}>
								{this.resource.labelLogin}:
							</div> 
							<div style={{fontWeight: 'bold'}}>
								{userInfo.login} 
							</div>
						</div>
						<div style={{display: 'flex', marginBottom: '5px'}}>
							<div style={{minWidth: '150px'}}>
								{this.resource.labelUser}: 
							</div>
							<div style={{fontWeight: 'bold'}}>
								{userCard}
							</div>
						</div>
						<div style={{display: 'flex', marginBottom: '5px'}}>
							<div style={{minWidth: '150px'}}>
								{this.resource.labelOrganization}: 
							</div>
							<div style={{fontWeight: 'bold'}}>
								{organizationCard}
							</div>
						</div>
					</div>
					<div style={{display: 'flex', marginTop: '10px'}}>
						<Password holder={this} />
						<div style={{marginLeft: '20px'}}>
							<div style={{display: 'flex', marginBottom: '15px'}}>
								<div style={{minWidth: '200px'}}>
									{this.resource.labelLanguage}: 
								</div>
								<Language app={this.props.app} />
							</div>
							<div style={{display: 'flex', marginBottom: '15px'}}>
								<div style={{minWidth: '200px'}}>
									{this.resource.labelButtonView}: 
								</div>
								<ButtonMode app={this.props.app} />
							</div>
						</div>
					</div>
					<div style={{marginTop: 20}}>
						<span style={{fontWeight: 'bold'}}>{this.resource.labelGroups}</span>: {listGroup} 
					</div>
					<div className={styles.divAccessLevel}>
						<span style={{fontWeight: 'bold'}}>{this.resource.labelAccessLevel}</span> 
						<ObjectListAccess holder={this} userInfo={userInfo}/>
						<div className={styles.divAccessLevelBlock}>
							<span style={{fontWeight: 'bold'}}>{this.resource.labelForms}</span>
							<div className={styles.divAccessLevelBlockItem}>
								<FormAccess holder={this} userInfo={userInfo}/>
							</div>
						</div>
						<div className={styles.divAccessLevelBlock}>
							<span style={{fontWeight: 'bold'}}>{this.resource.labelActions}</span>
							<div className={styles.divAccessLevelBlockItem}>
								<ActionAccess holder={this} userInfo={userInfo}/>
							</div>
						</div>
						<div className={styles.divAccessLevelBlock}>
							<span style={{fontWeight: 'bold'}}>{this.resource.labelAccess}</span>
							<div className={styles.divAccessLevelBlockItem}>
								<Access holder={this} userInfo={userInfo}/>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
	
}

function Password(props) {
	var res = props.holder.resource;
	return (
		<React.Fragment>
			<div className={styles.divPassword}>
				<div>
					<div>
						{res.labelChangePassword}
					</div>
					<div className={styles.divPasswordInput}>

							<div className={styles.divPasswordInputItem}>
								<div className={styles.divPasswordInputItemLabel}>
									{res.labelOldPassword} 
								</div>
								<div>
									<input className={styles.inputPasswordItem}
										type="password" id={'old'} value={props.holder.password.old} onChange={ props.holder.handlePasswordValueChange}
									/>
								</div>
							</div>

							<div className={styles.divPasswordInputItem}>
								<div className={styles.divPasswordInputItemLabel}>
									{res.labelNewPassword} 1 
								</div>
								<div>
									<input className={styles.inputPasswordItem}
										type="password" id={'new1'} value={props.holder.password.new1} onChange={ props.holder.handlePasswordValueChange}
									/>
								</div>
							</div>

							<div className={styles.divPasswordInputItem}>
								<div className={styles.divPasswordInputItemLabel}>
									{res.labelNewPassword} 2
								</div>
								<div>
									<input className={styles.inputPasswordItem}
										type="password" id={'new2'} value={props.holder.password.new2} onChange={ props.holder.handlePasswordValueChange}
									/>
								</div>
							</div>
							
							<div className={styles.divPasswordInputItem}>
								<button type='button' onClick={props.holder.changePassword}>{res.buttonChange}</button>
							</div>

					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

function ObjectListAccess(props) {
	var res = props.holder.resource;
	var objectAccess = props.userInfo.permission.classes;
	if( objectAccess == null )
		objectAccess = [];
	var list = (
		objectAccess.map((objectAccess, index) => {
				return (
					<div key={index} className={styles.divAccessLevelBlockItem}>
						<ObjectAccess holder={props.holder} objectAccess={objectAccess} />
					</div>
				);
		}) 
	);
	var rows = (
		objectAccess.map((objectAccess, index) => {
				return (
					<tr key={index}>
						<td>{objectAccess.title}</td>
						<ObjectActionAccess active={objectAccess.canCreate} />
						<ObjectActionAccess active={objectAccess.canRead} />
						<ObjectActionAccess active={objectAccess.canReadGroup} />
						<ObjectActionAccess active={objectAccess.canReadOwn} />
						<ObjectActionAccess active={objectAccess.canUpdate} />
						<ObjectActionAccess active={objectAccess.canUpdateGroup} />
						<ObjectActionAccess active={objectAccess.canUpdateOwn} />
						<ObjectActionAccess active={objectAccess.canDelete} />
						<ObjectActionAccess active={objectAccess.canDeleteGroup} />
						<ObjectActionAccess active={objectAccess.canDeleteOwn} />
					</tr>
				);
		}) 
	);
	return (
		<div className={styles.divAccessLevelBlock}>
			<span style={{fontWeight: 'bold'}}>{res.labelObjects}</span>
			<table border='1'>
			<thead>
				<tr>
					<td rowspan='2' align='center'>{res.colHeaderClass}</td>
					<td rowspan='2' align='center'>{res.colHeaderCreate}</td>
					<td colspan='3' align='center'>{res.colHeaderRead}</td>
					<td colspan='3' align='center'>{res.colHeaderUpdate}</td>
					<td colspan='3' align='center'>{res.colHeaderDelete}</td>
				</tr>
				<tr>
					<td align='center'>{res.permissionAll}</td>
					<td align='center'>{res.permissionGroup}</td>
					<td align='center'>{res.permissionOwn}</td>
					<td align='center'>{res.permissionAll}</td>
					<td align='center'>{res.permissionGroup}</td>
					<td align='center'>{res.permissionOwn}</td>
					<td align='center'>{res.permissionAll}</td>
					<td align='center'>{res.permissionGroup}</td>
					<td align='center'>{res.permissionOwn}</td>
				</tr>
			</thead>
			<tbody>
				{rows}
			</tbody>
			</table>
		</div>
	);
}

function ObjectActionAccess(props) {
	var mark = props.active ? '*' : ' ';
	return(
		<td align='center'>{mark}</td>
	)
}

function ObjectAccess(props) {
	var res = props.holder.resource;
	var level = '';
	if( props.objectAccess.canCreate )
		level += ' <{res.permissionCreate}>' 

	if( props.objectAccess.canRead )
		level += ' <{res.permissionReadAll}>'
	else if ( props.objectAccess.canReadGroup ) 
		level += ' <{res.permissionReadGroup}>'
	else if ( props.objectAccess.canReadOwn ) 
		level += ' <{res.permissionReadOwn}>'

	if( props.objectAccess.canUpdate )
		level += ' <{res.permissionUpdateAll>'
	else if ( props.objectAccess.canUpdateGroup ) 
		level += ' <{res.permissionUpdateGroup}>'
	else if ( props.objectAccess.canUpdateOwn ) 
		level += ' <{res.permissionUpdateOwn}>'

	if( props.objectAccess.canDelete )
		level += ' <{res.permissionDeleteAll}>'
	else if ( props.objectAccess.canDeleteGroup ) 
		level += ' <{res.permissionDeleteGroup}>'
	else if ( props.objectAccess.canDeleteOwn ) 
		level += ' <{res.permissionDeleteOwn{>'

	return (
		<React.Fragment> 
 			{props.objectAccess.title}: {level}
 		</React.Fragment> 
	);
}

function FormAccess(props) {
	var formAccess = props.userInfo.permission.forms;
	if( formAccess == null )
		formAccess = [];
	var formAccessHtml = formAccess.map( (form, index) => {
		return (
			<div key={index}>{form.title} ({form.formId})</div>
		);
	} );
	return formAccessHtml;
}

function ActionAccess(props) {
	var actionAccess = props.userInfo.permission.actions;
	if( actionAccess == null )
		actionAccess = [];
	var actionAccessHtml = actionAccess.map( (action, index) => {
		return (
			<div key={index}>{action.title} ({action.actionId})</div>
		);
	} );
	return actionAccessHtml;
}

function Access(props) {
	var accesses = props.userInfo.permission.accesses;
	if( accesses == null )
		accesses = [];
	var accessesHtml = accesses.map( (access, index) => {
		return (
			<div key={index}>{access.title} ({access.accessId})</div>
		);
	} );
	return accessesHtml;
}

export default ProfilePage;
