import React from 'react';
import { validInteger, validDecimal } from '../../../util/tools';

class FilterValueNumberField extends React.Component {

	/*
		props
			filter
			handleFilterChanged
	*/
	constructor(props) {
		super(props);
		this.filter = props.filter;
		if(this.filter.listValue.length == 0)
			this.filter.listValue.push('');
		this.state = {
			value: this.filter.listValue[0]
		}
	}
	
	handleChangeValue = (e) => {
		var inputValue = e.target.value;
		var valid = this.validValue( inputValue );
		this.filter.listValue[0] = valid ? inputValue : '';
		this.setState({value: inputValue}, this.handleFilterChanged);
	}
	handleFilterChanged = () => {
		this.props.handleFilterChanged( this.filter );
	}
	validValue = (value) => {
		if( this.filter.filterModel.decimal )
			return validDecimal( value )
		else
			return validInteger( value )
	}

	render() {
		var bg = this.validValue( this.state.value ) ? 'White' : 'LightYellow';
		return (
			<div>
				<input type='text' style={{width: '100%', backgroundColor: bg}} 
					value={this.state.value} onChange={this.handleChangeValue} 
				/>
			</div>
		);
	}
}

export default FilterValueNumberField;
