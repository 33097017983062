import React from 'react';
import stylesPlain from './plain.module.css';

import { getCurrentResource, getDefinedValue } from '../../util/tools';
import { IET_SET_VALUE } from '../../util/realm/instance';

class FieldEnum extends React.Component {
	
	resource = null;
	enumeration = null;
	field = null;

	/*
		plain                      mandatory
		instance                   mandatory
		parentField                optional
		path                       mandatory
		enumeration                optional   
		readOnly                   optional
		styleSelect                optional
	*/
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.plain.FieldEnum;
		this.init();
		this.state = {
			value: this.field ? getDefinedValue( this.field.value, '') : '',
			readOnly: getDefinedValue( props.readOnly, false)
		}
	}
	componentDidUpdate(prevProps) {
		var doInit = false;
		doInit = doInit || prevProps.instance !== this.props.instance;
		doInit = doInit || prevProps.enumeration !== this.props.enumeration;
		doInit = doInit || prevProps.parentField !== this.props.parentField;
		doInit = doInit || prevProps.path !== this.props.path;
		if ( doInit ) {
			this.init();
			this.setState({ value: getDefinedValue( this.field.value, '') });
		}
		if( prevProps.readOnly !== this.props.readOnly )
			this.setState({ readOnly: getDefinedValue( this.props.readOnly, false) });
	}
	componentDidMount() {
		if( this.props.instance ) 
			this.props.instance.addChangeListener( this );
	}
	componentWillUnmount() {
		if( this.props.instance ) 
			this.props.instance.removeChangeListener( this );
  	}
	init = () => {
		var instance = this.props.instance;
		if( instance ) {
			if( this.props.parentField )
				this.field = instance.getField(this.props.path, this.props.parentField);
			else
				this.field = instance.getField(this.props.path);
		}
		if( this.field ) {
			if( this.props.enumeration )
				this.enumeration = this.props.enumeration;
			else {
				var enumerationId = this.field.model.enumerationId;
				this.enumeration = this.props.plain.realm.getEnumeration( enumerationId );
				if( this.enumeration ) {
					if( this.enumeration.items[0].value != '' ) {
						this.enumeration.items.unshift( {value: '', label: this.resource.enumNotDefined} )
					}
				}
			}
		}
	}
	
	handleInstanceChange = async (event) => {
		if( event.eventType == IET_SET_VALUE && event.field == this.field) {
			this.setState({ value: event.newValue });
		}
	}
	handleValueChange = e => {
		var value = e.target.value;
		this.props.instance.setFieldValue( this.field, value )
	}
	render() {
		if( ! this.field )
			return ( <span>Absent field with path [{this.props.path}]</span> );
		if( ! this.enumeration )
			return ( <span>Absent enumeration with id {this.field.model.enumerationId}</span> );
		var optionsHtml = this.enumeration.items.map((item, index) => {
			return (<option key={index} value={item.value} title={item.description} >{item.label}</option>);
		});	
		//console.log('Render enum', this.field, this.state.value)
		return (
			<select className={stylesPlain.selectEnum} style={this.props.styleSelect} 
				value={this.state.value} onChange={this.handleValueChange} 
			>
				{optionsHtml}
			</select>
		)
	}
	
}

export default FieldEnum;
