import {
	DATATYPE_GROUP,
	DATATYPE_LIST,
	DATATYPE_STRING,
	DATATYPE_ENUM,
	DATATYPE_REFERENCE
} from './instance';

import { findByKey } from '../tools'; 

export class Realm {
	
	listEnumeration = [];
	listEntityFEModel = [];	
	
	constructor(realmJson) {
		this.listEnumeration = realmJson.listEnumeration;
		this.listEntityFEModel = realmJson.listEntityFEModel;
	}	

	getInstanceModel(classId) {
		if( classId == 'Event' ) {
			return eventInstanceModel;
		}
		
		return findByKey(this.listEntityFEModel, 'entityId', classId);
	}

	getEnumeration(enumerationId) {
		return findByKey(this.listEnumeration, 'enumerationId', enumerationId);
	}
	getEnumerationItemText = (enumeration, itemId) => {
		if( ! enumeration )
			return '';
		if( itemId == '' )
			return '';
		var item = findByKey(enumeration.items, 'value', itemId);
		if( ! item )
			return '';
		return item.label;
	}

}

const eventInstanceModel = {
	class: 'Event',
	subfields: [
		{
			fieldId: 'type', dataType: DATATYPE_ENUM, enumerationId: 'ev',
		},
		{
			fieldId: 'name', dataType: DATATYPE_STRING,
		},
		{
			fieldId: 'dates', dataType: DATATYPE_STRING,
		},
		{
			fieldId: 'descrList', dataType: DATATYPE_LIST,
			item: {
				fieldId: 'descrList', dataType: DATATYPE_GROUP,
				subfields: [
					{
						fieldId: 'language', dataType: DATATYPE_ENUM, enumerationId: 'lg',
					},
					{
						fieldId: 'format', dataType: DATATYPE_ENUM, enumerationId: 'tf',
					},
					{
						fieldId: 'content', dataType: DATATYPE_STRING,
					},
					{
						fieldId: 'uri', dataType: DATATYPE_LIST,
						item: {
							fieldId: 'uri', dataType: DATATYPE_STRING,	
						}
					},
				]
			}
		},
		/*
		{
			fieldId: 'locations', dataType: DATATYPE_LIST,
			title: '', tooltip: '', 
			item: {
				fieldId: 'locations', dataType: DATATYPE_GROUP,
				subfields: [
					
				]
			}
		},
		*/
		{
			fieldId: 'recordRef', dataType: DATATYPE_REFERENCE,
		},
	]
}

