import React from 'react';
import styles from './FullDescription.module.css';
import { getInstanceFieldValue, setInstanceFieldValue, getEnumeration, getEnumerationItem } from '../../util/tools';

class FieldCheckBoxList extends React.Component {
	
	constructor(props) {
		super(props);

		this.populateListItem();
		var fieldValue = getInstanceFieldValue(this.props.plain.instance, this.props.field.path);
		if( fieldValue != null ) {
			for(let selectedId of fieldValue) {
				this.setItemSelected(selectedId, true);
			}
		}

		this.state = {
			dirty: false
		}
	}
	
	listItem = [];
	populateListItem = () => {
		var enumeration = getEnumeration(this.props.plain.realm.listEnumeration, this.props.field.model.enumerationId);
		for(let item of enumeration.items) {
			this.listItem.push( {itemId: item.value, title: item.label, tooltip: item.description, selected: false} )
		}
	}
	isItemSelected = (itemId) => {
		for(let item of this.listItem) {
			if( item.itemId == itemId )
				return item.selected;
		}
		return false;
	}
	setItemSelected = (itemId, selected) => {
		for(let item of this.listItem) {
			if( item.itemId == itemId ) {
				item.selected = selected;
				break;
			}
		}
	}
	getListItemSelected = () => {
		var list = [];
		for(let item of this.listItem) {
			if( item.selected )
				list.push( item.itemId );
		}
		return list;
	}
	
	handleCheckBoxChange = (event) =>	{
		const target=event.target;
		this.setItemSelected( target.name, target.checked )
		setInstanceFieldValue(this.props.plain.instance, this.props.field.path, this.getListItemSelected());
		this.setState({ dirty: true });
	}
	
	render() {
		const rows = this.listItem.map((item, index) => {
			return <RoleCheckBox holder={this} key={index} index={index} item={item} />
		});
		return (
			<React.Fragment>
				<div>
					<div className={styles.divFieldScalarLabel}>
						{this.props.field.model.title} 
					</div>
					<div>
						{rows}
					</div>
				</div>
			</React.Fragment>
		);
	}
	
}

function RoleCheckBox(props) {
	var item = props.item;
	console.log('Role item', item)
	if( item.tooltip )
		return (
			<div style={{display: 'flex', alignItems: 'center'}}>
				<input type="checkbox" name={item.itemId} onChange={props.holder.handleCheckBoxChange} checked={item.selected} />
				<div className={styles.divCheckBoxLabelWithTooltip} style={{marginLeft: '7px'}}>
					{item.title}
					<span className={styles.tooltiptext}>{item.tooltip}</span>
				</div>
			</div>
		);
	else
		return (
			<div style={{display: 'flex', alignItems: 'center'}}>
				<input type="checkbox" name={item.itemId} onChange={props.holder.handleCheckBoxChange} checked={item.selected} />
				<div style={{marginLeft: '7px'}}>
					{item.title}
				</div>
			</div>
		);
}

export default FieldCheckBoxList;
