import React from 'react';
import Modal from 'react-responsive-modal';
import styles from './Message.module.css';
import { getCurrentResource } from '../../util/tools';

class Message extends React.Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().modal.message.Message;		
	}

	state = {
		isOpen: false
	}

	render() {
		return (
			<Modal open={this.props.isModalOpen} onClose={this.props.close} center styles={styleModal} >
				<div className={styles.divHeader}>
					{this.resource.header}
				</div>
				<div 
					className={styles.divBody} 
					dangerouslySetInnerHTML={{__html: this.props.message()}}
				>
				</div>
			</Modal>
		);
	}
}

const styleModal = {
	modal: {
		background: '#fff',
		padding: '0px',
		borderRadius: '21px',
		maxWidth: '800px',
		width: '100%',
	},
	closeButton: {
		top: '10px',
		right: '10px',
		fill: '#F00',
		outline: 'none',
	},
};


export default Message;