import React from 'react';
import Modal from 'react-responsive-modal';
import styles from './Message.module.css';
import { getCurrentResource } from '../../util/tools';

class ConfirmMessageModal extends React.Component {

	constructor(props) {
		super(props);
		this.messageHandler = props.messageHandler;
		this.resource = getCurrentResource().components.message.ConfirmMessageModal;		
	}

	state = {
		isOpen: false
	}
	
	setResponse = (confirm) => {
		this.messageHandler.confirm = confirm;	
		this.props.close();
	}

	render() {
		return (
			<Modal open={this.props.isModalOpen} onClose={this.props.close} center styles={styleModal} >
				<div className={styles.divHeader}>
					{ this.resource.header }
				</div>
				<div className={styles.divBody} >
					{ this.props.message() }
				</div>
				<div style={{textAlign: 'center', marginBottom: '30px'}}>
					<button style={localStyles.button} onClick={ ()=> {this.setResponse(true)} }>{ this.resource.buttonYes }</button>
					<button style={localStyles.button} onClick={ ()=> {this.setResponse(false)} }>{ this.resource.buttonNo }</button>
				</div>
			</Modal>
		);
	}
}

const localStyles ={
	button: {
		marginLeft: '40px',
		marginRight: '40px',
		padding: '5px 15px 5px 15px',
		fontSize: '16pt'
	}
};
const styleModal = {
	modal: {
		background: '#fff',
		padding: '0px',
		borderRadius: '21px',
		maxWidth: '500px',
		width: '100%',
	},
	closeButton: {
		top: '10px',
		right: '10px',
		fill: '#F00',
		outline: 'none',
	},
};

export default ConfirmMessageModal;