export const CONDITION_NONE = 'none';
export const CONDITION_IS_NOT_NULL = 'isNotNull';
export const CONDITION_IS_NULL = 'isNull';
export const CONDITION_INCLUDE = 'include';
export const CONDITION_NOT_INCLUDE = 'notInclude';
export const CONDITION_STARTS_WITH = 'startsWith';
export const CONDITION_NOT_STARTS_WITH = 'notStartsWith';
export const CONDITION_EQUALS = 'equals';
export const CONDITION_NOT_EQUALS = 'notEquals';
export const CONDITION_CONTAINS_TEXT = 'containsText';
export const CONDITION_NOT_CONTAINS_TEXT = 'notContainsText';
export const CONDITION_CONTAINS_ALL_WORDS = 'containsAllWords';
export const CONDITION_CONTAINS_ANY_WORD = 'containsAnyWord';
export const CONDITION_GREATER = "greater";
export const CONDITION_GREATER_EQUALS = "greaterEquals";
export const CONDITION_LESS = "less";
export const CONDITION_LESS_EQUALS = "lessEquals";
export const CONDITION_BETWEEN = "between";
export const CONDITION_NOT_BETWEEN = "notBetween";
export const CONDITION_MONTH_INCLUDE = "monthInclude";

export const findFilterIndex = (listFilter, filterId) => {
	for(let i=0; i < listFilter.length; i++) {
		let f = listFilter[i];
		if( f.filterModel.predicateId == filterId ) {
			return i;
		}
	}
	return -1;
}
export const findFilter = (listFilter, filterId) => {
	var index = findFilterIndex(listFilter, filterId);
	return ( index < 0 ) ? null : listFilter[index]; 
}

export const findConditionIndex = (listCondition, conditionId, defaultIndex) => {
	for(let i=0; i < listCondition.length; i++) {
		let c = listCondition[i];
		if( c.id == conditionId ) {
			return i;
		}
	}
	return defaultIndex;
}


export const clearFilter = (filter) => {
	filter.active = false;
	filter.bodyText = '';
	filter.conditionId = CONDITION_NONE;
	filter.listValue = [];
	filter.listValueObject = [];
}

export const findSorting = (listSorting, sortingId) => {
	for(let i=0; i < listSorting.length; i++) {
		let s = listSorting[i];
		if( s.sortingId == sortingId ) {
			return s;
		}
	}
	return null;
}
