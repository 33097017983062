import React, { Component } from 'react';
import styles from './NavBar.module.css';
import { getButtonMode, setButtonMode } from '../../util/localStorage';
import { getCurrentResource } from '../../util/tools';

class ButtonMode extends Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.navigation.ButtonMode;
		this.listOptions = [
			{value: 'icon', title: this.resource.titleIcon},
			{value: 'text', title: this.resource.titleText}
		];
}

	selectMode = (e) => {
		var mode = e.target.value;
		setButtonMode(mode);
		this.props.app.setState({buttonMode: mode});
	}

	render() {
		var mode = getButtonMode();
		var options = this.listOptions.map((o, index) => {
			return (
				<option value={o.value} selected={o.value == mode}>{o.title}</option>
			);
		});
		return (
			<React.Fragment>
				<select onChange={this.selectMode}>
					{options}
				</select>
			</React.Fragment>
		)
	}
}

export default ButtonMode;
