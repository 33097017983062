import React, { Component } from 'react';
import ConfirmMessageModal from './ConfirmMessageModal';

class ConfirmMessage extends Component {

	constructor(props) {
		super(props);
		
		this.state = {
			isModalOpen: false
		}
		
		props.messageHandler.showMessage = this.showMessage; 
	}

	openModalMessage = () => {
		this.setState({ isModalOpen: true });	
	}
	closeModalMessage = () => {
		this.setState({ isModalOpen: false }, this.props.messageHandler.doAfterClose);	
		
	}
	showMessage = msg => {
		this.message = msg;
		this.openModalMessage();
	}
	getMessageText = () => {
		return this.message;
	}
	message = '';

	render() {
		return (
			<React.Fragment>
				<ConfirmMessageModal 
					isModalOpen={this.state.isModalOpen} close={this.closeModalMessage} 
					message={this.getMessageText}
					messageHandler={this.props.messageHandler} 
				/>			
			</React.Fragment>
		)
	}
	
}

export default ConfirmMessage;
