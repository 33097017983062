import config from '../config/config';

const KEY_LANGUAGE = config.appPrefix + 'language';
const KEY_BUTTON_MODE = config.appPrefix + 'buttonMode';
const KEY_USER_INFO = config.appPrefix + 'userInfo';
const KEY_SUPER_USER_INFO = config.appPrefix + 'superUserInfo';
const KEY_RATE_VIEW_PROFILE = config.appPrefix + 'rvProfile';
const KEY_REALM = config.appPrefix + 'realm';

const anonymousUserInfo = {
	login: '',
	name: '',
	jwt: '',
	permission: {
		forms:[],
		actions: [
//			{actionId: 'exportBooks', canPerform: true}
		],
		classes: [
//			{classId: 'Book', canLoad: true, canDelete: false}
		]
	}
};

export const getLanguage = () => {
	var lang = localStorage.getItem( KEY_LANGUAGE );
	if( ! lang ) {
		lang = 'ukr';
		setLanguage(lang);
	}
	return lang;
}
export const setLanguage = (lang) => {
	localStorage.setItem(KEY_LANGUAGE, lang );
}

export const getButtonMode = () => {
	var mode = localStorage.getItem( KEY_BUTTON_MODE );
	if( ! mode ) {
		mode = 'icon';
		setButtonMode(mode);
	}
	return mode;
}
export const setButtonMode = (mode) => {
	localStorage.setItem(KEY_BUTTON_MODE, mode );
}

export const getUserInfo = () => {
	try {
		var userInfo = JSON.parse( localStorage.getItem( KEY_USER_INFO ) );
	} catch (err) {
		var userInfo = null;
	}
	if( userInfo == null ) {
		return anonymousUserInfo;
	} else {
		return userInfo;
	}
};
export const getSuperUserInfo = () => {
	try {
		var userInfo = JSON.parse( localStorage.getItem( KEY_SUPER_USER_INFO ) );
	} catch (err) {
		var userInfo = null;
	}
	return userInfo;
};

export const clearUserInfo = userInfo => {
  localStorage.removeItem(KEY_USER_INFO);
  localStorage.removeItem(KEY_SUPER_USER_INFO);
};

export const setUserInfo = userInfo => {
	if( userInfo.permission == null )
		userInfo.permission = {
			forms:[],
			actions: [],
			classes: []
		};
	localStorage.setItem(KEY_USER_INFO, JSON.stringify( userInfo ) );
};
export const setSuperUserInfo = userInfo => {
	if( userInfo == null )
		localStorage.removeItem(KEY_SUPER_USER_INFO);
	else
		localStorage.setItem(KEY_SUPER_USER_INFO, JSON.stringify( userInfo ) );
};

export const getObjectPermissions = (classId, userInfo) => {
	if( userInfo == null )
		var userInfo = getUserInfo();
	if( userInfo.permission.classes == null )
		return null;
	for(let o of userInfo.permission.classes) {
		if( classId == o.classId )
			return o;
	}
	return null;
}
export const canCreateObject = (classId, userInfo) => {
	if( userInfo == null )
		var userInfo = getUserInfo();
	if( userInfo.permission.superuser )
		return true;
	var permissions = getObjectPermissions(classId, userInfo);
	if( permissions == null )
		return false;
	return permissions.canCreate;
}
export const canReadObject = (userInfo, classId, ownerGroup, ownerUser) => {
	if( userInfo == null )
		var userInfo = getUserInfo();
	if( userInfo.permission.superuser )
		return true;
	var permissions = getObjectPermissions(classId, userInfo);
	if( permissions == null )
		return false;
	if( permissions.canRead )
		return true;	
	if( permissions.canReadOwn && userInfo.login == ownerUser )
		return true;
	if( permissions.canReadGroup && userInfo.listGroup.includes( ownerGroup ) )
		return true;
	return false;
}
export const canUpdateObject = (userInfo, classId, ownerGroup, ownerUser) => {
	if( userInfo == null )
		var userInfo = getUserInfo();
	if( userInfo.permission.superuser )
		return true;
	var permissions = getObjectPermissions(classId, userInfo);
	if( permissions == null )
		return false;
	if( permissions.canUpdate )
		return true;	
	if( permissions.canUpdateOwn && userInfo.login == ownerUser )
		return true;
	if( permissions.canUpdateGroup && userInfo.listGroup.includes( ownerGroup ) )
		return true;
	return false;
}
export const canDeleteObject = (userInfo, classId, ownerGroup, ownerUser) => {
	if( userInfo == null )
		var userInfo = getUserInfo();
	if( userInfo.permission.superuser )
		return true;
	var permissions = getObjectPermissions(classId, userInfo);
	if( permissions == null )
		return false;
	if( permissions.canDelete )
		return true;	
	if( permissions.canDeleteOwn && userInfo.login == ownerUser )
		return true;
	if( permissions.canDeleteGroup && userInfo.listGroup.includes( ownerGroup ) )
		return true;
	return false;
}
export const canViewForm = (formId, userInfo) => {
	if( userInfo == null )
		var userInfo = getUserInfo();
	if( userInfo.permission.superuser )
		return true;
	if( userInfo.permission.forms == null )
		return false;
	for(let f of userInfo.permission.forms) {
		if( formId == f.formId )
			return f.canView;
	}
	return false;
}
export const canPerformAction = (actionId, userInfo) => {
	if( userInfo == null )
		var userInfo = getUserInfo();
	if( userInfo.permission.superuser )
		return true;
	if( userInfo.permission.actions == null )
		return false;
	for(let a of userInfo.permission.actions) {
		if( actionId == a.actionId )
			return a.canPerform;
	}
	return false;
}

export const getRateViewProfile = (formId) => {
	var key = KEY_RATE_VIEW_PROFILE + "/" + formId; 
	try {
		var rvProfile = JSON.parse( localStorage.getItem( key ) );
	} catch (err) {
		var rvProfile = null;
	}
	return rvProfile;
}

export const setRealm = (realm) => {
	localStorage.setItem(KEY_REALM, JSON.stringify( realm ) );
}

export const getRealm = () => {
	try {
		var realm = JSON.parse( localStorage.getItem( KEY_REALM ) );
	} catch (err) {
		var realm = null;
	}
	return realm;
}

export const setRateViewProfile = (formId, rvProfile) => {
	var key = KEY_RATE_VIEW_PROFILE + "/" + formId; 
	localStorage.setItem(key, JSON.stringify( rvProfile ) );
}
