import React, { Component } from 'react';
import { getCurrentResource } from '../../util/tools';
import PlainFullDescription from '../../components/fulldescription/PlainFullDescription';
import styles from '../../components/fulldescription/FullDescription.module.css';

import { selectTopicReference } from './util';

import { FORM_AUTHORITY_AUTHOR_FT } from '../../util/consts/forms';
import { CLASS_AUTHORITY_AUTHOR } from '../../util/consts/classes';

class AuthorityAuthorPage extends Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().pages.plain.AuthorityAuthor;
		this.plainInfo = {
			app: props.app,
			page: this,
			class: CLASS_AUTHORITY_AUTHOR,
			instanceModelId: FORM_AUTHORITY_AUTHOR_FT,
			instanceId: this.props.match.params.id,
			param: {}
		};
	}

	selectTopic = async (fieldReference) => {
		await selectTopicReference(fieldReference);
	}

	render() {
		return (
			<React.Fragment>
				<div className={styles.divPlainTitle}>
					{this.resource.title}
				</div>
				<PlainFullDescription plain={this.plainInfo} />
			</React.Fragment>
		)
	}
}

export default AuthorityAuthorPage;
