import React, { createRef } from 'react';
import styles from './FullDescription.module.css';
import Label from './Label';
import { initFieldStyles } from './util';
import { getCurrentResource, getInstanceFieldValue, getInstanceFieldFile, setInstanceFieldValue } from '../../util/tools';
import { getLanguage, getUserInfo } from '../../util/localStorage';

import selectIcon from '../../assets/icons/png24x24/Search.png';
import downloadIcon from '../../assets/icons/png24x24/Download.png';
import clearIcon from '../../assets/icons/png24x24/Delete.png';

import { FILE_WAS_SET, FILE_WAS_CLEARED } from '../../util/consts/common';

class FieldFile extends React.Component {

	constructor(props) {
		super(props);
		var fieldComponent = {
			field: this,
			path: props.field.path
		}
		props.plain.listFieldComponent.push(fieldComponent);
		this.resource = getCurrentResource().components.fulldescription.FieldFile;
		this.file = null;
		this.fileRef = createRef();
		
		// This call garantees that instance...fieldFile is not null
		var fieldFile = getInstanceFieldFile(props.plain.instance, props.field.path);
		if( fieldFile.sourceFileName == null || fieldFile.sourceFileName == 'null' )
			fieldFile.sourceFileName = '';

		this.pathFileState = [... props.field.path];
		this.pathFileState.push('fileState')
		this.pathFileBody = [... props.field.path];
		this.pathFileBody.push('fileBody')
		this.pathFileName = [... props.field.path];
		this.pathFileName.push('sourceFileName')
		this.pathStore = [... props.field.path];
		this.pathStore.push('storeId');
		this.pathUrl = [... props.field.path];
		this.pathUrl.push('fileURL');
		
		this.state = {
			dirty: false,
			inst: props.plain.instance,
			fileName: fieldFile.sourceFileName,
			fileState: ''
		}
	}

	handleFileSelect = (e) => {
		var files = e.target.files;
		if( files && files.length > 0 ) {
			var instance = this.state.inst;
			setInstanceFieldValue(instance, this.pathFileState, FILE_WAS_SET);
			setInstanceFieldValue(instance, this.pathFileBody, files[0]);
			this.setState({dirty: true, fileName: files[0].name, fileState: FILE_WAS_SET})
		}
	}
	clearFile = () => {
		var instance = this.state.inst;
		if( this.state.fileState == FILE_WAS_SET ) {
			setInstanceFieldValue(instance, this.pathFileState, '');
			setInstanceFieldValue(instance, this.pathFileBody, undefined);
			var fileName = getInstanceFieldValue(this.state.inst, this.pathFileName);
			this.setState({dirty: true, fileName: fileName, fileState: ''})
		} else {
			setInstanceFieldValue(instance, this.pathFileState, FILE_WAS_CLEARED);
			this.setState({dirty: true, fileName: '', fileState: FILE_WAS_CLEARED})
		}
	}
	downloadFile = async () => {
		var instance = this.state.inst;
		var inst = instance.id;
		var origin = getInstanceFieldValue(instance, this.pathFileName);		
		var ds = getInstanceFieldValue(instance, this.pathStore);		
		var file = getInstanceFieldValue(instance, this.pathUrl);
		
		var url = 'http://localhost:8085/files/download';
		url += '?inst=' + inst;
		url += '&ds=' + ds;
		url += '&file=' + file;
		url += '&origin=' + origin;
		
		console.log(url);
				
		var xhr = new XMLHttpRequest();
		xhr.open('GET', url, true);
		xhr.responseType = 'blob';
		xhr.setRequestHeader('Accept-Language', getLanguage() );
		var userInfo = getUserInfo();
		if( userInfo.jwt ) {
			xhr.setRequestHeader('Authorization', 'Bearer ' + userInfo.jwt );
		}
		xhr.onprogress = e => console.log(`${parseInt((e.loaded/e.total)*100)}%`);
		xhr.onload = async (e) => {
			if( xhr.status != 200 ) {
				const text = await xhr.response.text();
				this.props.plain.app.showMessage(text);
				console.log( text );
			} else {
				this.save(xhr.response, origin);
			} 
		}
		xhr.send();
	}
	save = (blob, filename) => {
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = url;
		a.download = filename; //filename
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
		console.log('your file has downloaded!');
	}

	render() {
		var fieldModel = this.props.field.model;
		var styleFieldScalar = {};
		var styleLabel = {};
		initFieldStyles( this.props.field, styleFieldScalar, styleLabel );
		
		var instance = this.state.inst;
		var savedFileName = getInstanceFieldValue(instance, this.pathFileName);
		var fileState = this.state.fileState;
		
		var hasSavedFile = savedFileName != undefined && savedFileName != null && savedFileName != '';
		var hasSelectedFile = fileState == FILE_WAS_SET;

		var buttonSelectFileDisabled = fieldModel.readOnly;
		var buttonDownloadFileDisabled = ! hasSavedFile || hasSelectedFile;
		var buttonClearFileDisabled = fieldModel.readOnly || ! hasSavedFile && ! hasSelectedFile;
		
		if( hasSelectedFile )
			var buttonDownloadFileTooltip = this.resource.buttonDownloadFileAfterSave;
		else
			var buttonDownloadFileTooltip = this.resource.buttonDownloadFile;
		
		return (
			<div className={styles.divFieldScalar} style={styleFieldScalar}>
				<Label fieldModel={fieldModel} style={styleLabel} />
				<div className={styles.divFieldScalarValue} style={{display: 'flex', alignItems: 'center'}}>
					<div style={{flexGrow: '100'}}>
						{this.state.fileName}
					</div> 
					<input ref={this.fileRef} type={'file'} style={{display: 'none'}} onChange={e => this.handleFileSelect(e)} />
					<button style={{marginLeft: '10px'}}
						onClick={e => this.fileRef.current.click()}
						title={this.resource.buttonSelectFile}
						disabled={buttonSelectFileDisabled}
					>
						<img src={selectIcon} />
					</button>
					<button style={{marginLeft: '5px'}}
						onClick={this.downloadFile} 
						title={buttonDownloadFileTooltip}
						disabled={buttonDownloadFileDisabled}
					>
						<img src={downloadIcon} />
					</button>
					<button style={{marginLeft: '5px'}}
						onClick={this.clearFile} 
						title={this.resource.buttonClearFile}
						disabled={buttonClearFileDisabled}
					>
						<img src={clearIcon} />
					</button>
				</div>
			</div>
		);
	}	
}

export default FieldFile;
