import React, { Component } from 'react';
import styles from './Message.module.css';
import NavBar from '../../components/navigation/NavBar';

class BomMessagePage extends Component {
	constructor(props) {
		super(props);
	}
	
	render() {
		return (
			<React.Fragment>
				<NavBar />
				<div className={styles.divMessage}>
					{this.props.message}
				</div>
			</React.Fragment>
		)
	}
	
}

export default BomMessagePage;
