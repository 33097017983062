import React from 'react';
import { 
	dateInputFormatToRegularFormat, 
	dateRegularFormatToInputFormat 
} from '../../../util/tools';

class FilterValueDateField extends React.Component {

	/*
		props
			filter
			handleFilterChanged
	*/
	constructor(props) {
		super(props);
		this.filter = props.filter;
		if(this.filter.listValue.length == 0)
			this.filter.listValue.push('');
		this.state = {
			value: dateRegularFormatToInputFormat( this.filter.listValue[0] )
		}
	}
	
	handleChangeValue = (e) => {
		var inputValue = e.target.value;
		this.filter.listValue[0] = dateInputFormatToRegularFormat( inputValue );
		this.setState({value: inputValue}, this.handleFilterChanged);
	}
	handleFilterChanged = () => {
		this.props.handleFilterChanged( this.filter );
	}

	render() {
		return (
			<div>
				<input type='date' name='dateValue' style={{width: '100%'}} 
					value={this.state.value} onChange={this.handleChangeValue} 
				/>
			</div>
		);
	}
}

export default FilterValueDateField;
