import React from 'react';
import styles from '../RateView.module.css';
import FilterLabel from './FilterLabel';

class FilterOneField extends React.Component {

	constructor(props) {
		super(props);
		var rateView = props.rateView;
		rateView.registerFilter( this );
		var filterValue = '';
		if( rateView.rvContext && rateView.rvContext.filtersInitData ) {
			for(var f of rateView.rvContext.filtersInitData) {
				if( f.predicateId == props.filter.predicateId ) {
					filterValue = f.value;
					break;
				}
			}
		}
		this.state = {
			value: filterValue
		}
		if( filterValue )
			this.updateQueryByValue( filterValue );
	}

	clearFilter = () => {
		this.props.rateView.clearQueryFilter( this.props.filter.predicateId )
		this.setState({value: ''});
	}
	handleEdit = e => {
		var value = e.target.value; //.trim();
		this.setValue( value );
	}	
	setValue = (value) => {
		this.props.rateView.clearData();
		this.setState({value: value});
		var trimmedValue = value.trim();
		if( trimmedValue == '' ) {
			this.props.rateView.clearQueryFilter( this.props.filter.predicateId )
			return;
		}
		this.updateQueryByValue( trimmedValue );
	}
	updateQueryByValue = (value) => {
		var q = this.props.rateView.query;
		var indexFilter = -1;
		var predicate;
		for(var i=0; i < q.listPredicate.length; i++) {
			if( q.listPredicate[i].predicateId == this.props.filter.predicateId ) {
				indexFilter = i;
				break;
			}
		}
		if( indexFilter < 0 ) {
			q.listPredicate.push( {
				predicateId: this.props.filter.predicateId,
				conditionId: this.props.filter.conditionId,
				value: value
			} );
		} else {
			q.listPredicate[indexFilter].value = value;
		}
	}
	
	render() {
		var filter = this.props.filter;
		return (
			<div className={styles.divFilter}>
				<FilterLabel title={filter.title} tooltip={filter.tooltip} />
				<div className={styles.divFilterInput}>
					<input className={styles.inputFilterValue} type='text' value={this.state.value} onChange={this.handleEdit}/>
				</div>
			</div>
		);
	}	
}

export default FilterOneField;
