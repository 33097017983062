import React from 'react';

class FilterValueList extends React.Component {

	/*
		props
			items
			filter
			handleFilterChanged
	*/
	constructor(props) {
		super(props);
		this.items = props.items;
		this.filter = props.filter;
		this.state = {
			values: this.filter.listValue
		}
	}

	handleValueClick = (e) => {
		var options = e.target.options;
		var values = [];
		for (var i = 0, l = options.length; i < l; i++) {
			if (options[i].selected) {
				values.push(options[i].value);
			}
		}
		this.filter.listValue = values;
		this.setState({values: values}, this.handleFilterChanged);
	}
	handleFilterChanged = () => {
		this.props.handleFilterChanged( this.filter );
	}

	render() {
		var optionsHtml = this.items.map( (item, index) => {
			var selected = this.filter.listValue.includes(item.id);
			return <option key={index} value={item.id} selected={selected}>{item.title}</option>
		} );
		return (
			<div>
				<select multiple={true} size='13' style={{width: '100%'}} onChange={this.handleValueClick} >
					{optionsHtml}
				</select>
			</div>
		);
	}
}

export default FilterValueList;
