import React, { createRef } from 'react';
import Modal from 'react-responsive-modal';
import styles from './Exim.module.css';
import { getCurrentResource } from '../../util/tools';
import { 
	ACTION_ONIXIMPORT_API_URL, ACTION_ONIXIMPORT_LOCAL_API_URL, ACTION_ONIXIMPORT_ELIBRI_API_URL, 
	performImportRequest, performApiRequest 
} from '../../util/restapi';

const FORM_STATE_GET_DATA = 'gd';
const FORM_STATE_WAITING = 'w';
const FORM_STATE_IMPORT_COMPLETE = 'ic';

const IMPORT_MODE_NETWORK = 'network';
const IMPORT_MODE_LOCAL = 'local';
const IMPORT_MODE_ELIBRI = 'elibri';

class OnixImportModal extends React.Component {

	/*
		props
			holder
	*/
	constructor(props) {
		super(props);
		props.holder.componentOnixImportModal = this;
		this.resource = getCurrentResource().modal.exim.OnixImportModal;		
		this.importOptions = {
			saveOriginal: false,
			downloadImages: false,
			ignoreInvalid: false,
			ignoreWarning: false,
			testMode: false,
			filePath: ''
		};
		this.fileImportRef = createRef();
		this.state = {
			formDirty: false,
			formState: FORM_STATE_GET_DATA,
			importMode: IMPORT_MODE_NETWORK
		};
		this.init();		
	}
	init = async () => {
		this.importResultMessage = "";
		this.fileName = "Not selected";
		this.importResultMessage = '';
		this.importOptions.file = null;
		this.importOptions.saveOriginal = false;
		this.importOptions.downloadImages = false;
		this.importOptions.ignoreInvalid = false;
		this.importOptions.ignoreWarning = false;
		this.importOptions.testMode = false;
		//this.importOptions.filePath = '';
		this.setState({formState: FORM_STATE_GET_DATA, fileName: this.resource.noFileSelected});
	}
	
	onParameterChange = (event) =>	{
		const {name, checked} = event.target;
		if( name == 'saveOriginal' )
			this.importOptions.saveOriginal = checked;
		if( name == 'downloadImages' )
			this.importOptions.downloadImages = checked;
		if( name == 'ignoreInvalid' )
			this.importOptions.ignoreInvalid = checked;
		if( name == 'ignoreWarning' )
			this.importOptions.ignoreWarning = checked;
		if( name == 'testMode' )
			this.importOptions.testMode = checked;
		this.setState({ dirty: true });
	}
	onImportModeChange = (event) =>	{
		this.setState({ importMode: event.target.value });
	}
	onFilePathChange = (event) =>	{
		this.importOptions.filePath = event.target.value;
		this.setState({ dirty: true });
	}
	handleFileSelect = (e) => {
		var files = e.target.files;
		if( files && files.length > 0 ) {
			this.importOptions.file = files[0];
			this.setState({fileName: this.importOptions.file.name})
		}
	}	
	
	startImport = async () => {
		this.setState({formState: FORM_STATE_WAITING});
		var importOptions = {
			saveOriginal: this.importOptions.saveOriginal,
			downloadImages: this.importOptions.downloadImages,
			ignoreInvalid: this.importOptions.ignoreInvalid,
			ignoreWarning: this.importOptions.ignoreWarning,
			testMode: this.importOptions.testMode,
			filePath: this.importOptions.filePath,
		}
		if( this.state.importMode == IMPORT_MODE_NETWORK ) {
			const form = new FormData();
			form.append('importOptions', JSON.stringify(importOptions));
			form.append('data', this.importOptions.file);
			var response = await performImportRequest(ACTION_ONIXIMPORT_API_URL, form);
		} else if( this.state.importMode == IMPORT_MODE_LOCAL ) {
			var response = await performApiRequest( ACTION_ONIXIMPORT_LOCAL_API_URL, importOptions );
		} else if( this.state.importMode == IMPORT_MODE_ELIBRI ) {
			var response = await performApiRequest( ACTION_ONIXIMPORT_ELIBRI_API_URL, importOptions );
		}

		this.importResultMessage = response.log;
		if( ! this.importResultMessage ) {
			if( response.success && ! response.message )
				this.importResultMessage = this.resource.msgSuccess;
			else
				this.importResultMessage = response.message;
		}
		this.setState({formState: FORM_STATE_IMPORT_COMPLETE});
	}
	
	repeatImport = () => {
		console.log('Repeat import');
		this.importResultMessage = '';
		this.setState({formState: FORM_STATE_GET_DATA});
	}

	render() {
		return (
			<Modal 
				open={this.props.isModalOpen} onClose={this.props.close} center styles={styleModal}
				closeOnOverlayClick={false} 
			>
				<ImportForm holder={this} />
			</Modal>
		);
	}
}

function ImportForm(props) {
	var holder = props.holder;
	var res = holder.resource;
	var inputDisabled = props.holder.state.formState != FORM_STATE_GET_DATA;
	var repeatDisabled = props.holder.state.formState != FORM_STATE_IMPORT_COMPLETE;

	if( holder.state.formState == FORM_STATE_GET_DATA ) {
		var divWaiting = (null);
		var divMessage = (null);
	} else if( holder.state.formState == FORM_STATE_WAITING ) {
		var divWaiting = <div className={styles.divWaiting}>{res.waiting}</div>
		var divMessage = (null);
	} else if( holder.state.formState == FORM_STATE_IMPORT_COMPLETE ) {
		var divWaiting = (null)
		var divMessage = (
			<textarea className={styles.areaMessage} rows="4" readOnly={true}>
				{props.holder.importResultMessage}
			</textarea>		
		);
	}
	
	if( holder.state.importMode == IMPORT_MODE_NETWORK ) {
		var divFile = (
			<div style={{marginTop: '15px'}}>
				{res.labelFile}: <span>{props.holder.state.fileName}</span> 
				&nbsp;<button disabled={inputDisabled} onClick={e => props.holder.fileImportRef.current.click()}>{res.buttonSelectFile}</button>
			</div>
		);
	} else if( holder.state.importMode == IMPORT_MODE_LOCAL ) {
		var divFile = (
			<div style={{marginTop: '15px'}}>
				{res.labelFile}: <input type='text' size='40' value={holder.importOptions.filePath} onChange={holder.onFilePathChange} /> 
			</div>
		);
	} else { // IMPORT_MODE_ELIBRI
		divFile = null;
	}

	return (
		<React.Fragment>
			<div className={styles.divHeader}>
				{res.title}
			</div>
			<div className={styles.divParameters}>
				<p>
					<input type='checkbox' name='saveOriginal' onChange={props.holder.onParameterChange} checked={holder.importOptions.saveOriginal} />&nbsp;
					{res.labelSaveOriginal}
				</p>
				<p>
					<input type='checkbox' name='downloadImages' onChange={props.holder.onParameterChange} checked={holder.importOptions.downloadImages} />&nbsp;
					{res.labelDownloadImages}
				</p>
				<p>
					<input type='checkbox' name='ignoreInvalid' onChange={props.holder.onParameterChange} checked={holder.importOptions.ignoreInvalid} />&nbsp;
					{res.labelIgnoreInvalid}
				</p>
				<p>
					<input type='checkbox' name='ignoreWarning' onChange={props.holder.onParameterChange} checked={holder.importOptions.ignoreWarning} />&nbsp;
					{res.labelIgnoreWarning}
				</p>
				<p>
					<input type='checkbox' name='testMode' onChange={props.holder.onParameterChange} checked={holder.importOptions.testMode} />&nbsp;
					{res.labelTestMode}
				</p>
				<div>
					<select value={holder.state.importMode} onChange={holder.onImportModeChange}>
						<option value={IMPORT_MODE_NETWORK}>{res.optionNetworkImport}</option>
						<option value={IMPORT_MODE_LOCAL}>{res.optionLocalImport}</option>
						<option value={IMPORT_MODE_ELIBRI}>{res.optionElibriImport}</option>
					</select>
				</div>
				{divFile}
				<input ref={props.holder.fileImportRef} type={'file'} className={styles.hiddenFile} onChange={e => props.holder.handleFileSelect(e)} />
			</div>
			{divWaiting}
			{divMessage}
			<div className={styles.divButtons}>
				<button 
					onClick={holder.startImport} 
					disabled={inputDisabled} 
					title={res.buttonImportTooltip}
				>
					{res.buttonImport}
				</button>
				<button 
					style={{marginLeft: '20px'}}
					onClick={holder.repeatImport} 
					disabled={repeatDisabled} 
					title={res.buttonRepeatTooltip}
				>
					{res.buttonRepeat}
				</button>
			</div>
		</React.Fragment>
	);
}

const styleModal = {
	modal: {
		background: '#ffffff',
		padding: '0px',
		borderRadius: '21px',
		maxWidth: '500px',
		width: '100%',
	},
	closeButton: {
		top: '10px',
		right: '10px',
		fill: '#FF0000',
		outline: 'none',
	},
};


export default OnixImportModal;
