import React from 'react';
import Modal from 'react-responsive-modal';
import TabRowColumns from './TabRowColumns';
import TabFilters from './TabFilters';
import TabSorting from './TabSorting';
import { getCurrentResource } from '../../../util/tools';

const TAB_ROWS_COLUMNS = 'rowcols';
const TAB_FILTERS = 'filters';
const TAB_SORTING = 'sorting';

class ViewConditionsModal extends React.Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.rateview.conditions.ViewConditionsModal;
		this.rateView = props.rateView;

		var dlgViewConditions = this.rateView.formInfo.dlgViewConditions; 
		if( dlgViewConditions.showTabRowColumns ) {
			var activeTab = TAB_ROWS_COLUMNS;
		} else if( dlgViewConditions.showTabFilters ) {
			var activeTab = TAB_FILTERS;
		} else if( dlgViewConditions.showTabSorting ) {
			var activeTab = TAB_SORTING;
		} else {
			var activeTab = null;
		}
				
		this.modalSuccess = false;
		this.state = {
			queryOptions: {},
			activeTab: activeTab
		};
	}
	updateQueryOptions = (queryOptions) => {
		this.modalSuccess = false;
		this.setState( {queryOptions: queryOptions} )
	}

	setActiveTab = (tabId) => {
		this.setState({activeTab: tabId});
	}
	actionOk = () => {
		this.modalSuccess = true;
		this.props.closeModal();
	}
	actionCancel = () => {
		this.modalSuccess = false;
		this.props.closeModal();
	}
		
	render() {
		return (
			<Modal 
				open={this.props.isModalOpen} 
				onClose={this.props.closeModal} center 
				styles={styleModal}
				closeOnOverlayClick={false} animationDuration={1}
			>
				<div style={localStyles.divMain}>
					<div style={localStyles.divHeader}>
						{this.resource.header}
					</div>
					<div style={localStyles.divBody}>
						<Tabs holder={this} />
						<div style={{padding: '10px', background: '#f8f8f8', textAlign: 'left'}}>
							<TabBody holder={this} />
						</div>
					</div>
					<div style={{margin: '10px', textAlign: 'right'}}>
						<button onClick={this.actionOk}>{this.resource.buttonOk}</button>
						<button style={{marginLeft: '20px'}} onClick={this.actionCancel}>{this.resource.buttonCancel}</button>
					</div>
				</div>
			</Modal>
		);
	}
	
}

function Tabs(props) {
	var holder = props.holder;
	var dlgViewConditions = holder.rateView.formInfo.dlgViewConditions;
	var res = holder.resource;
	return (
		<div style={{display: 'flex', background: 'lightgray', padding: '10px 10px 0 10px'}}>
			{ dlgViewConditions.showTabRowColumns ? <Tab holder={props.holder} id={TAB_ROWS_COLUMNS} title={res.tabRowsColumns} /> : null}
			{ dlgViewConditions.showTabFilters ? <Tab holder={props.holder} id={TAB_FILTERS} title={res.tabFilters} /> : null}
			{ dlgViewConditions.showTabSorting ? <Tab holder={props.holder} id={TAB_SORTING} title={res.tabSorting} /> : null}
		</div>
	);
}
function Tab(props) {
	var tabId = props.id; 
	if( tabId == props.holder.state.activeTab )
		var tabStyle = localStyles.divActiveTab;
	else
		var tabStyle = localStyles.divNotActiveTab;
	return (
		<div style={tabStyle} onClick={ ()=>{props.holder.setActiveTab(tabId)} }>
			{props.title}
		</div>
	);
}
function TabBody(props) {
	var holder = props.holder;
	var tabId = holder.state.activeTab;
	
	if( tabId == TAB_ROWS_COLUMNS )
		return ( <TabRowColumns rateView={holder.rateView} queryOptions={holder.state.queryOptions} /> );
	if( tabId == TAB_FILTERS )
		return ( <TabFilters rateView={holder.rateView} queryOptions={holder.state.queryOptions} /> );
	if( tabId == TAB_SORTING )
		return ( <TabSorting rateView={holder.rateView} queryOptions={holder.state.queryOptions} /> );
		
	return null;
}

const styleModal = {
	modal: {
		background: '#ffffff',
		padding: '0px',
		borderRadius: '21px 21px 0 0',
		maxWidth: '1000px',
		width: '95%',
	},
	closeButton: {
		top: '10px',
		right: '10px',
		fill: '#FF0000',
		outline: 'none',
	},
};

const localStyles = {
	divMain: {
	},
	divHeader: {
		fontSize: '16pt',
		textAlign: 'center',
		padding: '10px',
		borderRadius: '21px 21px 0 0',
		backgroundColor: '#F0F0F0'
	},
	divBody: {
		padding: '10px'
	},
	divActiveTab: {
		margin: '10px 0px 0 0px', /* top | right | bottom | left */
		padding: '5px 10px 5px 10px',
		fontWeight: 'bold',
		borderLeft: '1px solid grey',
		borderRight: '1px solid grey',
		borderTop: '1px solid grey',
		borderRadius: '20px 0px 0 0',
		background: '#f8f8f8',
		cursor: 'pointer'
	},	
	divNotActiveTab: {
		margin: '10px 0px 0 0px',
		padding: '5px 10px 5px 10px',
		borderLeft: '1px solid grey',
		borderRight: '1px solid grey',
		borderTop: '1px solid grey',
		borderRadius: '20px 0px 0 0',
		background: 'lightgray',
		cursor: 'pointer'
	},	
};


export default ViewConditionsModal;