const dev = {
  appPrefix: 'dev.',
  useMockData: false,
  authEnabled: true,
  PATH: '',
  apiGateway: {
    URL: 'http://localhost:8085',
    LOGIN_REDIRECT: '',
    BASE_PATH: '',
  },
  logging: {
    apiRequests: true,
  },
  debugMode: true
};

const dev8086 = {
  appPrefix: 'dev.',
  useMockData: false,
  authEnabled: true,
  PATH: '',
  apiGateway: {
    URL: 'http://localhost:8086',
    LOGIN_REDIRECT: '',
    BASE_PATH: '',
  },
  logging: {
    apiRequests: true,
  },
  debugMode: true
};

const prod = {
  appPrefix: 'prod.',
  useMockData: false,
  authEnabled: true,
  PATH: '/bom',
  apiGateway: {
    URL: 'https://ush.kyiv.ua:8443',
    LOGIN_REDIRECT: '',
    BASE_PATH: 'bom',
  },
  logging: {
    apiRequests: false,
  },
  debugMode: false
};

const demo = {
  appPrefix: 'demo.',
  useMockData: false,
  authEnabled: true,
  apiGateway: {
    URL: '',
    LOGIN_REDIRECT: '',
    BASE_PATH: '',
  },
  logging: {
    apiRequests: true,
  },
};

const devTomcat = {
  appPrefix: 'devTomcat.',
  useMockData: false,
  authEnabled: true,
  apiGateway: {
    URL: '',
    BASE_PATH: '',
  },
  logging: {
    apiRequests: true,
  },
};

const stagingTomcat = {
  appPrefix: 'stagingTomcat.',
  useMockData: false,
  authEnabled: true,
  apiGateway: {
    URL: 'https://185.69.153.40/api/v1',
    LOGIN_REDIRECT: '',
    BASE_PATH: '',
  },
  logging: {
    apiRequests: false,
  },
};


let config;

switch (process.env.REACT_APP_STAGE) {
  case 'dev':
    config = dev;
    break;
  case 'dev8086':
    config = dev8086;
    break;
  case 'demo':
    config = demo;
    break;
  case 'devTomcat':
    config = devTomcat;
    break;
  case 'stagingTomcat':
    config = stagingTomcat;
    break;
  case 'prod':
    config = prod;
    break;
  default:
    config = dev;
    break;
}

export default config;
