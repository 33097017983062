import React from 'react';
import stylesPlain from '../../../components/plain/plain.module.css';

import iconAdd from '../../../assets/icons/png24x24/Add.png';
import iconMoveUp from '../../../assets/icons/png24x24/Up.png';
import iconMoveDown from '../../../assets/icons/png24x24/Down.png';
import iconDelete from '../../../assets/icons/png24x24/Delete.png';

class TableAuthorsShort extends React.Component {

	fieldList = null;
	
	/*
		plain
		plainDirtyCounter
		instance
		readOnly
	*/
	constructor(props) {
		super(props);
		this.init();
		this.state = {
			selectedIndex: -1,
			readOnly: false,
			value: this.fieldList ? this.fieldList.value : null
		}
	}
	componentDidUpdate(prevProps) {
		var doInit = false;
		doInit = doInit || prevProps.instance !== this.props.instance;
		if ( doInit ) {
			this.init();
			this.setState({ value: this.fieldList.value });
		}
	}
	componentDidMount() {
		if( this.props.instance ) 
			this.props.instance.addChangeListener( this );
	}
	componentWillUnmount() {
		if( this.props.instance ) 
			this.props.instance.removeChangeListener( this );
  	}
	init = () => {
		if( this.props.instance )
			this.fieldList = this.props.instance.rootField.value.contributor; 
	}
	
	render() {
		var names = [];
		if( this.fieldList ) {
			for( let author of this.fieldList.value) {
				names.push( author.value.names.value[0] );
			}
		}
		console.log('names', names);
		var rowsHtml = names.map((name, index) => {
			return (
				<tr key={index}>
					<td>
						{name.value.language.value}
					</td>
					<td>
						{name.value.keyName.value}
					</td>
					<td>
						{name.value.name.value}
					</td>
					<td>
						{name.value.secondName.value}
					</td>
					<td>
						{name.value.otherName.value}
					</td>
					<td>
						{name.value.shortName.value}
					</td>
					<td>
						{name.value.fullName.value}
					</td>
				</tr>
			)
		});
		return (
			<div>
				<div style={{display: 'flex', alignItems: 'center', marginBottom: '5px'}}>
					<div style={{flexGrow: '0', marginRight: '10px'}}>{'Автори'}</div>
					<div>
						<button type='button' className={stylesPlain.buttonIcon}
							onClick={this.addItem} title={'Додати автора'}
						>
							<img src={iconAdd} />
						</button>
						<button type='button' className={stylesPlain.buttonIcon} style={{marginLeft: '15px'}}
							onClick={this.deleteItem}
						>
							<img src={iconDelete} />
						</button>
					</div>
				</div>
				<table border='1' style={{width: '1000px'}}>
					<thead style={{}}>
						<tr style={{}}>
							<td kstyle={{width: '100px'}}>
								{'language'}
							</td>
							<td kstyle={{width: '100px'}}>
								{'keyName'}
							</td>
							<td kstyle={{width: '100px'}}>
								{'name'}
							</td>
							<td kstyle={{width: '100px'}}>
								{'secondName'}
							</td>
							<td kstyle={{width: '100px'}}>
								{'otherName'}
							</td>
							<td kstyle={{width: '100px'}}>
								{'shortName'}
							</td>
							<td kstyle={{width: '100px'}}>
								{'fullName'}
							</td>
						</tr>
					</thead>
					<tbody>
						{rowsHtml}
					</tbody>
				</table>
			</div>
		);
	}	
}

export default TableAuthorsShort; 