import React from 'react';
import styles from './FullDescription.module.css';
import ButtonIconText from '../button/ButtonIconText';
import Label from './Label';
import { initFieldStyles } from './util';
import { getCurrentResource, getInstanceFieldValue, setInstanceFieldValue, validInteger } from '../../util/tools';
import { getButtonMode } from '../../util/localStorage';

import deleteIcon from '../../assets/icons/png24x24/Delete.png';

class FieldInteger extends React.Component {

	constructor(props) {
		super(props);
		this.isBoolean = props.field.model.boolean;
		this.resource = getCurrentResource().components.fulldescription.FieldInteger;
		this.state = {
			dirty: false,
			valid: true
		}
	}
	
	handleValueChange = e => {
		const { name, value } = e.target;
		var valid = validInteger( value );
		var instance = this.props.plain.instance;
		setInstanceFieldValue(instance, this.props.field.path, value);
		this.setState({ dirty: true, valid: valid });
	};
	handleCheckBoxChange = (event) =>	{
		const target=event.target;
		var value = target.checked ? 1 : 0;
		setInstanceFieldValue(this.props.plain.instance, this.props.field.path, value);
		this.setState({ dirty: true });
	}
	
	render() {
		if( this.props.listItem ) {
			var listItemTools = this.props.listItem.tools;
		} else {
			var listItemTools = (null);
		}

		var path = this.props.field.path;
		var id = path[ path.length-1 ];
		var value = getInstanceFieldValue(this.props.plain.instance, this.props.field.path);
		if( value == null )
			value = '';
	
		var styleFieldScalar = {};
		var styleLabel = {};
		initFieldStyles( this.props.field, styleFieldScalar, styleLabel );
	
		if( this.isBoolean ) {
			value = ( value == 1 );
			return (
				<React.Fragment>
				<div className={styles.divFieldScalar} style={styleFieldScalar}>
						<input type="checkbox" id={id} name={id} onChange={this.handleCheckBoxChange} checked={value} />
						&nbsp;{this.props.field.model.title}
					</div>
				</React.Fragment>
			);
		} else {
	
			return (
				<React.Fragment>
					<div className={styles.divFieldScalar} style={styleFieldScalar}>
						<Label listItem={this.props.listItem} fieldModel={this.props.field.model} style={styleLabel} />
						<div className={styles.divFieldScalarValue}>
							<input className={ this.state.valid ? styles.inputFieldScalarValue : styles.inputFieldScalarValueInvalid}
								style={{width: 'calc(100% - 10px)'}}
								type="text"
								id={id}
								name={id}
								value={value}
								onChange={ this.handleValueChange}
							/>
						</div>
						{listItemTools}
					</div>
				</React.Fragment>
			);
		}
	}	
}

export default FieldInteger;
