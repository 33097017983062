import React, { createRef } from 'react';
import SelectionList from '../../selectionList/SelectionList';
import FilterValueStringField from './FilterValueStringField';
import {findConditionIndex} from './util';
import { getCurrentResource } from '../../../util/tools';

import {
	CONDITION_NONE,
	CONDITION_IS_NOT_NULL,
	CONDITION_IS_NULL,
	CONDITION_STARTS_WITH,
	CONDITION_NOT_STARTS_WITH,
	CONDITION_EQUALS,
	CONDITION_NOT_EQUALS,
	CONDITION_CONTAINS_TEXT,
	CONDITION_NOT_CONTAINS_TEXT,
	CONDITION_CONTAINS_ALL_WORDS,
	CONDITION_CONTAINS_ANY_WORD
} from './util';

class FilterString extends React.Component {

	/*
		props:
			filter
			filterIndex
			holder
			rateView
			handleFilterChanged
	*/
	constructor(props) {
		super(props);
		props.holder.registerFilterComponent(this, props.filterIndex); 
		this.refConditionList = React.createRef();
		this.resCondition = getCurrentResource().components.rateview.conditions.conditions; 
		this.conditions = [
			{id: CONDITION_NONE, title: this.resCondition.none.title},
		];
		var filter = props.filter;
		for(let conditionId of filter.filterModel.listConditions) {
			this.conditions.push(
				{id: conditionId, title: this.resCondition[conditionId].title}
			);
		}

		var rateView = props.rateView;

		var conditionIndex = findConditionIndex(this.conditions, filter.conditionId, 0);
		this.state = {
			filter: filter,
			conditionIndex: conditionIndex
		}
	}
	updateFilter = (filter) => {
		var conditionIndex = findConditionIndex(this.conditions, filter.conditionId, 0);
		this.refConditionList.current.setSelectedIndex( conditionIndex );
		this.setState({filter: filter, conditionIndex: conditionIndex});
	}
	
	handleConditionSelection = (index) => {
		if( index == -1 )
			index = 0;
		var cond = this.conditions[index];
		this.props.filter.conditionId = cond.id;
		this.setState({conditionIndex: index}, this.handleFilterChanged);
	}	
	handleFilterChanged = (filter) => {
		if( ! filter )
			filter = this.props.filter;

		if( filter.conditionId == CONDITION_NONE ) {
			filter.active = false;
			filter.bodyText = '';
		} else if( filter.conditionId == CONDITION_IS_NOT_NULL || filter.conditionId == CONDITION_IS_NULL ) {
			filter.active = true;
			filter.bodyText = filter.title + ' ' + this.resCondition[filter.conditionId].title;
		} else {
			if( filter.listValue.length == 0 || filter.listValue[0].trim() == '' ) {
				filter.active = false;
				filter.bodyText = '';
			} else {
				filter.active = true;
				filter.bodyText = filter.title + ' ' + this.resCondition[filter.conditionId].title + ' ';
				filter.bodyText += "'" + filter.listValue[0] + "'";
			}
		}

		this.props.handleFilterChanged( filter );
	}
	
	render() {
		return (
			<div style={{display: 'flex'}}>
				<div style={{width: '250px', border: '1px solid black'}}>
					<SelectionList 
						ref={this.refConditionList}
						items={this.conditions} 
						handleItemSelection={this.handleConditionSelection}
						selectedIndex={this.state.conditionIndex} 
					/>
				</div>
				<div style={{flexGrow: '100', marginLeft: '10px'}}>
					<FilterValue holder={this} />
				</div>
			</div>
		);
	}
}

function FilterValue(props) {
	var holder = props.holder;
	var cond = holder.conditions[ holder.state.conditionIndex ];
	var fvStringConditions = [ 
		CONDITION_STARTS_WITH,	CONDITION_NOT_STARTS_WITH, 
		CONDITION_EQUALS, CONDITION_NOT_EQUALS,
		CONDITION_CONTAINS_TEXT, CONDITION_NOT_CONTAINS_TEXT,
		CONDITION_CONTAINS_ALL_WORDS,
		CONDITION_CONTAINS_ANY_WORD
	];
	if( fvStringConditions.includes( cond.id ) ) {
		return <FilterValueStringField filter={holder.props.filter} handleFilterChanged={holder.handleFilterChanged} />
	} else {
		return null;
	}
}

export default FilterString;
