import React, { Component } from 'react';
import NavBar from '../../components/navigation/NavBar';
import RateView from '../../components/rateview/RateView';
import ClassifierImport from '../../components/exim/ClassifierImport';
import { getCurrentResource } from '../../util/tools';
import { getUserInfo } from '../../util/localStorage';

import { FORM_CLASSIFIER_TABLE } from '../../util/consts/forms';
import { ACTION_CLASSIFIER_IMPORT } from '../../util/consts/actions';

class ClassifierListPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isImportModalOpen: false
		}

		this.resource = getCurrentResource().pages.tableform.ClassifierList;
		var param = {};
		var encodedParam = props.match.params.param;
		if( encodedParam ) {
			var strParam = decodeURIComponent(encodedParam);
			param = JSON.parse( strParam );
		}

		this.rvContext = {
			form: FORM_CLASSIFIER_TABLE,
			data: []
		};
		this.rvOptions = {
			form: FORM_CLASSIFIER_TABLE,
			formTitle: this.resource.formTitle,
			leftSideTools: this.tools(),
			transition: param.transition
		};
		if( param.transition )
			this.rvOptions.autoRefresh = true;
	}
	
	tools = () => {
		return (
			<div style={{textAlign: "left"}}>
				<ClassifierImport holder={this} />
			</div>			
		);
	}
	
	render() {
		return (
			<React.Fragment>
				<NavBar app={this.props.app} />
				<RateView rvOptions={this.rvOptions} rvContext={this.rvContext} app={this.props.app} />
			</React.Fragment>
		)
	}
	
}

export default ClassifierListPage;
