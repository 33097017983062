import React from 'react';
import Modal from 'react-responsive-modal';
import { getCurrentResource } from '../../util/tools';

class InfoMessageModal extends React.Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.message.ConfirmMessageModal;		
	}

	render() {
		var message = this.props.message;
		var title = message.title;
		if( ! title )
			title = this.resource.header;
		var mainText = message.mainMessage;
		var divAdditionalMessage = null;
		if( message.additionalMessage ) {
			var divAdditionalMessage = (
				<div style={localStyles.divAdditionalMessage}>
					<textarea style={{width: '100%'}} rows="8" readOnly={true}>
						{message.additionalMessage}
					</textarea>
				</div>		
			);
		}

		var styleModal = {
			modal: {
				background: '#fff',
				padding: '0px',
				borderRadius: '21px',
				maxWidth: '600px',
				width: '100%',
			},
			closeButton: {
				top: '10px',
				right: '10px',
				fill: '#F00',
				outline: 'none',
			},
		};
		if( message.maxWidth )
			styleModal.modal.maxWidth = message.maxWidth;
		return (
			<Modal open={this.props.isModalOpen} onClose={this.props.close} center styles={styleModal} >
				<div style={localStyles.divHeader}>
					{title}
				</div>
				<div style={localStyles.divBody} dangerouslySetInnerHTML={{__html: mainText}} />
				{divAdditionalMessage}
			</Modal>
		);
	}
}

const localStyles ={
	button: {
		marginLeft: '40px',
		marginRight: '40px',
		padding: '5px 15px 5px 15px',
		fontSize: '16pt'
	},
	divHeader: {
		fontSize: '14pt',
		textAlign: 'center',
		padding: '10px',
		borderRadius: '21px 21px 0 0',
		backgroundColor: '#F0F0F0',
	},
	divBody: {
		fontSize: '14pt',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '10px',
		marginBottom: '10px',
		textAlign: 'center',
		padding: '5px'
	},
	divAdditionalMessage: {
		marginLeft: 'auto',
		marginRight: 'auto',
		marginBottom: '30px',
		width: '95%'
	}
};


export default InfoMessageModal;