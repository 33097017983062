import React, { Component } from 'react';
import { getCurrentResource } from '../../util/tools';
import PlainFullDescription from '../../components/fulldescription/PlainFullDescription';
import styles from '../../components/fulldescription/FullDescription.module.css';
import { FORM_BOOK_PROD_FT } from '../../util/consts/forms';

class BookProdPage extends Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().pages.plain.Book;
	}

	plainInfo = {
		app: this.props.app,
		instanceModelId: FORM_BOOK_PROD_FT,
		instanceId: this.props.match.params.id
	};

	render() {
		return (
			<React.Fragment>
				<div className={styles.divPlainTitle}>
					{this.resource.title}
				</div>
				<PlainFullDescription plain={this.plainInfo} />
			</React.Fragment>
		)
	}
}

export default BookProdPage;
