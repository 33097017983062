import React, { createRef } from 'react';
import Modal from 'react-responsive-modal';
import styles from './modal.module.css';

import config from '../../../config/config';

import { getCurrentResource } from '../../../util/tools';
import { ACTION_USH_IMPORT_API_URL, performImportRequest } from '../../../util/restapi';

const FORM_STATE_GET_DATA = 'gd';
const FORM_STATE_WAITING = 'w';
const FORM_STATE_IMPORT_COMPLETE = 'ic';

class UshImportModal extends React.Component {

	constructor(props) {
		super(props);
		props.holder.componentUshImportModal = this;
		this.resource = getCurrentResource().components.rateview.modal.UshImportModal;
		this.importOptions = {};
		this.importOptions = {
			file: null
		}
		this.fileImportRef = createRef();
		this.state = {
			isOpen: false,
			fileName: this.resource.noFileSelected,
			formState: FORM_STATE_GET_DATA
		}
		this.init();		
	}
	
	init = () => {
		this.fileName = "Not selected";
		this.importResultMessage = '';
		this.importOptions.file = null;
		this.setState({formState: FORM_STATE_GET_DATA, fileName: this.resource.noFileSelected});
	}

	styleModal = {
		modal: {
			background: '#fff',
			padding: '0px',
			borderRadius: '21px',
			maxWidth: '500px',
			width: '100%',
		},
		closeButton: {
			top: '10px',
			right: '10px',
			fill: '#F00',
			outline: 'none',
		},
	};

	handleFileSelect = (e) => {
		var files = e.target.files;
		if( files && files.length > 0 ) {
			this.importOptions.file = files[0];
			this.setState({fileName: this.importOptions.file.name})
		}
	}	

	startImport = async () => {
		this.setState({formState: FORM_STATE_WAITING});
		var importOptions = {
			format: 'xml',
			rewrite: true,
			rewriteIfNewer: true
		}
		const form = new FormData();
		form.append('importOptions', JSON.stringify(importOptions));
		form.append('data', this.importOptions.file);

		var response = await performImportRequest(ACTION_USH_IMPORT_API_URL, form);
		console.log( response );
		if( response.success && ! response.message )
			this.importResultMessage = "Success";
		else
			this.importResultMessage = response.message;
		this.setState({formState: FORM_STATE_IMPORT_COMPLETE});
	}

	render() {
		return (
			<Modal 
				open={this.props.isModalOpen} onClose={this.props.close} center styles={this.styleModal}
				closeOnOverlayClick={false} 
			>
				<ImportForm holder={this} />
			</Modal>
		);
	}
}

function ImportForm(props) {
	var res = props.holder.resource;
	var formData = props.holder.importOptions; 
	var inputDisabled = props.holder.state.formState != FORM_STATE_GET_DATA;

	if( props.holder.state.formState == FORM_STATE_GET_DATA ) {
		var divWaiting = (null);
		var divMessage = (null);
	} else if( props.holder.state.formState == FORM_STATE_WAITING ) {
		var divWaiting = <div className={styles.divWaiting}>{res.waiting}</div>
		var divMessage = (null);
	} else if( props.holder.state.formState == FORM_STATE_IMPORT_COMPLETE ) {
		var divWaiting = (null)
		var divMessage = (
			<textarea className={styles.areaMessage} rows="4" readOnly={true}>
				{props.holder.importResultMessage}
			</textarea>		
		);
	}

	return (
		<React.Fragment>
			<div className={styles.divHeader}>
				{res.title}
			</div>
			<div className={styles.divParameters}>
				<p>
					{res.file}: <span>{props.holder.state.fileName}</span> <button  disabled={inputDisabled} onClick={e => props.holder.fileImportRef.current.click()}>...</button>
				</p>
				<input ref={props.holder.fileImportRef} type={'file'}  className={styles.hiddenFile} onChange={e => props.holder.handleFileSelect(e)} />
			</div>
			{divWaiting}
			{divMessage}
			<div className={styles.divButtons}>
				<button onClick={props.holder.startImport} disabled={inputDisabled} title={res.buttonImportTooltip}>{res.buttonImport}</button>
			</div>
		</React.Fragment>
	);
}

export default UshImportModal;
