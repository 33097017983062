import React from 'react';
import Field from './Field';
import styles from './FullDescription.module.css';

class FieldBlock extends React.Component {

	render() {
		var fieldModel = this.props.field.model;
		const subfields = fieldModel.subfields.map((sf, index) => {
			var path = [...this.props.field.path];
			path[path.length-1] = sf.id;
			var sfField = {
				model: sf,
				path: path
			};
			return (
				<Field key={index} plain={this.props.plain} field={sfField} />
			)
		});
		var style = {};
		for(var cssProperty of fieldModel.listCssProperty)
			style[ cssProperty.key ] = cssProperty.value;

		return (
			<div className={styles.divFieldBlock} style={style}>
				{subfields}
			</div>
		);
	}
}

export default FieldBlock;
