import React from 'react';
import { validInteger, validDecimal } from '../../../util/tools';

class FilterValueNumberDiapason extends React.Component {

	/*
		props
			filter
			handleFilterChanged
	*/
	constructor(props) {
		super(props);
		this.filter = props.filter;
		if(this.filter.listValue.length == 0)
			this.filter.listValue.push('');
		if(this.filter.listValue.length == 1)
			this.filter.listValue.push('');
		this.state = {
			valueFrom: this.filter.listValue[0],
			valueTo: this.filter.listValue[1],
		}
	}
	
	handleChangeValue = (e) => {
		const {name, value} = e.target;
		var valid = this.validValue( value );
		if( name == 'valueFrom' ) {
			this.filter.listValue[0] = valid ? value : '';
			this.setState({valueFrom: value}, this.handleFilterChanged);
		}
		if( name == 'valueTo' ) {
			this.filter.listValue[1] = valid ? value : '';
			this.setState({valueTo: value}, this.handleFilterChanged);
		}
	}
	handleFilterChanged = () => {
		this.props.handleFilterChanged( this.filter );
	}
	validValue = (value) => {
		if( this.filter.filterModel.decimal )
			return validDecimal( value )
		else
			return validInteger( value )
	}

	render() {
		var bgFrom = this.validValue( this.state.valueFrom ) ? 'White' : 'LightYellow';
		var bgTo = this.validValue( this.state.valueTo ) ? 'White' : 'LightYellow';
		return (
			<div>
				<div>
					<input type='text' name='valueFrom' style={{width: '100%', backgroundColor: bgFrom}} 
						value={this.state.valueFrom} onChange={this.handleChangeValue} 
					/>
				</div>
				<div style={{marginTop: '30px'}}>
					<input type='text' name='valueTo' style={{width: '100%', backgroundColor: bgTo}} 
						value={this.state.valueTo} onChange={this.handleChangeValue} 
					/>
				</div>
			</div>
		);
	}
}

export default FilterValueNumberDiapason;
