import React, { Component } from 'react';
import Message from '../../modal/message/Message';

class ModalMessage extends Component {

	constructor(props) {
		super(props);
		
		this.state = {
			isModalMessageOpen: false
		}
		
		props.messageHandler.showMessage = this.showMessage; 
	}

	openModalMessage = () => {
		this.setState({ isModalMessageOpen: true });	
	}
	closeModalMessage = () => {
		this.setState({ isModalMessageOpen: false }, this.props.messageHandler.doAfterClose);	
		
	}
	showMessage = msg => {
		this.message = msg;
		this.openModalMessage();
	}
	getMessageText = () => {
		return this.message;
	}
	message = '';

	render() {
		return (
			<React.Fragment>
				<Message isModalOpen={this.state.isModalMessageOpen} close={this.closeModalMessage} message={this.getMessageText} />			
			</React.Fragment>
		)
	}
	
}

export default ModalMessage;
