import React, { Component } from 'react';
import { getCurrentResource } from '../../util/tools';
import PlainFullDescription from '../../components/fulldescription/PlainFullDescription';
import styles from '../../components/fulldescription/FullDescription.module.css';
import { FORM_ESTIMATION_FT } from '../../util/consts/forms';
import { CLASS_ESTIMATION } from '../../util/consts/classes';

class EstimationPage extends Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().pages.plain.Estimation;
		this.plainInfo = {
			app: props.app,
			page: this,
			class: CLASS_ESTIMATION,
			instanceModelId: FORM_ESTIMATION_FT,
			instanceId: this.props.match.params.id,
			param: {}
		};
	}

	render() {
		return (
			<React.Fragment>
				<div className={styles.divPlainTitle}>
					{this.resource.title}
				</div>
				<PlainFullDescription plain={this.plainInfo} />
			</React.Fragment>
		)
	}
}

export default EstimationPage;
