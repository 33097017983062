import React, { Component } from 'react';
import NavBar from '../../components/navigation/NavBar';
import RateView from '../../components/rateview/RateView';
import ButtonIconText from '../../components/button/ButtonIconText';
import ModalMessage from '../../components/message/ModalMessage';
import config from '../../config/config';
import { getCurrentResource } from '../../util/tools';
import { getUserInfo, canPerformAction } from '../../util/localStorage';
import { FORM_OFFER_TABLE, FORM_ORDER_FT } from '../../util/consts/forms';
import {
	ACTION_ORDER_CREATE_BY_OFFER, 
	ACTION_ORDERS_CREATE_BY_OFFERS, 
	ACTION_PROCUREMENT_CREATE_BY_OFFER 
} from '../../util/consts/actions';
import { performApiRequest, 
	ACTION_ORDER_CREATEBYOFFER_API_URL, 
	ACTION_ORDERS_CREATEBYOFFERS_API_URL, 
	ACTION_PROCUREMENT_CREATEBYOFFER_API_URL, 
} from '../../util/restapi';

import iconCreateProcurement from '../../assets/icons/png24x24/Refresh.png';
import iconCreateOrder from '../../assets/icons/png24x24/check_mark.png';

class OfferListPage extends Component {
	constructor(props) {
		super(props);
		this.userInfo = getUserInfo();
		
		this.rvComponent = null;
		this.messageHandler = {};

		this.resource = getCurrentResource().pages.tableform.OfferList;
		var param = {};
		var encodedParam = props.match.params.param;
		if( encodedParam ) {
			var strParam = decodeURIComponent(encodedParam);
			param = JSON.parse( strParam );
		}
		
		this.rvContext = {
			form: FORM_OFFER_TABLE,
			data: []
		}
		this.rvOptions = {
			form: FORM_OFFER_TABLE,
			formTitle: this.resource.formTitle,
			transition: param.transition,
//			leftSideTools: this.leftSideTools(),
			controlPanelTools: this.controlPanelTools()
		}
		if( param.transition )
			this.rvOptions.autoRefresh = true;
	}
	
	createProcurements = async () => {
		if( ! this.rvContext.data )
			return;
		const URL = config.apiGateway.URL + "/action/procurement/create_by_offer";
		const request = {
			listOfferId: []
		};

		for( var row of this.rvContext.data ) {
			if( row.checked ) {
				request.listOfferId.push( row.id );
			}
		}
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(request)			
		};
		
		var response = await fetch(URL, requestOptions);
		response = await response.json();
	}	

	createOrder = async () => {
		var ids = this.rvComponent.getSelectedIds();
		if( ids.length == 0 )
			return;
		/*	
		if( ids.length == 1 ) {
			var request = {
				offerId: ids[0] 
			};
			var response = await performApiRequest(ACTION_ORDER_CREATEBYOFFER_API_URL, request);
			if( response.success ) {
				var orderId = response.data;
				var editOrderURL = `${config.PATH}/` + FORM_ORDER_FT + '/' + orderId ;
				window.open( editOrderURL );
			} else {
				this.messageHandler.showMessage( response.message );
			}
		} else {
		*/
			var request = {
				listOfferId: ids 
			};
			var response = await performApiRequest(ACTION_ORDERS_CREATEBYOFFERS_API_URL, request);
			this.messageHandler.showMessage( response.message );
		//}
	}
	
	leftSideTools = () => {
		var buttonCreateProcurementHtml = null;
		var buttonCreateOrderHtml = null;
		if( canPerformAction(ACTION_PROCUREMENT_CREATE_BY_OFFER, this.userInfo) ) {
			buttonCreateProcurementHtml = (
				<button type='button' onClick={this.createProcurements} style={{marginLeft: '5px'}}>
					{this.resource.createProcurements}
				</button>
			);
		}
		if( canPerformAction(ACTION_ORDER_CREATE_BY_OFFER, this.userInfo) ) {
			buttonCreateOrderHtml = (
				<button type='button' onClick={this.createOrder} style={{marginLeft: '5px'}}>
					{this.resource.createOrder}
				</button>
			);
		}
		return (
			<div style={{textAlign: 'left', marginLeft: '20px'}}>
				{buttonCreateProcurementHtml}
				{buttonCreateOrderHtml}
			</div>			
		);
	}
	controlPanelTools = () => {
		var buttonCreateProcurementHtml = null;
		var buttonCreateOrderHtml = null;

		var showTools = false;
		/*
		if( canPerformAction(ACTION_PROCUREMENT_CREATE_BY_OFFER, this.userInfo) ) {
			showTools = true;
			buttonCreateProcurementHtml = (
				<ButtonIconText 
					onClick={this.createProcurements}  style={{marginLeft: '5px'}}
					text={this.resource.createProcurements} 
					tooltip={this.resource.createProcurements} icon={iconCreateProcurement}
				/>
			);
		}
		*/
		if( canPerformAction(ACTION_ORDER_CREATE_BY_OFFER, this.userInfo) ) {
			showTools = true;
			buttonCreateOrderHtml = (
				<ButtonIconText 
					onClick={this.createOrder} style={{marginLeft: '5px'}}
					text={this.resource.createOrder} 
					tooltip={this.resource.createOrder} icon={iconCreateOrder}
				/>
			);
		}
		if( showTools ) {
			return (
				<div style={{textAlign: 'left', marginLeft: '20px'}}>
					{buttonCreateProcurementHtml}
					{buttonCreateOrderHtml}
				</div>			
			);
		} else
			return null;
	}
	
	render() {
		return (
			<React.Fragment>
				<NavBar app={this.props.app} />
				<RateView holder={this} rvContext={this.rvContext} rvOptions={this.rvOptions} app={this.props.app} />
				<ModalMessage messageHandler={this.messageHandler} />
			</React.Fragment>
		)
	}
	
}

export default OfferListPage;
