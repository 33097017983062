import React, { Component } from 'react';
import { getCurrentResource } from '../../util/tools';
import PlainFullDescription from '../../components/fulldescription/PlainFullDescription';
import styles from '../../components/fulldescription/FullDescription.module.css';
import { FORM_AUTHORITY_PUBLISHER_FT } from '../../util/consts/forms';
import { CLASS_AUTHORITY_PUBLISHER } from '../../util/consts/classes';

class AuthorityPublisherPage extends Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().pages.plain.AuthorityPublisher;
		var param = {};
		this.plainInfo = {
			app: props.app,
			page: this,
			class: CLASS_AUTHORITY_PUBLISHER,
			instanceModelId: FORM_AUTHORITY_PUBLISHER_FT,
			instanceId: this.props.match.params.id,
			param: param,
		};
	}


	render() {
		return (
			<React.Fragment>
				<div className={styles.divPlainTitle}>
					{this.resource.title}
				</div>
				<PlainFullDescription plain={this.plainInfo} />
			</React.Fragment>
		)
	}
}

export default AuthorityPublisherPage;
