export const ACTION_AUTHORITY_AUTHOR_EXPORT = 'aa_export';
export const ACTION_AUTHORITY_PUBLISHER_EXPORT = 'ap_export';
export const ACTION_BOOK_EXPORT = 'book_export';
export const ACTION_BOOK_IMPORT = 'book_import';
export const ACTION_EBOOK_REPORT_EXPORT = 'ebook_report_export';
export const ACTION_EBOOK_REPORT_IMPORT = 'ebook_report_import';
export const ACTION_CLASSIFIER_IMPORT = 'classifier_import';
export const ACTION_INSTANCE_CHANGE_OWNER = 'instance_changeowner';
export const ACTION_INSTANCE_DELETE = 'instance_delete';
export const ACTION_INSTANCE_MERGE = 'merge_instances';
export const ACTION_INSTANCE_UPDATE_FIELDS = 'update_fields';
export const ACTION_ONIX_EXPORT = 'onix_export';
export const ACTION_ONIX_IMPORT = 'onix_import';
export const ACTION_ONIX_IMPORT_LOCAL = 'onix_import_local';
export const ACTION_ONIX_IMPORT_ELIBRI = 'onix_import_elibri';
export const ACTION_ORDER_CREATE_BY_OFFER = 'action_create_order_by_offer';
export const ACTION_ORDERS_CREATE_BY_OFFERS = 'action_create_orders_by_offers';
export const ACTION_PROCUREMENT_CREATE_BY_OFFER = 'action_create_procurement_by_offer';
export const ACTION_PROVIDER_IMPORT = 'provider_import';
export const ACTION_USH_EXPORT = 'ush_xml_export';
export const ACTION_USH_IMPORT = 'ush_import';
export const ACTION_ELIBRI_API = 'elibri_api';
