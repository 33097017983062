import React, { Component } from 'react';
import config from '../../config/config';

class ConverterPage extends Component {
	
	constructor(props) {
		super(props);
	}

	state = {
		message: 'Ready to convert'
	};	
	
	startConverting = () => {
		const query = {info: 'info'};
		var url = config.apiGateway.URL;
		if( config.apiGateway.BASE_PATH )
			url += '/' + config.apiGateway.BASE_PATH;
		url += '/converter/bom';
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(query)			
		};
		fetch(url, requestOptions)
			.then(response => response.json())
			.then(data => { 
				this.setState( { message: data.message } );
		});
	}
	clearConverting = () => {
		this.setState( { message: 'Ready to convert' } );
	}
	
	render() {
		return (
			<React.Fragment>
				<div>
					Converter
				</div>
				<div>
					<p>Message: <b>{this.state.message}</b></p>
				</div>
				<button onClick={this.startConverting}>Convert</button>
				&nbsp;
				<button onClick={this.clearConverting}>Clear</button>
			</React.Fragment>
		)
	}
	
}

export default ConverterPage;
