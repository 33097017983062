import React from 'react';
import ButtonIconText from '../../components/button/ButtonIconText';
import SelectInstance from './SelectInstance'; 
import { getButtonMode } from '../../util/localStorage';

class ButtonSelectInstance extends React.Component {

	constructor(props) {
		super(props);
		this.useIcons = ( getButtonMode() == 'icon' );
		this.state = {
			isModalOpen: false
		}
	}
	
	selectInstance = () => {
		console.log( 'select instance' );
		this.setState({ isModalOpen: true });
	}
	onInstanceSelected = (ids) => {
		this.closeModal();
		this.props.onInstanceSelected(ids);
	}
	closeModal = () => {
		this.setState({ isModalOpen: false });
	}
	
	render() {
		return (
			<React.Fragment>
				<ButtonIconText 
					onClick={this.selectInstance} buttonType={'button'} useIcon={this.useIcons} 
					text={this.props.title} tooltip={this.props.tooltip} icon={this.props.icon}
				/>
				<SelectInstance 
					isModalOpen={this.state.isModalOpen} 
					closeModal={this.closeModal}
					title={this.props.selectInstanceRvContext.formTitle}
					onInstanceSelected={this.onInstanceSelected}
					rvContext = {this.props.selectInstanceRvContext}
				/>
			</React.Fragment>
		);
	}
}

export default ButtonSelectInstance;
