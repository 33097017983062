import React from 'react';
import { getCurrentResource } from '../../../util/tools';

class FilterBoolean extends React.Component {
	/*
		props:
			filter
			filterIndex
			holder
			rateView
			handleFilterChanged
	*/
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.rateview.conditions.FilterBoolean;
		props.holder.registerFilterComponent(this, props.filterIndex);
		var filter = props.filter; 
		if( filter.listValue.length = 0 )
			filter.listValue.push('0'); 
		this.state = {
			checked: filter.listValue[0] == '1'
		}
	}
	handleCheckBoxChange = (e) => {
		var checked = e.target.checked ? 1 : 0;
		var filter = this.props.filter;

		if( checked ) {
			filter.active = true;
			filter.bodyText = filter.title;
		} else {
			filter.active = false;
			filter.bodyText = '';
		}
		
		this.props.handleFilterChanged( filter );
		this.setState({checked: checked});
	}
	updateFilter = (filter) => {
		this.setState({checked: filter.active});
	}

	render() {
		return (
			<div>
				<div style={{marginBottom: '10px'}}>
					{this.props.filter.filterModel.tooltip}
				</div>
				<div>
					<input type='checkbox' onChange={this.handleCheckBoxChange} checked={this.state.checked} />
					<span style={{marginLeft: '10px'}}>{this.resource.labelYes}</span>
				</div>
			</div>
		);
	}
}

export default FilterBoolean;

