import { performApiRequest } from '../../util/restapi';
import { QUERY_GETPAGE_API_URL } from '../../util/restapi';

export const selectTopicReference = async (fieldReference) => {
	fieldReference.selectInstanceRvContext.filtersInitData = null;
	var serviceValue = fieldReference.state.serviceValue; 
	if( serviceValue ) {
		var query = {
			queryId: 'topic_table',
			listColumn: [ {id: 'card'}, {id: 'topic_add'}, {id: 'topic_mod'}],
			listPredicate: [ { predicateId: 'code', conditionId: 'startsWith', value: serviceValue } ],
			listSorting: [	{ sortingId: 'cl_name', ascending: true } ],
			page: 1, pageSize: 15 
		};
		var filtersInitData = [
			{predicateId: 'code', value: serviceValue}
		];
		var response = await performApiRequest( QUERY_GETPAGE_API_URL, query );
		if( response.data.data.length == 0 ) {
			query = {
				queryId: 'topic_table',
				listColumn: [ {id: 'card'}, {id: 'topic_add'}, {id: 'topic_mod'}],
				listPredicate: [ { predicateId: 'topic_name', value: serviceValue } ],
				listSorting: [	{ sortingId: 'cl_name', ascending: true } ],
				page: 1, pageSize: 15 
			};
			var filtersInitData = [
				{predicateId: 'topic_name', value: serviceValue}
			];
			response = await performApiRequest( QUERY_GETPAGE_API_URL, query );
		}
		if( response.data.data.length == 1 ) {
			var rows = [ response.data.data[0].id ];
			fieldReference.onInstanceSelected( rows );
		} else {
			fieldReference.selectInstanceRvContext.filtersInitData = filtersInitData;
			fieldReference.setState({ isSelectInstanceModalOpen: true });
			var rateView = fieldReference.selectInstanceRvContext.rateView;
			rateView.updateData( response.data ); 
		}
	} else {
		fieldReference.setState({ isSelectInstanceModalOpen: true });
	}
}
