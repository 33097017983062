import React, { Component } from 'react';
import styles from './NavBar.module.css';
import { getLanguage, setLanguage } from '../../util/localStorage';

class Language extends Component {
 
	constructor(props) {
		super(props);
		this.state = {
			lang: getLanguage()
		}
	}
	listOptions = [
		{value: 'ukr', title: 'Українська'},
		{value: 'eng', title: 'English'}
	];

	selectLanguage = (e) => {
		var lang = e.target.value;
		setLanguage(lang);
		//this.setState({lang: lang});
		this.props.app.setState({lang: lang});
	}

	render() {
		var lang = getLanguage();
		var options = this.listOptions.map((o, index) => {
			return (
				<option value={o.value} selected={o.value == lang}>{o.title}</option>
			);
		});
		return (
			<React.Fragment>
				<select onChange={this.selectLanguage}>
					{options}
				</select>
			</React.Fragment>
		)
	}
}

export default Language;
