import React from 'react';
import styles from './TreeView.module.css';
import ButtonIconText from '../button/ButtonIconText';
import { getCurrentResource } from '../../util/tools';
import { getButtonMode } from '../../util/localStorage';
import { performApiRequest, PLAIN_API_URL } from '../../util/restapi';

import collapseIcon from '../../assets/icons/png24x24/collapse_silver.png';
import expandIcon from '../../assets/icons/png24x24/expand.png';

const FORM_STATE_POPULATING = 'p';
const FORM_STATE_NORMAL = 'n';
const FORM_STATE_ERROR = 'e';

class TreeNode extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			formState: FORM_STATE_NORMAL,
			isCollapsed: true,
			node: this.props.node,
			cbSelected: false
		};
		
		this.resource = getCurrentResource().components.treeview.TreeNode;
		this.children = null;
		this.responseMessage = '';
		this.selected = false;		

		this.parentComponent = props.parent;
		this.parentComponent.childrenComponents.push( this );
		this.childrenComponents = [];
	}

	expand = async () => {
		this.setState( { isCollapsed: false } );
		if( this.children == null && this.state.formState == FORM_STATE_NORMAL ) {
			let ok = await this.populateChildren();
		}
	}
	collapse = () => {
		this.setState( { isCollapsed: true } );
	}
	populateChildren = async () => {
		this.setState( { formState: FORM_STATE_POPULATING } );
		var request = {
			treeId: this.props.treeId,
			refParent: this.props.node,
			param: {
				rowQty: 0
			}
		}
		var response = await performApiRequest("tree/populatenode", request);
		if( response.success ) {
			this.children = response.data.nodes; 
			this.setState({formState: FORM_STATE_NORMAL})
			return true;
		} else {
			this.responseMessage = response.message; 
			this.setState({formState: FORM_STATE_ERROR})
			return false;
		}
	}
	getSelectedNodes = (nodes) => {
		if( this.selected )
			nodes.push( this.state.node );
		for(let c of this.childrenComponents) {
			c.getSelectedNodes( nodes );
		}
	}
	getSelectedComponents = (components, includeSubnodes) => {
		if( this.selected )
			components.push( this );
		if( this.selected && includeSubnodes || ! this.selected ) {
			for(let c of this.childrenComponents) {
				c.getSelectedComponents( components, includeSubnodes );
			}
		}
	}
	
	startUpdate = () => {
		this.setState({formState: FORM_STATE_POPULATING});
	}
	completeUpdate = (response) => {
		if( response.success ) {
			this.updateNode( response.data );
			this.setState({formState: FORM_STATE_NORMAL});
		} else {
			this.responseMessage = response.message;
			this.setState({formState: FORM_STATE_ERROR});
		}
	}
	/*
		bundle
			items[]
				parent
				nodes
	*/
	updateNode = (bundle) => {
		console.log("Bundle", bundle);
		console.log("State before", this.state);
		this.children = null;
		this.childrenComponents = [];
		for(let item of bundle.items) {
			if( item.parent.id == this.state.node.id ) {
				console.log('Update node', item);
				this.children = item.nodes;
				this.setState({node: item.parent}); 
				break;
			}
		}
		console.log("State after", this.state);
	}
	handleCheckBoxChange = (event) =>	{
		this.selected = event.target.checked;
		this.props.rateView.handleRowSelectionChange();
		this.setState({ cbSelected: this.selected });
	}

	render() {
		if( this.state.isCollapsed ) {
			return (
				<div className={styles.divTreeNode}>
					<HeaderRow holder={this} />
				</div>
			);
		}
		if( this.state.formState == FORM_STATE_POPULATING ) {
			var body = (
				<div className={styles.divTreeView}>
					{this.resource.waiting}
				</div>
			);
		} else if( this.state.formState == FORM_STATE_ERROR ) {
			var body = (
				<div className={styles.divTreeView}>
					{this.responseMessage}
				</div>
			);
		} else if( this.children ) {
			var body = this.children.map((child, index) => {
				return (
					<TreeNode key={index} rateView={this.props.rateView} parent={this} node={child} treeId={this.props.treeId} /> 
				)
			});
		} else {
			var body = (null)
		}
		return (
			<div className={styles.divTreeNode}>
				<HeaderRow holder={this} />
				<div className={styles.divSubnodes}>
					{body}
				</div>
			</div>
		);
	}
}

function HeaderRow(props) {
	var buttonStyle = {width: '22px'}
	if( props.holder.state.isCollapsed ) {
		var buttonExpandOrCollapse = (
			<button type='button' onClick={props.holder.expand} style={buttonStyle} >+</button>
		);
	} else {
		var buttonExpandOrCollapse = (
			<button type='button' onClick={props.holder.collapse} style={buttonStyle} >-</button>
		);
	}
	return (
		<div style={{display: 'flex'}}>
			<div style={{flexGrow: 0}}>
				{buttonExpandOrCollapse}
			</div>
			<div style={{flexGrow: 0, marginLeft: '10px'}}>
				<input type='checkbox' name='cb' onChange={props.holder.handleCheckBoxChange} checked={props.holder.state.cbSelected} />
			</div>
			<div style={{flexGrow: 100, marginLeft: '5px'}}>
				{props.holder.state.node.card}
			</div>
		</div>
	);
}

export default TreeNode;
