import React from 'react';
import stylesPlain from './plain.module.css';

import { getDefinedValue } from '../../util/tools';

import iconCollapse from '../../assets/icons/png24x24/collapse_silver.png';
import iconExpand from '../../assets/icons/png24x24/expand.png';
import iconAdd from '../../assets/icons/png24x24/Add.png';
import iconMoveUp from '../../assets/icons/png24x24/Up.png';
import iconMoveDown from '../../assets/icons/png24x24/Down.png';
import iconDelete from '../../assets/icons/png24x24/Delete.png';

class FieldList_Rows extends React.Component {
	
	field = null;

	/*
		plain
		parentField
		path
		itemComponent
		readOnly
		divStyle
		title
		tooltip
		collapsed
		cardBuilder
	*/
	constructor(props) {
		super(props);
		if( props.parentField )
			this.field = props.plain.instance.getField(props.path, props.parentField );
		else
			this.field = props.plain.instance.getField(props.path);
		this.state = {
			value: this.field.value,
			collapsed: getDefinedValue(props.collapsed, true)
		}
	}
	
	expand = () => {
		this.setState({collapsed: false});
	}
	collapse = () => {
		this.setState({collapsed: true});
	}
	
	addItem = () => {
		
	}
	moveUpItem = (index) => {
		
	}
	moveDownItem = (index) => {
		
	}
	deleteItem = (index) => {
		
	}
	
	render() {
		var disableAdd = getDefinedValue(this.props.readOnly, false); 
		if( this.state.collapsed ) {
			if( this.field.value.length == 0 ) {
				return (
					<div style={this.props.divStyle}>
						<button type='button' className={stylesPlain.buttonIcon} disabled={disableAdd} 
							onClick={this.addItem} title={'Add'}
						>
							<img src={iconAdd} />
						</button>
					</div>
				);
			} else {
				var card = '';
				if( this.props.cardBuilder ) {
					card = this.props.cardBuilder( this.field );
				}
				return (
					<div style={this.props.divStyle}>
						<button type='button' className={stylesPlain.buttonIcon} 
							onClick={this.expand} title={'Expand'}
						>
							<img src={iconExpand} />
						</button>
						<span style={{marginLeft: '10px'}}>{this.props.title}</span>
						<span style={{marginLeft: '10px'}}>{card}</span>
					</div>
				);
			}
		}
		var itemsHtml = this.field.value.map((itemField, index) => {
			var disableMoveUp = false; 
			var disableMoveDown = false; 
			var disableDelete = false;
			var divButtonsHtml = (
				<div style={{minWidth: '120px'}}>
					<button type='button' className={stylesPlain.buttonIcon} disabled={disableMoveUp} 
						onClick={ () => this.moveUpItem(index) } title={'Move up item'}
					>
						<img src={iconMoveUp} />
					</button>
					<button type='button' className={stylesPlain.buttonIcon} disabled={disableMoveDown} 
						onClick={ () => this.moveDownItem(index) } title={'Move down item'} style={{marginLeft: '5px'}}
					>
						<img src={iconMoveDown} />
					</button>
					<button type='button' className={stylesPlain.buttonIcon} disabled={disableDelete} 
						onClick={ () => this.deleteItem(index) } title={'Delete item'} style={{marginLeft: '15px'}}
					>
						<img src={iconDelete} />
					</button>
				</div>
			)
			var listItemInfo = {
				index: index,
				isLastItem: (index == this.field.value.length-1),
				moveUpItem: this.moveUpItem,
				moveDownItem: this.moveDownItem,
				deleteItem: this.deleteItem,
				divButtonsHtml: divButtonsHtml
			};
			return (
				<this.props.itemComponent key={index} fieldGroup={itemField} listItemInfo={listItemInfo} plain={this.props.plain} />
			)
		});
		return (
			<div style={this.props.divStyle}>
				<div>
					<button type='button' className={stylesPlain.buttonIcon} 
						onClick={this.collapse} title={'Collapse'}
					>
						<img src={iconCollapse} />
					</button>
					<button type='button' className={stylesPlain.buttonIcon} disabled={disableAdd} 
						onClick={this.addItem} title={'Add'} style={{marginLeft: '10px'}}
					>
						<img src={iconAdd} />
					</button>
					<span style={{marginLeft: '20px'}}>{this.props.title}</span>
					<span style={{marginLeft: '10px'}}>{card}</span>
				</div>
				{itemsHtml}
			</div>
		);
	}
}

export default FieldList_Rows;
