import React from 'react';
import Modal from 'react-responsive-modal';
import styles from './modal.module.css';
import { getCurrentResource } from '../../../util/tools';

import { ENTITY_CHANGE_OWNER_API_URL, performApiRequest } from '../../../util/restapi';

const FORM_STATE_READY = 'ready'; 
const FORM_STATE_WAITING = 'wait'; 

class ChangeOwnerModal extends React.Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().modal.rateview.ChangeOwnerModal;
		this.state = {
			formState: FORM_STATE_READY,
			user: 0,
			group: 0,
			onlySelectedGroup: true
		};
	}
	init = () => {
		this.setState({
			formState: FORM_STATE_READY,
			user: 0,
			group: 0,
			onlySelectedGroup: true
		});
	}

	handleSelectChange = (e) => {
		const {name,value} = e.target;
		if( name == 'user' )
			this.setState({user: value});
		if( name == 'group' )
			this.setState({group: value});
	}
	handleCheckBoxChange = (e) => {
		this.setState({onlySelectedGroup: e.target.checked});
	}
	
	doChangeOwner = async () => {
		this.setState({formState: FORM_STATE_WAITING});
		var request = {
			listObjectId: this.props.modalContext.ids,
			userId: this.state.user,
			groupId: this.state.group
		};
		var response = await performApiRequest( ENTITY_CHANGE_OWNER_API_URL, request );
		this.props.modalContext.response = response;
		this.props.modalContext.saved = true;
		this.setState({formState: FORM_STATE_READY, user: 0, group: 0});
		this.props.holder.closeModal();
	}

	render() {
		var listGroupHtml = this.props.holder.listGroup.map( (group, index) => {
			return (
				<option key={index} value={group.id}>{group.card}</option>
			)
		} );
		if( this.state.onlySelectedGroup ) {
			if( this.state.group == 0 ) {
				var listUser = this.props.holder.listUser;
			} else {
				var listUser = [{id: 0, login: ''}];
				for(let gr of this.props.holder.listGroup) {
					if( gr.id == this.state.group ) {
						for(let u of gr.listUser)
							listUser.push( u );
						break;
					}
				}
			}
		} else {
			var listUser = this.props.holder.listUser;
		}
		var listUserHtml = listUser.map( (user, index) => {
			return (
				<option key={index} value={user.id}>{user.login}</option>
			)
		} );
		var waitingHtml = this.state.formState == FORM_STATE_WAITING ?
			(<div style={{marginBottom: '20px', textAlign: 'center'}}>{this.resource.msgPleaseWait}</div>) : (null) ;
		var buttonDisabled = 
			this.state.formState == FORM_STATE_WAITING ||
			( this.state.user == 0 && this.state.group == 0 ) 
		return (
			<Modal 
				open={this.props.isModalOpen} onClose={this.props.close} center styles={styleModal}
				closeOnOverlayClick={false} 
			>
				<React.Fragment>
					<div className={styles.divHeader}>
						{this.resource.title}
					</div>
					<div style={{padding: '20px'}}>
						<div style={{display: 'flex', marginBottom: '20px'}}>
							<div style={{width: '150px'}}>
								{this.resource.labelGroup}
							</div>
							<div>
								<select name='group' style={{minWidth: '200px'}} defaultValue={this.state.group} onChange={this.handleSelectChange}>
									{listGroupHtml}
								</select>
							</div>
						</div>
						<div style={{display: 'flex', marginBottom: '10px'}}>
							<div style={{width: '150px'}}>
								{this.resource.labelUser}
							</div>
							<div>
								<select name='user' style={{minWidth: '200px'}} defaultValue={this.state.user} onChange={this.handleSelectChange}>
									{listUserHtml}
								</select>
							</div>
						</div>
						<div style={{display: 'flex', marginBottom: '20px', marginLeft: '10px'}}>
							<div>
								<input type='checkbox' checked={this.state.onlySelectedGroup} onClick={this.handleCheckBoxChange} />
							</div>
							<div style={{marginLeft: '5px'}}>
								{this.resource.cbOnlySelectedGroup}
							</div>
						</div>
						{waitingHtml}
						<div style={{textAlign: 'right'}}>
							<button type='button' disabled={buttonDisabled} onClick={this.doChangeOwner}>{this.resource.buttonChange}</button>
						</div>
					</div>
				</React.Fragment>
			</Modal>
		);
	}
}

const styleModal = {
	modal: {
		background: '#ffffff',
		padding: '0px',
		borderRadius: '21px',
		maxWidth: '700px',
		width: '100%',
	},
	closeButton: {
		top: '10px',
		right: '10px',
		fill: '#FF0000',
		outline: 'none',
	},
};

export default ChangeOwnerModal;
