import React, { Component } from 'react';
import ButtonIconText from '../button/ButtonIconText';
import InfoMessage from '../message/InfoMessage';
import LoginForm from '../login/LoginForm';
import { getCurrentResource } from '../../util/tools';
import { performApiRequest } from '../../util/restapi';
import {getUserInfo, getSuperUserInfo, setUserInfo, setSuperUserInfo, clearUserInfo, canViewForm} from '../../util/localStorage';
import { HELLO_PATH, PROFILE_PATH } from '../../util/routes';
import {
	LOGIN_API_URL,
	QUERY_LOADGROUPSANDUSERS_API_URL,
	GETUSERINFO_API_URL
} from '../../util/restapi';

import exitIcon from '../../assets/icons/png24x24/Exit.png';
import profileIcon from '../../assets/icons/png24x24/Gear.png';

class Header extends Component {

	constructor(props) {
		super(props);
		this.refMessage = React.createRef();
		this.resource = getCurrentResource().components.header.Header;
		this.userInfo = getUserInfo(); 
		this.superUserInfo = getSuperUserInfo(); 
		this.state = {
			showLoginForm: false,
			isMessageModalOpen: false,
			groupsAndUsers: { listUser: [] },
			userInfo: this.userInfo
		}
	}
	componentDidMount = async () => {
		if( this.isSuperUser() ) {
			this.loadGroupsAndUsers();
		}
	}

	isSuperUser = () => {
		return (this.superUserInfo != null || this.userInfo.superuser);
	}	
	showLoginForm = () => {
		this.setState({ showLoginForm: true });
	}
	hideLoginForm = () => {
		this.setState({ showLoginForm: false });
	}

	loadGroupsAndUsers = async () => {
		var response = await performApiRequest( QUERY_LOADGROUPSANDUSERS_API_URL, {} );
		if( response.success ) {
			var data = response.data;
		} else {
			var data = { listUser: [] };
		}
		this.setState({groupsAndUsers: data})
	}
	tryLogin = async (login, password) => {
		var request = {
			login: login,
			password: password
		};
		var userInfo = await performApiRequest( LOGIN_API_URL, request );
		if( ! userInfo.valid) {
			this.refMessage.current.showMessage( this.resource.msgInvalidLoginPassword );
		} else {
			this.setUser(userInfo);
			window.location.replace( HELLO_PATH );
		}
	}
	
	handleAsUserChange = async (e) => {
		var value = e.target.value;
		if( value == 'admin' ) {
			if( this.superUserInfo ) {
				this.setUser(this.superUserInfo);
				this.setSuperUser(null);
				this.setState({userInfo: this.userInfo})
			}
			window.location.replace( HELLO_PATH );
		} else {
			if( this.superUserInfo != null ) {
				this.setUser(this.superUserInfo);
				this.setSuperUser(null);
			}
			var request = { login: value };
			var response = await performApiRequest( GETUSERINFO_API_URL, request );
			if( response.success ) {
				this.setSuperUser(this.userInfo);
				this.setUser(response.data);
				window.location.replace( HELLO_PATH );
			} else {
				this.showMessage(response.message);
			}
		}
	}
	setUser = (userInfo) => {
		this.userInfo = userInfo;
		setUserInfo(userInfo);
	}
	setSuperUser = (userInfo) => {
		this.superUserInfo = userInfo;
		setSuperUserInfo(userInfo);
	}
	
	logOut = () => {
		clearUserInfo();
		window.location.replace( HELLO_PATH );
	}

	openProfile = () => {
		window.location.replace( PROFILE_PATH );
	}

	render() {
		var resource = this.resource;
		return (
			<React.Fragment>
				<div style={localStyles.divMain}>
					<div style={{marginLeft: '300px', flexGrow: '100', textAlign: 'center'}}>
						<a href={HELLO_PATH} style={{color: 'black', textDecoration: 'none'}}>
							{resource.title}
						</a>
					</div>
					<div style={{display: 'flex', minWidth: '300px', alignItems: 'center', justifyContent: 'right'}}>
						<SignIn holder={this} /> 
						{	this.userInfo.login ?
							<ButtonIconText 
								onClick={this.openProfile} style={{marginLeft: '7px'}}
								text={resource.buttonProfile} tooltip={resource.buttonProfileTooltip} icon={profileIcon}
							/> : null
						}
					</div>
				</div>
				{ this.state.showLoginForm ? <LoginForm hideLoginForm={this.hideLoginForm} tryLogin={this.tryLogin}/> : null }
				<InfoMessage ref={this.refMessage} />
			</React.Fragment>
		)
	}
}

function SignIn(props) {
	var holder = props.holder;
	if( holder.state.showLoginForm )
		return null;
	var userInfo = holder.userInfo;
	if( userInfo.login ) {
		var cmbAsUserHtml = null;
		if( holder.isSuperUser() )
			var cmbAsUserHtml = (<CmbAsUser holder={holder} />);
		return (
			<React.Fragment>
				{userInfo.login} 
				{cmbAsUserHtml} 
				<ButtonIconText 
					onClick={holder.logOut} style={{marginLeft: '10px'}}
					text={holder.resource.buttonLogout} tooltip={holder.resource.buttonLogout} icon={exitIcon}
				/>
			</React.Fragment>
		)
	} else {
		return (
			<button onClick={holder.showLoginForm}>{holder.resource.buttonLogin}</button>
		)
	}
}

function CmbAsUser(props) {
	var holder = props.holder;
	var users = [ {login: 'admin'}, ...holder.state.groupsAndUsers.listUser];

	var optionsHtml = users.map((item, index) => {
		//var selected = item.value == value; selected={selected}
		return (<option key={index} value={item.login}>{item.login}</option>);
	});	

	return (
		<select 
			value={holder.state.userInfo.login} 
			onChange={holder.handleAsUserChange} 
			style={{marginLeft: '5px'}}
		>
			{optionsHtml}
		</select>
	);
}

const localStyles = {
	divMain: {
		display: 'flex',
		alignItems: 'center',
		backgroundColor: 'lightgray',
		border: '1px solid black',
		paddingTop: '3px',
		paddingBottom: '3px',
		paddingRight: '3px'
	}
}

export default Header;
