import React from 'react';
import styles from './TreeView.module.css';
import TreeNode from './TreeNode';
import { getCurrentResource } from '../../util/tools';
import { performApiRequest, TREE_POPULATEROOTS_API_URL, TREE_POPULATEBUNDLE_API_URL } from '../../util/restapi';

const FORM_STATE_POPULATING = 'p';
const FORM_STATE_NODE_POPULATING = 'np';
const FORM_STATE_NORMAL = 'n';
const FORM_STATE_ERROR = 'e';

class TreeView extends React.Component {
	
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.treeview.TreeView;	

		this.state = {
			formState: FORM_STATE_POPULATING,
			rootNodes: []	
		}
		this.responseMessage = '';		
		
		props.rateView.treeViewComponent = this;
		this.childrenComponents = [];
	}
	componentDidMount = async () => {
		this.refreshRoots();
	}
	refresh = () => {
		var components = this.getSelectedComponents( false );
		if( components.length == 0 )
			this.refreshRoots();
		else 
			this.refreshSelectedNodes(components);
	}
	refreshRoots = async () => {
		this.setState({formState: FORM_STATE_POPULATING, rootNodes: []})
		var request = {
			treeId: this.props.treeId,
			param: {
				rowQty: this.props.rateView.query.pageSize
			}
		}
		var response = await performApiRequest("tree/populateroots", request);
		if( response.success ) {
			this.setState({formState: FORM_STATE_NORMAL, rootNodes: response.data.roots})
		} else {
			this.responseMessage = response.message; 
			this.setState({formState: FORM_STATE_ERROR})
		}
	}
	refreshSelectedNodes = async (components) => {
		var request = {
			treeId: this.props.treeId,
			param: {
				rowQty: this.props.rateView.query.pageSize
			},
			listRefParent: []
		}
		for(let c of components) {
			c.startUpdate();
			var node = c.state.node;
			var refParent = {
				id: node.id,
				globalId: node.globalId,
				className: node.className
			};
			request.listRefParent.push( refParent );
		}
		var response = await performApiRequest(TREE_POPULATEBUNDLE_API_URL, request);
		for(let c of components) {
			c.completeUpdate(response);
		}
	}
	getSelectedNodes = () => {
		var nodes = [];
		for(let c of this.childrenComponents) {
			if( c.state.cbSelected )
				nodes.push( c.state.node );
			c.getSelectedNodes( nodes );
		}
		return nodes;
	}
	getSelectedComponents = (includeSubnodes) => {
		var components = [];
		for(let c of this.childrenComponents) {
			c.getSelectedComponents( components, includeSubnodes );
		}
		return components;
	}

	render() {
		if( this.state.formState == FORM_STATE_POPULATING || this.state.formState == FORM_STATE_NODE_POPULATING ) {
			return (
				<div className={styles.divTreeView}>
					{this.resource.waiting}
				</div>
			);
		}
		if( this.state.formState == FORM_STATE_ERROR ) {
			return (
				<div className={styles.divTreeView}>
					{this.responseMessage}
				</div>
			);
		}
		const rootNodes = this.state.rootNodes.map((child, index) => {
			return (
				<TreeNode key={index} rateView={this.props.rateView} parent={this} node={child} treeId={this.props.treeId} /> 
			)
		});
		return (
			<React.Fragment>
				<div className={styles.divTreeView}>
					{rootNodes}
				</div>
			</React.Fragment>
		);
	}
}

export default TreeView;
