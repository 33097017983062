import React, {createRef} from 'react';
import stylesPlain from '../../components/plain/plain.module.css';

import TableAuthorsShort from './components/TableAuthorsShort';

import PlainPage from '../../components/plain/PlainPage';
import Label from '../../components/plain/Label';
import FieldString from '../../components/plain/FieldString';
import FieldEnum from '../../components/plain/FieldEnum';
import TabbedPane from '../../components/plain/TabbedPane';

import {FORM_BOOK_PLAIN} from '../../util/consts/forms';

class BookPlainPage extends PlainPage {

	constructor(props) {
		super(props);
		this.formPath = FORM_BOOK_PLAIN;
		this.formTitle = 'Книга';
	}
	componentDidMount = async () => {
		this.init('Book')
	}
	createInstanceFromBean(bean) {
		var instance = super.createInstanceFromBean(bean);
		
		if( instance.rootField.value.identification.value.length == 0 ) {
			let fieldIdentificationList = instance.rootField.value.identification;
			instance.addListItem(fieldIdentificationList, null);
			let fieldIdentificationItem = fieldIdentificationList.value[0];
			fieldIdentificationItem.value.type.value = 'ISBN'; 
		}
		if( instance.rootField.value.title.value.length == 0 ) {
			let fieldTitleList = instance.rootField.value.title;
			instance.addListItem(fieldTitleList, null);
			let fieldTitleItem = fieldTitleList.value[0];
			fieldTitleItem.value.language.value = 'ukr'; 
			fieldTitleItem.value.titleType.value = 'Modern'; 
		}
		if( instance.rootField.value.contributor.value.length == 0 ) {
			let fieldContributorList = instance.rootField.value.contributor;
			instance.addListItem(fieldContributorList, null);
			let fieldContributorItem = fieldContributorList.value[0];
			fieldContributorItem.value.type.value = 'A01'; 
		}
		if( instance.rootField.value.contributor.value[0].value.names.value.length == 0 ) {
			let fieldContributorNameList = instance.rootField.value.contributor.value[0].value.names;
			instance.addListItem(fieldContributorNameList, null);
			let fieldContributorNameItem = fieldContributorNameList.value[0];
			fieldContributorNameItem.value.language.value = 'ukr'; 
		}
		if( instance.rootField.value.content.value.language.value.length == 0 ) {
			let fieldLanguageList = instance.rootField.value.content.value.language;
			instance.addListItem(fieldLanguageList, null);
			let fieldLanguageItem = fieldLanguageList.value[0];
			fieldLanguageItem.value = 'ukr'; 
		}
		if( instance.rootField.value.content.value.resume.value.length == 0 ) {
			let fieldResumeList = instance.rootField.value.content.value.resume;
			instance.addListItem(fieldResumeList, null);
			let fieldResumeItem = fieldResumeList.value[0];
			fieldResumeItem.value.language.value = 'ukr'; 
		}
		
		return instance;
	}

	renderBody() {
		var tabs = [
			{
				title: 'Параметри',
				component: <TabStab body={'Параметри'}/>,
			},
			{
				title: 'Ідентифікація',
				component: <TabStab body={'Ідентифікація'}/>,
			},
			{
				title: 'Назви',
				component: <TabStab body={'Назви'}/>,
			},
			{
				title: 'Автори',
				component: <TabStab body={''}/>,
			},
			{
				title: 'Авторитетні записи',
				component: <TabStab body={'Авторитетні записи'}/>,
			},

			{
				title: 'Видання',
				component: <TabStab body={'Видання'}/>,
			},
			{
				title: 'Фізичні характеристики',
				component: <TabStab body={'Фізичні характеристики'}/>,
			},
			{
				title: 'Зміст',
				component: <TabStab body={'Зміст'}/>,
			},
			{
				title: 'Зв\'язки з документами',
				component: <TabStab body={'Зв\'язки з документами'}/>,
			},
			{
				title: 'Теми класифікатора',
				component: <TabStab body={'Теми класифікатора'}/>,
			},
			{
				title: 'Електронні копії',
				component: <TabStab body={'Електронні копії'}/>,
			},
			{
				title: 'Зображення',
				component: <TabStab body={'Зображення'}/>,
			},
		];
		/*
			{
				title: 'Упорядкування',
				component: <TabStab body={'Упорядкування'}/>,
			},
			{
				title: 'Екранізації',
				component: <TabStab body={'Екранізації'}/>,
			},
			{
				title: 'Відзнаки і нагороди',
				component: <TabStab body={'Відзнаки і нагороди'}/>,
			},
		*/
		return (
			<div className={stylesPlain.divPlainMain}>
				{this.renderHeader()}
				
				{/* Body */}
				<div className={stylesPlain.divPlainBody} >
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
						<Label title={'ISBN'} />
						<FieldString instance={this.state.instance} path={'/identification/0/number'} plain={this} 
							styleInput={{width: '200px', marginLeft: '10px', marginRight: '30px'}} 
						/>
						<Label title={'Назва'} />
						<FieldString instance={this.state.instance} path={'/title/0/mainName'} plain={this} 
							styleInput={{width: '600px', marginLeft: '10px', marginRight: '30px'}} 
						/>
						<Label title={'Мова видання'} />
						<FieldEnum instance={this.state.instance} path={'/content/language/0'} plain={this} 
							styleSelect={{marginLeft: '10px'}} 
						/>
					</div>
					<TableAuthorsShort instance={this.state.instance} plain={this} />
					<div style={{marginTop: '12px'}}>
						<div>
							<Label title={'Анотація'}/>
						</div>
						<div>
							<FieldString instance={this.state.instance} path={'/content/resume/0/text'} plain={this}
								multiline={true} rows={5} cols={60} styleInput={{width: '1000px'}}
							 />
						</div>
					</div>			
					<TabbedPane tabs={tabs} divStyle={{border: '1px solid gray', marginTop: '10px'}}/>
				</div>
			</div>
		)
	}
}

class TabStab extends React.Component {
	
	render() {
		return (
			<div style={{minHeight: '200px'}}>
				<div style={{marginTop: '70px', textAlign: 'center', fontSize: '20pt'}}>
					{this.props.body}
				</div>
			</div>
		)
	}
}

export default BookPlainPage;
