import React, { Component } from 'react';

import config from '../../config/config';

import NavBar from '../../components/navigation/NavBar';
import RateView from '../../components/rateview/RateView';
import OnixExport from '../../components/exim/OnixExport';
import OnixImport from '../../components/exim/OnixImport';
import { getCurrentResource } from '../../util/tools';
import { canPerformAction } from '../../util/localStorage';
import { FORM_MESSAGE_TABLE } from '../../util/consts/forms';
import { ACTION_ONIX_EXPORT, ACTION_ONIX_IMPORT } from '../../util/consts/actions';

class MessageListPage extends Component {

	constructor(props) {
		super(props);
		this.refApiFile = React.createRef();
		this.state = {
			isImportModalOpen: false
		}

		this.resource = getCurrentResource().pages.tableform.MessageList;
		var param = {};
		var encodedParam = props.match.params.param;
		if( encodedParam ) {
			var strParam = decodeURIComponent(encodedParam);
			param = JSON.parse( strParam );
		}

		this.rvContext = {
			form: FORM_MESSAGE_TABLE,
			data: []
		}
		this.rvOptions = {
			form: FORM_MESSAGE_TABLE,
			formTitle: this.resource.formTitle,
//			leftSideTools: this.leftSideTools(),
			controlPanelTools: this.controlPanelTools(),
			transition: param.transition
		};
		if( param.transition )
			this.rvOptions.autoRefresh = true;
	}

	leftSideTools = () => {
		if( ! canPerformAction( ACTION_ONIX_EXPORT ) &&  ! canPerformAction( ACTION_ONIX_IMPORT ) )
			return ( null );
		return (
			<div style={{textAlign: "left"}}>
				<OnixExport holder={this} mode='leftSide' />&nbsp;
				<OnixImport holder={this} mode='leftSide' />
			</div>			
		);
	}
	controlPanelTools = () => {
		if( ! canPerformAction( ACTION_ONIX_EXPORT ) &&  ! canPerformAction( ACTION_ONIX_IMPORT ) )
			return ( null );
		return (
			<div style={{textAlign: 'left', marginLeft: '20px'}}>
				<OnixExport holder={this} mode='controlPanel' />
				<OnixImport holder={this} mode='controlPanel' />
				<OnixSendMessage holder={this} />
			</div>			
		);
	}

	sendTestMessage = async () => {
		this.refApiFile.current.click();
	}	
	apiFileSelected = async (e) => {
		var files = e.target.files;
		if( ! files || files.length == 0 )
			return;
		var file = files[0];
		const buffer = await file.arrayBuffer();
		const base64 = btoa(String.fromCharCode(...new Uint8Array(buffer)))
		
		var url = config.apiGateway.URL;
		if( config.apiGateway.BASE_PATH )
			url += '/' + config.apiGateway.BASE_PATH;
		url += '/action/message/onix/accept';
		
		var requestBody = {
			saveOriginal: false,
			downloadImages: false,
			ignoreInvalid: false,
			ignoreWarning: false,
			testMode: true,
			strOnixMessage: base64
		}
		
		const request = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json', 
				'Accept-Language': 'ukr',
				'Authorization': 'Basic ' + btoa('admin:ESR-bom-prod') 
			},
			body: JSON.stringify( requestBody )
		};
		
		var response = await fetch(url, request);
		response = await response.json();
		console.log( response );
	}
		
	render() {
		return (
			<React.Fragment>
				<NavBar app={this.props.app} />
				<RateView rvOptions={this.rvOptions} rvContext={this.rvContext} app={this.props.app} />
			</React.Fragment>
		)
	}
	
}

function OnixSendMessage(props) {
	if( ! config.debugMode) {
		return null;
	}
	return (
		<React.Fragment>
			<button type='button' style={{marginLeft: '5px'}}
				 onClick={props.holder.sendTestMessage}
			>
				TestAPI
			</button>
			<input ref={props.holder.refApiFile} type='file' style={{display: 'none'}} onChange={props.holder.apiFileSelected}/>			
		</React.Fragment>
	);
	
}

export default MessageListPage;
