import React from 'react';
import styles from '../RateView.module.css';

class FilterLabel extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		var tooltipText = this.props.tooltip;
		var tooltipHtml = (tooltipText) ? <span className={styles.tooltiptext}>{tooltipText}</span> : null;
		return (
			<div className={styles.divFilterLabelWithTooltip} style={this.props.style}>
				{this.props.title}
				{tooltipHtml}
			</div>
		);
	}	
}

export default FilterLabel;
