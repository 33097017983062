import React from 'react';
import stylesRV from '../RateView.module.css';

import UshImportModal from '../modal/UshImportModal';

import { getCurrentResource } from '../../../util/tools';
import { canPerformAction, getButtonMode } from '../../../util/localStorage';
import { ACTION_USH_IMPORT } from '../../../util/consts/actions';

import importIcon from '../../../assets/icons/png24x24/Download.png';

class ButtonUshImport extends React.Component {
	
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.rateview.button.ButtonUshImport;
		this.componentUshImportModal = null;
		this.state = {
			isImportModalOpen: false
		}
	}
	
	doImport = () => {
		if( this.componentUshImportModal )
			this.componentUshImportModal.init();
		this.setState({ isImportModalOpen: true });	
	}	
	closeImport = () => {
		this.setState({ isImportModalOpen: false });	
	}
	
	render() {
		if( ! canPerformAction( ACTION_USH_IMPORT ) )
			return ( null );
		var buttonStyle = this.props.buttonStyle ? this.props.buttonStyle : {}; 
		if( getButtonMode() == 'icon' ) {
			var button = (
				<button 
					type="button"
					className={stylesRV.buttonToolbarIcon} style={buttonStyle} 
					onClick={this.doImport} 
					title={this.resource.buttonImportTooltip}
				>
					<img src={importIcon} />
				</button>
			);
		} else {
			var button = (
				<button 
					type="button"
					className={stylesRV.buttonToolbarText} style={buttonStyle} 
					onClick={this.doImport}
					title={this.resource.buttonImportTooltip}
				>
					{this.resource.buttonImport}
				</button>
			);
		}
		return (
			<React.Fragment>
				{button}
				<UshImportModal 
					isModalOpen={this.state.isImportModalOpen} 
					close={this.closeImport} 
					holder={this}/>
			</React.Fragment>
		);
	}
	
}

export default ButtonUshImport;
