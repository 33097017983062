import React, { Component } from 'react';
import config from '../../config/config';

class BookSupplyPage extends Component {
	
	constructor(props) {
		super(props);
	}

	state = {
		message: 'Ready to import'
	};	

	startImport = () => {
		const query = {info: 'info'};
		var url = config.apiGateway.URL;
		if( config.apiGateway.BASE_PATH )
			url += '/' + config.apiGateway.BASE_PATH;
		url += '/booksupply/import';
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(query)			
		};
		fetch(url, requestOptions)
			.then(response => response.json())
			.then(data => { 
				this.setState( { message: data.message } );
		});
	}
	clearImport = () => {
		this.setState( { message: 'Ready to convert' } );
	}

	render() {
		return (
			<React.Fragment>
				<div>
					Book Supply
				</div>
				<div>
					<p>Message: <b>{this.state.message}</b></p>
				</div>
				<button onClick={this.startImport}>Import</button>
				&nbsp;
				<button onClick={this.clearImport}>Clear</button>
			</React.Fragment>
		)
	}
}

export default BookSupplyPage;
