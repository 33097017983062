import React from 'react';
import { getEnumeration, getEnumerationItem } from '../../../util/tools';
import styles from '../RateView.module.css';
import { getCurrentResource } from '../../../util/tools';
import FilterLabel from './FilterLabel';

class FilterEnumField extends React.Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.rateview.filter.FilterEnumField;		
		props.rateView.registerFilter( this );
		var initialValue = props.filter.initialValue;
		if( initialValue == null )
			initialValue = '';
		this.state = {
			currentValue: initialValue
		}
		this.currentValue = initialValue;
		this.setQueryFilter();
		this.enumeration = getEnumeration( this.props.rateView.formInfo.listEnumeration, this.props.filter.enumerationId );
		this.items = [{value: '', label: this.resource.noCondition}, ...this.enumeration.items];
	}
	
	clearFilter() {
		this.props.rateView.clearQueryFilter( this.props.filter.predicateId )
		this.currentValue = '';
		this.setState({currentValue: ''});
	}	
	handleValueChange = (e) => {
		this.props.rateView.clearData();
		this.currentValue = e.target.value;
		this.setQueryFilter();
		this.setState({ currentValue: this.currentValue });
	}
	setQueryFilter = () => {
		var q = this.props.rateView.query;
		var indexFilter = -1;
		var predicate;
		for(var i=0; i < q.listPredicate.length; i++) {
			if( q.listPredicate[i].predicateId == this.props.filter.predicateId ) {
				indexFilter = i;
				break;
			}
		}
		if( indexFilter < 0 ) {
			if( this.currentValue != '' ) {
				predicate = {
					predicateId: this.props.filter.predicateId,
					conditionId: this.props.filter.conditionId,
					value: this.currentValue
				};
				q.listPredicate.push( predicate );
			}
		} else {
			if( this.currentValue == '' ) {
				q.listPredicate.splice( indexFilter, 1 );
			} else {
				q.listPredicate[indexFilter].value = this.currentValue;
			}
		}
	}	

	render() {
		var filter = this.props.filter;
		var optionsHtml = this.items.map((item, index) => {
			var selected = (item.value == this.state.currentValue );
			return (<option key={index} value={item.value} title={item.description} selected={selected}>{item.label}</option>);
		});	
		
		return (
			<div className={styles.divFilter}>
				<FilterLabel title={filter.title} tooltip={filter.tooltip} />
				<div className={styles.divFilterInput}>
					<select style={{width: '100%'}} onChange={this.handleValueChange}>
						{optionsHtml}
					</select>
				</div>
			</div>
		);
	}	
}

export default FilterEnumField;
