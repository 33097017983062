import React from 'react';
import stylesPlain from './plain.module.css';

import { getDefinedValue } from '../../util/tools';
import { IET_SET_VALUE } from '../../util/realm/instance';

class FieldString extends React.Component {

	field = null;

	/*
		plain                      mandatory
		instance                   mandatory
		parentField                optional
		path                       mandatory
		styleInput
		readOnly
		multiline
		rows
		cols
		inputType // text, password
		autoComplete // on, off
		validator {
			isValueValid - function
			validBackground
			invalidBackground
		}
	*/
	constructor(props) {
		super(props);
		this.init();
		this.state = {
			value: this.field ? getDefinedValue( this.field.value, '') : '',
			readOnly: getDefinedValue( props.readOnly, false)
		}
	}
	componentDidUpdate(prevProps) {
		var doInit = false;
		doInit = doInit || prevProps.instance !== this.props.instance;
		doInit = doInit || prevProps.parentField !== this.props.parentField;
		doInit = doInit || prevProps.path !== this.props.path;
		if ( doInit ) {
			this.init();
			this.setState({ value: getDefinedValue( this.field.value, '') });
		}
		if( prevProps.readOnly !== this.props.readOnly )
			this.setState({ readOnly: getDefinedValue( this.props.readOnly, false) });
	}
	componentDidMount() {
		if( this.props.instance ) 
			this.props.instance.addChangeListener( this );
	}
	componentWillUnmount() {
		if( this.props.instance ) 
			this.props.instance.removeChangeListener( this );
  	}
	init = () => {
		if( this.props.instance ) {
			if( this.props.parentField )
				this.field = this.props.instance.getField(this.props.path, this.props.parentField );
			else
				this.field = this.props.instance.getField(this.props.path);
		}
	}
	
	handleInstanceChange = async (event) => {
		if( event.eventType == IET_SET_VALUE && event.field == this.field) {
			this.setState({ value: event.newValue });
		}
	}
	handleValueChange = e => {
		var value = e.target.value;
		
		if( this.props.validator ) {
			
		}
		
		this.props.instance.setFieldValue( this.field, value )
	}
	
	render() {
		var multiline = getDefinedValue( this.props.multiline, false);
		var style = getDefinedValue( this.props.styleInput, {});
		var readOnly = getDefinedValue( this.props.readOnly, false);
		
		if( multiline ) {
			var rows = getDefinedValue( this.props.rows, 4);
			var cols = getDefinedValue( this.props.cols, 30);
			return (
				<textarea 
					className={stylesPlain.textArea} style={style} rows={rows} cols={cols} 
					readOnly={readOnly} value={this.state.value} onChange={this.handleValueChange}
				/>
			);
		}
		
		var type = getDefinedValue( this.props.inputType, 'text');
		var autoComplete = getDefinedValue( this.props.autoComplete, 'on');
		return (
			<input type={type} className={stylesPlain.inputFieldString} style={style} autoComplete={autoComplete} readOnly={readOnly}
				value={this.state.value} onChange={ this.handleValueChange}
			/>
		);
	}
}

export default FieldString;
