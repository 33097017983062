import React from 'react';

class FilterValueStringField extends React.Component {

	/*
		props
			filter
			handleFilterChanged
	*/
	constructor(props) {
		super(props);
		this.filter = props.filter;
		if(this.filter.listValue.length == 0)
			this.filter.listValue.push('');
		this.state = {
			value: this.filter.listValue[0]
		}
	}
	
	handleChangeValue = (e) => {
		this.filter.listValue[0] = e.target.value;
		this.setState({value: this.filter.listValue[0]}, this.handleFilterChanged);
	}
	handleFilterChanged = () => {
		this.props.handleFilterChanged( this.filter );
	}

	render() {
		return (
			<div>
				<input type='text' style={{width: '100%'}} 
					value={this.state.value} onChange={this.handleChangeValue} 
				/>
			</div>
		);
	}
}

export default FilterValueStringField;
