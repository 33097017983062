import React from 'react';
import Modal from 'react-responsive-modal';
import styles from './RateView.module.css';

import { getCurrentResource } from '../../util/tools';
import { ENTITY_UPDATE_GLOBAL_ID_API_URL, ENTITY_GENERATE_GLOBAL_ID_API_URL, performApiRequest } from '../../util/restapi';

const FORM_STATE_READY = 'ready'; 
const FORM_STATE_WAITING = 'wait'; 

class UpdateGlobalIdModal extends React.Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().modal.rateview.UpdateGlobalIdModal;
		this.rateView = props.rateView;

		this.init( false );
		this.state = {
			formState: FORM_STATE_READY,
			newGlobalId: ''
		}		
		
	}
	
	init = (updateState) => {
		this.tryUpdate = false;
		this.updateResponse = null;

		this.oldGlobalId = '';
		var objects = this.rateView.getSelectedObjects();
		if( objects.length > 0 )
			this.oldGlobalId = objects[0].globalId;
		if( updateState ) { 
			this.setState({
				formState: FORM_STATE_READY,
				newGlobalId: ''
			});
		}
	}

	handleGlobalIdChange = (e) => {
		this.setState({newGlobalId: e.target.value});
	}	

	generateGlobalId = async () => {
		this.setState({formState: FORM_STATE_WAITING});
		var request = {
			classId: this.rateView.formInfo.masterClass,
			listOldGlobalId: [this.oldGlobalId]
		}
		this.updateResponse = await performApiRequest( ENTITY_GENERATE_GLOBAL_ID_API_URL, request );
		if( this.updateResponse.success ) {
			this.setState({newGlobalId: this.updateResponse.data, formState: FORM_STATE_READY});
		} else {
			this.tryUpdate = true;
			this.setState({formState: FORM_STATE_READY});
			this.props.holder.closeModal();
		}
	}

	doUpdate = async () => {
		this.tryUpdate = true;
		this.setState({formState: FORM_STATE_WAITING});
		var request = {
			classId: this.rateView.formInfo.masterClass,
			listOldGlobalId: [this.oldGlobalId],
			newGlobalId: this.state.newGlobalId
		}
		this.updateResponse = await performApiRequest( ENTITY_UPDATE_GLOBAL_ID_API_URL, request );
		this.setState({formState: FORM_STATE_READY});
		this.props.holder.closeModal();
	}

	render() {
		var waitingHtml = 
			(
				<div style={{height: '15px', marginBottom: '5px', textAlign: 'center'}}>
					{this.state.formState == FORM_STATE_WAITING ? this.resource.msgPleaseWait : ' ' }
				</div>
			);
		var buttonDisabled = (this.state.formState == FORM_STATE_WAITING);

		return (
			<Modal 
				open={this.props.isModalOpen} onClose={this.props.close} center styles={styleModal}
				closeOnOverlayClick={false} 
			>
				<div>
					<div className={styles.divHeader}>
						{this.resource.title}
					</div>
					<div style={{padding: '20px'}}>
						<div style={{display: 'flex', marginTop: '15px', marginBottom: '20px', padding: '10px'}}>
							<span style={{marginRight: '15px'}}>{this.resource.labelNewValue}</span>
							<input type='text' value={this.state.newGlobalId} onChange={this.handleGlobalIdChange} 
								style={{flexGrow: '100', marginRight: '15px'}}
							/>
							<button type='button' disabled={buttonDisabled} onClick={this.generateGlobalId}>{this.resource.buttonGenerate}</button>
						</div>
						{waitingHtml}
						<div style={{textAlign: 'right'}}>
							<button type='button' disabled={buttonDisabled} onClick={this.doUpdate}>{this.resource.buttonUpdate}</button>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

const styleModal = {
	modal: {
		background: '#ffffff',
		padding: '0px',
		borderRadius: '21px',
		maxWidth: '800px',
		width: '100%',
	},
	closeButton: {
		top: '10px',
		right: '10px',
		fill: '#FF0000',
		outline: 'none',
	},
};

export default UpdateGlobalIdModal;
