import React from 'react';
import styles from './FullDescription.module.css';
import Label from './Label';
import { initFieldStyles } from './util';
import { getCurrentResource, getInstanceFieldValue, setInstanceFieldValue } from '../../util/tools';

class FieldDate extends React.Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.fulldescription.FieldDate;
		this.state = {
			dirty: false
		}
	}
	
	handleValueChange = e => {
		var value = e.target.value;
		if( value )
			value = value.slice(0,4) + value.slice(5,7) + value.slice(8,10);
		var instance = this.props.plain.instance;
		setInstanceFieldValue(instance, this.props.field.path, value);
		this.setState({ dirty: true });
	};
	
	render() {

		if( this.props.listItem ) {
			var listItemTools = this.props.listItem.tools;
		} else {
			var listItemTools = (null);
		}

		var path = this.props.field.path;
		var id = path[ path.length-1 ];
		var value = getInstanceFieldValue(this.props.plain.instance, this.props.field.path);
		if( value != null && value.length == 8) {
			value = value.slice(0,4) + '-' + value.slice(4,6) + '-' + value.slice(6,9);
		}
	
		var styleFieldScalar = {};
		var styleLabel = {};
		initFieldStyles( this.props.field, styleFieldScalar, styleLabel );
	
		// type = datetime-local
		return (
			<React.Fragment>
				<div className={styles.divFieldScalar} style={styleFieldScalar}>
					<Label listItem={this.props.listItem} fieldModel={this.props.field.model} style={styleLabel} />
					<div className={styles.divFieldScalarValue}>
						<input type='date' 
								style={{width: 'calc(100% - 10px)'}}
								id={id}
								name={id}
								value={value}
								onChange={this.handleValueChange}
						/>
					</div>
					{listItemTools}
				</div>
			</React.Fragment>
		);
	}	
}

export default FieldDate;
