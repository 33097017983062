import React from 'react';
import Field from './Field';
import config from '../../config/config';
import { populateInstanceField } from './util';
import { getCurrentResource, getInstanceModel, getInstanceFields, setInstanceFieldValue } from '../../util/tools';
import { performApiRequest, performApiRequestForm, PLAIN_INIT_API_URL, ENTITY_SAVE_API_URL } 
from '../../util/restapi';
import styles from './FullDescription.module.css';

import { FILE_WAS_SET, FILE_WAS_CLEARED } from '../../util/consts/common';

class PlainFullDescription extends React.Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.fulldescription.PlainFullDescription;
		this.state = {
			isInited: false,
			isSuccess: true,
			inst: null,
			message: ''
		}
	}
	componentDidMount = async () => {
		var plain = this.props.plain;
		plain.component = this; 
		if( ! plain.instanceId )
			plain.instanceId = 0;
		plain.listFieldComponent = [];
		plain.systemData = null;

		var request = {
			classId: plain.class,
			instanceId: plain.instanceId,
			masterId: plain.param.masterId
		};
		var response = await performApiRequest(PLAIN_INIT_API_URL, request);
		if( ! response.success ) {
			this.setState({isInited: true, isSuccess: false, message: response.message});
			return;
		}
		var formInfo = response.data;
		
		plain.realm = formInfo.realm;
		plain.instance = formInfo.instance;
		plain.instanceModel = getInstanceModel(this.props.plain.realm.listInstanceModel, this.props.plain.instanceModelId);
		if( plain.instanceId == 0 )
			populateInstanceField(plain.instance, plain.instanceModel.root);
		
		plain.saveEntity = this.saveEntity;
		plain.files = [];
		this.setState( { isInited: true, inst: plain.instance } );
	}

	saveEntity = async () => {
		const form = new FormData();
		var instance = this.props.plain.instance;
		var isNew = instance.id == null || instance.id == 0;
		var files = [];
		var fileIndex = 0;
		for(let field of getInstanceFields( instance ) ) {
			if( field.fileState == FILE_WAS_SET ) {
				field.fileIndex = fileIndex++;
				files.push( field.fileBody );
				form.append('files', field.fileBody);
				field.fileBody = undefined;
			} if( field.fileState == FILE_WAS_CLEARED ) {
				field.fileBody = undefined;
			}
		}
		var jsonInstance = JSON.stringify( instance );
		for(let field of getInstanceFields( instance ) ) {
			if( field.fileState == FILE_WAS_SET ) {
				field.fileBody = files[ field.fileIndex ];
			}
		}

		form.append('jsonInstance', jsonInstance);
		form.append('class', this.props.plain.class);
		var response = await performApiRequestForm(ENTITY_SAVE_API_URL, form);
		if( response.success ) {
			instance = JSON.parse( response.data );
			this.props.plain.instance = instance;
			this.updateInstance( instance );
			if( this.props.plain.systemData != null ) {
				this.props.plain.systemData.setState({master:null, isCollapsed: true, isPopulated: false})
			}
		}
		var doAfterClose = null;
		if( isNew && response.success ) {
			doAfterClose = () => {
				var url = config.PATH + '/' + this.props.plain.instanceModelId + '/' + instance.id;
				console.log("Do after close", url);
				window.open(url, '_self')
			}
		}
		this.props.plain.app.showResponse( response, this.resource.messageSaved, doAfterClose );
	}
	updateInstance = (instance) => {
		this.setState( { inst: instance } );
		for( var c of this.props.plain.listFieldComponent) {
			if( c.field )
				c.field.setState( {inst: instance} )
		}
	}
	borrowFields = async (borrowUrl, donorId, mode) => {
		var request = {
			master: this.props.plain.instance,
			donorId: donorId,
			mode: mode 
		};
		var response = await performApiRequest( borrowUrl, request );
		if( response.success ) {
			this.props.plain.instance = response.data;
			this.setState({inst: this.props.plain.instance});
		} else {
			this.props.plain.app.showResponse( response );
		}
	}

	render() {
		if( ! this.state.isInited )
			return ( <div>{this.resource.messageInitForm}</div> );
		if( ! this.state.isSuccess )
			return ( 
				<div style={{margin: '50px', padding: '50px', color: 'red', fontSize: '16pt'}}>
					{this.state.message}
				</div> 
			);
		if( ! this.props.plain.instance )
			return ( <div>{this.resource.messageInstanceIsAbsent}</div> );

		const field = {
			model: this.props.plain.instanceModel.root,
			path: ['root']
		}
		return (
			<React.Fragment>
				<div className={styles.divFullDescription}>
					<Field plain={this.props.plain} field={field} />
				</div>
			</React.Fragment>
		)
	}
	
}

export default PlainFullDescription;
