import React, {createRef} from 'react';
import stylesPlain from '../../components/plain/plain.module.css';

import PlainPage, {FORM_STATE_INIT_ERROR} from '../../components/plain/PlainPage';

import {FORM_PRODUCT_PLAIN} from '../../util/consts/forms';

class ProductPlainPage extends PlainPage {

	constructor(props) {
		super(props);
		this.formPath = FORM_PRODUCT_PLAIN;
		this.formTitle = 'Title';
	}
	componentDidMount = async () => {
		this.init('Product')
	}

	renderBody() {
		return (
			<div className={stylesPlain.divPlainMain}>
				{this.renderHeader()}
				
				{/* Body */}
				<div className={stylesPlain.divPlainBody} >
					BODY
				</div>
			</div>
		)
	}
}

export default ProductPlainPage;
