import React, { Component } from 'react';
import { getCurrentResource, matchPath } from '../../util/tools';
import { performApiRequest, ENTITY_LOAD_LIST_API_URL } from '../../util/restapi';
import SelectInstance from '../../modal/selectinstance/SelectInstance';
import PlainFullDescription from '../../components/fulldescription/PlainFullDescription';
import styles from '../../components/fulldescription/FullDescription.module.css';
import { FORM_MESSAGE_FT, FORM_PRODUCT_FT, FORM_PRODUCT_TABLE } from '../../util/consts/forms';
import { CLASS_MESSAGE } from '../../util/consts/classes';

class MessagePage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isSelectInstanceModalOpen: false
		}
		this.resource = getCurrentResource().pages.plain.Message;
		this.plainInfo = {
			app: props.app,
			page: this,
			class: CLASS_MESSAGE,
			instanceModelId: FORM_MESSAGE_FT,
			instanceId: this.props.match.params.id,
			param: {}
			/*
			actions: [
				{
					path: ['root', 'products'],
					title: this.resource.buttonSelect,
					perform: this.selectProducts
				}
			] 
			*/
		};
	}

	selectProducts = () => {
		this.setState({ isSelectInstanceModalOpen: true });
	}
	onInstanceSelected = async (rows) => {
		var listId = [];
		for(let row of rows) {
			listId.push( row.id );
		}
		var request = {
			listId: listId,
		};
		console.log( 'Select product' )
		var response = await performApiRequest( ENTITY_LOAD_LIST_API_URL, request );
		if( response.success ) {
			var products = response.data;
			var path = ['root', 'products'];
			for( let fieldComponent of this.plainInfo.listFieldComponent ) {
				console.log( fieldComponent )
				if( matchPath(path, fieldComponent.path) ) {
					fieldComponent.field.addItems( products );
					break;
				}
			}
			this.closeSelectInstance();
		} else {
			// TODO Show message
		}
	}
	closeSelectInstance = () => {
		this.setState({ isSelectInstanceModalOpen: false });
	}

	render() {
		return (
			<React.Fragment>
				<div className={styles.divPlainTitle}>
					{this.resource.title}
				</div>
				<PlainFullDescription plain={this.plainInfo} />
				<SelectInstance 
					isModalOpen={this.state.isSelectInstanceModalOpen} 
					closeModal={this.closeSelectInstance}
					title={this.resource.titleProducts}
					form={FORM_PRODUCT_TABLE}
					onInstanceSelected={this.onInstanceSelected}
				/>
			</React.Fragment>
		)
	}
}

export default MessagePage;
