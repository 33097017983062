import React, { createRef } from 'react';
import styles from './RateView.module.css';

import config from '../../config/config';

import ButtonIconText from '../button/ButtonIconText';
import MergeInstancesModal from '../../modal/rateview/MergeInstancesModal';

import { getCurrentResource } from '../../util/tools';
import { getButtonMode, getUserInfo, canUpdateObject, canDeleteObject, canPerformAction } from '../../util/localStorage';
import { MASTER_LOAD_LIST_API_URL, performApiRequest } from '../../util/restapi';
import { ACTION_INSTANCE_MERGE } from '../../util/consts/actions';

import {MODE_TREE} from './RateView';

import mergeIcon from '../../assets/icons/png24x24/merge.png';

class ButtonMergeInstances extends React.Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.rateview.ButtonMergeInstances;
		this.rateView = props.holder;
		this.refModal = React.createRef();
		this.userInfo = getUserInfo();
		this.useIcons = getButtonMode() == 'icon';
		this.state = {
			isModalOpen: false,
		}
	}
	
	mergeInstances = async () => {
		var ids = this.props.holder.getSelectedIds();
		if( ids.length < 2 ) {
			this.rateView.props.app.showMessage( {mainMessage:  this.resource.msgSelectAtLeast2} );
			return;
		}	
	
		var request = {
			listObjectId: ids
		};
		var response = await performApiRequest( MASTER_LOAD_LIST_API_URL, request );
		if( ! response.success ) {
			this.messageHandler.showMessage( response.message );
			return;
		}
		
		this.refModal.current.init( response.data.listObjectMaster );
		this.setState({ isModalOpen: true });
	}
	closeModal = () => {
		this.setState({ isModalOpen: false });
		var modal = this.refModal.current; 
		if( modal.tryMerge ) {
			this.rateView.props.app.showResponse( modal.mergeResponse, this.resource.msgMerged );
		}
	}
	
	render() {
		var holder = this.props.holder;
		var userInfo = holder.userInfo;
		if( ! canPerformAction(ACTION_INSTANCE_MERGE, userInfo) )
			return null;
		if( ! ( holder.formInfo && holder.formInfo.enableMerge ) )
			return null;
		var disabled = false;
		if( holder.state.selectedObjects.length < 2 )
			disabled = true;
		else {
			for( let obj of holder.state.selectedObjects) {
				disabled = disabled || ! canUpdateObject(userInfo, obj.className, obj.ownerGroup, obj.ownerUser);
				disabled = disabled || ! canDeleteObject(userInfo, obj.className, obj.ownerGroup, obj.ownerUser);
			} 
		}
		var disabled = disabled || holder.state.tableTreeMode == MODE_TREE;
			
		return (
			<React.Fragment>
				<ButtonIconText 
					onClick={this.mergeInstances} disabled={disabled}
					buttonType={'button'} useIcon={this.useIcons} styles={styles}
					text={this.resource.buttonMergeInstances} 
					tooltip={this.resource.buttonMergeInstancesTooltip} icon={mergeIcon}
				/>
				<MergeInstancesModal ref={this.refModal} 
					isModalOpen={this.state.isModalOpen} 
					close={this.closeModal} 
					holder={this} />
			</React.Fragment>
		);
	}
}

export default ButtonMergeInstances;
