import React from 'react';
import Modal from 'react-responsive-modal';
import styles from './Exim.module.css';
import config from '../../config/config';
import { getCurrentResource } from '../../util/tools';
import { 
	QUERY_GETCLASSIFIERS_API_URL, ACTION_CLASSIFIERIMPORT_API_URL, 
	performApiGetRequest, performApiRequest 
} from '../../util/restapi';

const FORM_STATE_GET_DATA = 'gd';
const FORM_STATE_WAITING = 'w';
const FORM_STATE_IMPORT_COMPLETE = 'ic';

class ClassifierImportModal extends React.Component {

	constructor(props) {
		super(props);
		props.holder.componentClassifierImportModal = this;
		this.resource = getCurrentResource().modal.exim.ClassifierImportModal;		
		this.state = {
			formDirty: false,
			formState: FORM_STATE_GET_DATA
		};
		this.init();		
	}
	init = async () => {
		this.classifiers = await performApiGetRequest( QUERY_GETCLASSIFIERS_API_URL );
		this.classifiers.unshift({id: 0, cardMaster: ''});
		this.importResultMessage = "";
		this.importOptions = {
			classifierType: '',
			classifierId: 0,
			fileName: ""
		}
		this.setState({formState: FORM_STATE_GET_DATA});
	}
	
	styleModal = {
		modal: {
			background: '#ffffff',
			padding: '0px',
			borderRadius: '21px',
			maxWidth: '500px',
			width: '100%',
		},
		closeButton: {
			top: '10px',
			right: '10px',
			fill: '#FF0000',
			outline: 'none',
		},
	};
	
	onParameterChange = (e) => {
		if( e.target.name == 'cmbType' ) {
			this.importOptions.classifierType = e.target.value;
		} else if ( e.target.name == 'cmbClassifier' ) {
			this.importOptions.classifierId = e.target.value;
		} else if ( e.target.name == 'textFile' ) {
			this.importOptions.fileName = e.target.value;
		}
		this.setState({formDirty: true});
	}
	
	startImport = async () => {
		this.setState({formState: FORM_STATE_WAITING});

		var request = {
			classifierType: this.importOptions.classifierType,
			classifierId: this.importOptions.classifierId,
			fileName: this.importOptions.fileName
		};

		var response = await performApiRequest(ACTION_CLASSIFIERIMPORT_API_URL, request );

		if( response.success && (! response.message || response.message == '') )
			this.importResultMessage = this.resource.msgSuccess;
		else
			this.importResultMessage = response.message;
		this.setState({formState: FORM_STATE_IMPORT_COMPLETE});
	}

	render() {
		return (
			<Modal 
				open={this.props.isModalOpen} onClose={this.props.close} center styles={this.styleModal}
				closeOnOverlayClick={false} 
			>
				<ImportForm holder={this} />
			</Modal>
		);
	}
}

function ImportForm(props) {
	var res = props.holder.resource;
	var formData = props.holder.importOptions; 

	var types = [
		{id: '', name: ''},
		{id: 'Thema', name: 'Thema'},
		{id: 'USH', name: 'USH'}
	];
	var typeOptions = types.map((type,index) => {
		return <option key={index} value={type.id}>{type.name}</option>
	});

	var classifierOptions = props.holder.classifiers.map((classifier,index) => {
		return <option key={index} value={classifier.id}>{classifier.cardMaster}</option>
	});

	var importDisabled = props.holder.state.formState != FORM_STATE_GET_DATA;;
	var buttonImportDisabled = importDisabled || ( ! props.holder.importOptions.classifierType); 
	buttonImportDisabled = buttonImportDisabled || ( ! props.holder.importOptions.classifierId); 
	buttonImportDisabled = buttonImportDisabled || ( ! props.holder.importOptions.fileName); 
	buttonImportDisabled = buttonImportDisabled || ( props.holder.importResultMessage); 

	if( props.holder.state.formState == FORM_STATE_GET_DATA ) {
		var divWaiting = (null);
		var divMessage = (null);
	} else if( props.holder.state.formState == FORM_STATE_WAITING ) {
		var divWaiting = <div className={styles.divWaiting}>{res.waiting}</div>
		var divMessage = (null);
	} else if( props.holder.state.formState == FORM_STATE_IMPORT_COMPLETE ) {
		var divWaiting = (null)
		var divMessage = (
			<textarea className={styles.areaMessage} rows="4" readOnly={true}>
				{props.holder.importResultMessage}
			</textarea>		
		);
	}

	return (
		<React.Fragment>
			<div className={styles.divHeader}>
				{res.title}
			</div>
			<div className={styles.divParameters}>
				<p>
					{res.labelType}:&nbsp; 
					<select name='cmbType' disabled={importDisabled} onChange={props.holder.onParameterChange} >
						{typeOptions}
					</select>
				</p>
				<p>
					{res.labelClassifier}:&nbsp; 
					<select name='cmbClassifier' disabled={importDisabled} onChange={props.holder.onParameterChange} >
						{classifierOptions}
					</select>
				</p>
				<p>
					{res.labelFile}: &nbsp;
					<input type='text' name='textFile' disabled={importDisabled} onChange={props.holder.onParameterChange} />
				</p>
			</div>
			{divWaiting}
			{divMessage}
			<div className={styles.divButtons}>
				<button 
					onClick={props.holder.startImport} 
					disabled={buttonImportDisabled} 
					title={res.buttonExportTooltip}>
						{res.buttonImport}
					</button>
			</div>
		</React.Fragment>
	);
}

export default ClassifierImportModal;
