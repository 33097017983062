import React from 'react';
import stylesPlain from './plain.module.css';

import { getDefinedValue } from '../../util/tools';
import { IET_SET_VALUE, IET_ADD_LIST_ITEM, IET_DELETE_LIST_ITEM } from '../../util/realm/instance';

import iconAdd from '../../assets/icons/png24x24/Add.png';
import iconMoveUp from '../../assets/icons/png24x24/Up.png';
import iconMoveDown from '../../assets/icons/png24x24/Down.png';
import iconDelete from '../../assets/icons/png24x24/Delete.png';

class FieldList_CardsPanel extends React.Component {
	
	field = null;
	disableMoveItems = false;

	/*
		plain
		instance                   mandatory
		parentField
		path
		itemComponent
		readOnly
		disableMoveItems
		divStyle
		title
		tooltip
		collapsed
		itemCardBuilder
	*/
	constructor(props) {
		super(props);
		if( props.disableMoveItems )
			this.disableMoveItems = props.disableMoveItems;
		this.init();
		this.state = {
			value: this.field.value,
			selectedIndex: this.field.value.length == 0 ? -1 : 0,
			readOnly: false,
			compoundCard: '',
		}
	}
	componentDidUpdate(prevProps) {
		var doInit = false;
		doInit = doInit || prevProps.instance !== this.props.instance;
		doInit = doInit || prevProps.parentField !== this.props.parentField;
		doInit = doInit || prevProps.path !== this.props.path;
		if ( doInit ) {
			this.init();
			this.setState({ value: this.field.value });
		}
		if( prevProps.readOnly !== this.props.readOnly )
			this.setState({ readOnly: getDefinedValue( this.props.readOnly, false) });
	}
	componentDidMount() {
		if( this.props.instance ) 
			this.props.instance.addChangeListener( this );
	}
	componentWillUnmount() {
		if( this.props.instance ) 
			this.props.instance.removeChangeListener( this );
  	}
	init = () => {
		if( this.props.instance ) {
			if( this.props.parentField )
				this.field = this.props.instance.getField(this.props.path, this.props.parentField );
			else
				this.field = this.props.instance.getField(this.props.path);
		}
	}
	
	getCompoundCard = () => {
		var compoundCard = '';
		for(let i=0; i < this.field.value.length; i++) {
			let itemField = this.field.value[i];
			compoundCard += this.props.itemCardBuilder(itemField, i);
		}
		return compoundCard;
	}
	handleInstanceChange = async (event) => {
		if( event.eventType == IET_ADD_LIST_ITEM && event.field == this.field) {
			var index = this.field.value.length-1;
			this.setState({
				value: this.field.value,
				selectedIndex: index,
				compoundCard: this.getCompoundCard(),
			});
		} else if( event.eventType == IET_DELETE_LIST_ITEM && event.field == this.field) {
			var index = event.itemIndex;
			if( index >= this.field.value.length )
				index--;
			this.setState({
				value: this.field.value,
				selectedIndex: index,
				compoundCard: this.getCompoundCard(),
			});
		} else {
			var compoundCard = this.getCompoundCard();
			if( compoundCard != this.state.compoundCard ) {
				this.setState({compoundCard: compoundCard});
			}
		}
	}
	addItem = () => {
		this.props.plain.instance.addListItem(this.field);
	}
	deleteItem = () => {
		var index = this.state.selectedIndex;
		if( index < 0 || index >= this.field.value.length )
			return;
			
		// TODO show confirmation dialog
		this.props.plain.instance.deleteListItem(this.field, index);
	}	
	
	handleCardClick = (index) => {
		var itemField = this.field.value[index];
		this.setState({selectedIndex: index})
	}
	
	render() {
		var cardsHtml = this.field.value.map((itemField, index) => {
			var divStyle = {...localStyles.divCard};
			if( index == this.state.selectedIndex )
				divStyle.backgroundColor = 'lightBlue';
			return (
				<div key={index} style={divStyle} onClick={ () => {this.handleCardClick(index)} }>
					{this.props.itemCardBuilder(itemField, index)}
				</div>
			)
		});
		if( this.disableMoveItems )
			var moveItemsButtonsHtml = null;
		else {
			moveItemsButtonsHtml = (
				<React.Fragment>
					<button type='button' className={stylesPlain.buttonIcon} style={{marginLeft: '5px'}}
						onClick={this.MoveUpItem}
					>
						<img src={iconMoveUp} />
					</button>
					<button type='button' className={stylesPlain.buttonIcon} style={{marginLeft: '5px'}}
						onClick={this.MoveDownItem}
					>
						<img src={iconMoveDown} />
					</button>
				</React.Fragment>
			)
		}
		var listItemInfo = {
			index: this.state.selectedIndex,
			isLastItem: false,
			divButtonsHtml: null
		};
		if( this.state.selectedIndex < 0 )
			var selectedItemHtml = null;
		else {
			var activeField = this.field.value[this.state.selectedIndex];
			selectedItemHtml = <this.props.itemComponent fieldGroup={activeField} listItemInfo={listItemInfo} instance={this.props.instance} plain={this.props.plain} />
		} 
		return (
			<div style={{display: 'flex'}}>
				<div style={localStyles.divCards}>
					<div style={localStyles.divCardsHeader}>
						<div style={{flexGrow: '1'}}>
							{this.props.title}
						</div>
						<button type='button'  className={stylesPlain.buttonIcon} style={{marginLeft: '20px'}}
							onClick={this.addItem}
						>
							<img src={iconAdd} />
						</button>
						{moveItemsButtonsHtml}
						<button type='button'  className={stylesPlain.buttonIcon} style={{marginLeft: '15px'}}
							onClick={this.deleteItem}
						>
							<img src={iconDelete} />
						</button>
					</div>
					{cardsHtml}
				</div>
				{selectedItemHtml}
			</div>
		)
	}
}

const localStyles = {
	divCards: {
		padding: '10px',
		backgroundColor: 'lightGray',
	},
	divCardsHeader: {
		marginBottom: '10px',
		display: 'flex',
		alignItems: 'center',
		fontWeight: 'bold',
		fontSize: '13pt'
	},
	divCard: {
		padding: '7px',
		cursor: 'pointer',
		backgroundColor: 'lightGray',
		minHeight: '18px'
	}
}

export default FieldList_CardsPanel;
