import React from 'react';
import styles from './FullDescription.module.css';

import Field from './Field';
import ButtonIconText from '../button/ButtonIconText';
import { populateInstanceField } from './util';
import { 
	getCurrentResource, getInstanceField, matchPath, setInstanceFieldValue, 
	getEnumeration, getEnumerationItem 
} from '../../util/tools';
import { getButtonMode, getLanguage } from '../../util/localStorage';

import collapseIcon from '../../assets/icons/png24x24/collapse_silver.png';
import expandIcon from '../../assets/icons/png24x24/expand.png';
import expandGreyIcon from '../../assets/icons/png24x24/expand_grey.png';
import addIcon from '../../assets/icons/png24x24/Add.png';
import moveUpIcon from '../../assets/icons/png24x24/Up.png';
import moveDownIcon from '../../assets/icons/png24x24/Down.png';
import deleteIcon from '../../assets/icons/png24x24/Delete.png';

class FieldList extends React.Component {

	constructor(props) {
		super(props);
		this.fieldComponent = {
			field: this,
			path: props.field.path
		}
		props.plain.listFieldComponent.push(this.fieldComponent);
		this.resource = getCurrentResource().components.fulldescription.FieldList;

		this.maxListItemId = 1;
		this.listItemId = [];
		this.fieldList = getInstanceField(props.plain.instance, props.field.path);
		if( ! this.fieldList ) {
			let newValue = [];
			setInstanceFieldValue( props.plain.instance, this.props.field.path, newValue );
			this.fieldList = getInstanceField(props.plain.instance, this.props.field.path);
		}
		for(let i=0; i < this.fieldList.length; i++) {
			this.listItemId.push( this.maxListItemId++); 
		}
		
		var collapsed = ! props.field.model.autoExpandMultiple;
		collapsed = collapsed || (this.listField && this.listField.length == 0 );
		this.state = {
			isCollapsed: collapsed,
			renderCounter: 0
		};	
		this.useIcons = getButtonMode() == 'icon';
		this.language = getLanguage();
		//this.listSubfields = [];
		if( props.parent && props.parent.listSubfields )
			props.parent.listSubfields.push( this.fieldComponent )
	}

	expand = () => {
		this.setState( { isCollapsed: false } );
	}
	collapse = () => {
		this.setState( { isCollapsed: true } );
	}
	
	createCard = () => {
		var fieldModel = this.props.field.model;
		if( fieldModel.type == 'enumeration' )
			var enumeration = getEnumeration( this.props.plain.realm.listEnumeration, fieldModel.enumerationId );
		var separator = fieldModel.arrayCardItemSeparator;
		if( ! separator )
			separator = ', ';
		var card = '';
		for(let i=0; i < this.fieldList.length; i++) {
			if( fieldModel.type == 'group' )
				var itemCard = this.fieldList[i].card;
			else if( fieldModel.type == 'enumeration' ) {
				var enumItem = getEnumerationItem( enumeration.items, this.fieldList[i] );
				if( enumItem != null )
					var itemCard = enumItem.label;
			}
			if( itemCard ) {
				if( card != '' )
					card += separator;
				card += itemCard;
			}	
		}
		if( card.length > 100 ) 
			card = card.substring(0,97) + '...';
		return card;
	}

	addItem = () => {
		var type = this.props.field.model.type;
		if( type == 'group' ) {
			var newItem = {}; 
			populateInstanceField(newItem, this.props.field.model);
			this.fieldList.push( newItem );
		} else if( type == 'mlstring' ) {
			var newItem = {lang: this.language, value: ''}; 
			this.fieldList.push( newItem );
		} else {
			this.fieldList.push( null );
		}
		this.listItemId.push( this.maxListItemId++);
		this.expandLastItem = true;
		//this.setState({ inst: instance });
		//this.setState({ inst: instance }, () => {
		//	if( type == 'group' && this.listSubfields.length > 0 ) {
		//		let lastField = this.listSubfields[ this.listSubfields.length-1 ].field;
		//		lastField.setState({isCollapsed: false}); 
		//	}
		//});
		this.setState({ renderCounter: this.state.renderCounter+1 });
	}
	populateItem = (item, itemModel) => {
		for( let subfield of itemModel.subfields ) {
			if( subfield.defaultValue ) {
				item[subfield.id] = subfield.defaultValue;
			} else if( subfield.type == 'group' && ! subfield.multiple ) {
				item[subfield.id] = {};
				this.populateItem(item[subfield.id], subfield);
			} else if( subfield.type == 'line' ) {
				this.populateItem(item, subfield);
			}
		}
	}
	addItems = (items) => {
		for(let item of items) {
			this.fieldList.push( item );
			this.listItemId.push( this.maxListItemId++);
		}
		this.setState({ renderCounter: this.state.renderCounter+1 });
	}
	moveUpItem = (index) => {
		if( index == 0 )
			return;
		var item = this.fieldList[index];
		this.fieldList[index] = this.fieldList[index-1];
		this.fieldList[index-1] = item;
		var itemId = this.listItemId[index]
		this.listItemId[index] = this.listItemId[index-1];
		this.listItemId[index-1] = itemId;
		this.setState({ renderCounter: this.state.renderCounter+1 });
	}
	moveDownItem = (index) => {
		if( index == this.fieldList.length - 1 )
			return;
		var item = this.fieldList[index];
		this.fieldList[index] = this.fieldList[index+1];
		this.fieldList[index+1] = item;
		var itemId = this.listItemId[index]
		this.listItemId[index] = this.listItemId[index+1];
		this.listItemId[index+1] = itemId;
		this.setState({ renderCounter: this.state.renderCounter+1 });
	}
	deleteItem = (index) => {
		this.fieldList.splice( index, 1 );
		this.listItemId.splice( index, 1 );
		this.setState({ renderCounter: this.state.renderCounter+1 });
	}

	render() {
		try {
			var fieldModel = this.props.field.model;
			if( this.state.isCollapsed ) {
				var shortFormHtml = null;
				var cardHtml = null;
				if( fieldModel.useShortForm ) {
					var newField = null;
					if( fieldModel.type == 'group' ) {
						if( fieldModel.shortFormField ) {
							if( this.fieldList.length == 0 ) {
								var newItem = {}; 
								populateInstanceField(newItem, this.props.field.model);
								this.fieldList.push( newItem );
								this.listItemId.push( this.maxListItemId++);
							}
							var path = [...this.props.field.path];
							path.push( 0 );
 							path.push( fieldModel.shortFormField.id );
							newField = {
								path: path,
								model: fieldModel.shortFormField,
							}
						}
					} else {
						if( this.fieldList.length == 0 ) {
							this.fieldList.push( null );
							this.listItemId.push( this.maxListItemId++);
						}
						var path = [...this.props.field.path];
						path.push( 0 );
						newField = {
							path: path,
							model: fieldModel
						}
					}
					if( newField ) {
						var listItem = {
							itemIndex: 0,
							isLastItem: (0 == this.fieldList.length-1),
							moveUpItem: null,
							moveDownItem: null,
							deleteItem: null,
							tools: null
						}
						shortFormHtml = <Field plain={this.props.plain} field={newField} parent={this.fieldComponent} listItem={listItem} />;
					}
				} else if( fieldModel.showArrayCard ) {
					cardHtml = <div style={{flexGrow: 100, marginLeft: '7px'}}>{this.createCard()}</div>;
				}
				var buttonIcon = ( shortFormHtml == null && this.fieldList.length > 0 || shortFormHtml != null && this.fieldList.length > 1 ) ? 
					expandIcon : expandGreyIcon ;
				return (
					<div className={styles.divFieldGroup}>
						<div style={{display: 'flex', alignItems: 'center'}}>
							<ButtonIconText 
								useIcon={this.useIcons} onClick={this.expand} styles={styles}
								tooltip={this.resource.buttonExpandTooltip} text={this.resource.buttonExpand}
								icon={buttonIcon}
							/>
							<FieldListLabel model={this.props.field.model} />&nbsp;
							{cardHtml}
							{shortFormHtml}							
						</div>
					</div>
				);
			} else {
				const items = this.listItemId.map((itemId, index) => {
					var path = [...this.props.field.path];
					path.push( index );
					var newField = {
						path: path,
						model: fieldModel
					}
					if( fieldModel.disableMove ) {
						var buttonDeleteDisabled = fieldModel.readOnly; 				
						var listItemTools = (
							<div style={{flexGrow: 0, minWidth: '40px', marginLeft: '7px'}}>
								<ButtonIconText 
									useIcon={this.useIcons} styles={styles}
									tooltip={this.resource.buttonDeleteTooltip} text={this.resource.buttonDelete} icon={deleteIcon}
									onClick={ () => this.deleteItem(index) } disabled={buttonDeleteDisabled} 
								/>
							</div>
						)
					} else {
						var buttonMoveUpDisabled = fieldModel.readOnly; 				
						var buttonMoveDownDisabled = fieldModel.readOnly; 				
						var buttonDeleteDisabled = fieldModel.readOnly; 				
						var listItemTools = (
							<div style={{flexGrow: 0, minWidth: '120px', marginLeft: '7px'}}>
								<ButtonIconText 
									useIcon={this.useIcons} styles={styles} 
									tooltip={this.resource.buttonMoveUpTooltip} text={this.resource.buttonMoveDown} icon={moveUpIcon}
									onClick={ () => this.moveUpItem(index) } disabled={buttonMoveUpDisabled} 
								/>
								&nbsp;
								<ButtonIconText 
									useIcon={this.useIcons} styles={styles}
									tooltip={this.resource.buttonMoveDownTooltip} text={this.resource.buttonMoveDown} icon={moveDownIcon}
									onClick={ () => this.moveDownItem(index) } disabled={buttonMoveDownDisabled} 
								/>
								&nbsp;&nbsp;&nbsp;
								<ButtonIconText 
									useIcon={this.useIcons} styles={styles}
									tooltip={this.resource.buttonDeleteTooltip} text={this.resource.buttonDelete} icon={deleteIcon}
									onClick={ () => this.deleteItem(index) } disabled={buttonDeleteDisabled} 
								/>
							</div>
						)
					}
					var listItem = {
						itemIndex: index,
						isLastItem: (index == this.fieldList.length-1),
						moveUpItem: this.moveUpItem,
						moveDownItem: this.moveDownItem,
						deleteItem: this.deleteItem,
						tools: listItemTools
					}
					return (
						<Field key={itemId} plain={this.props.plain} field={newField} parent={this.fieldComponent} listItem={listItem} /> 
					)
				});
				
				if( this.props.plain.actions ) {
					var actionButtons = this.props.plain.actions.map((action, index) => {
						if( matchPath(action.path, this.props.field.path) ) {
							return ( <ActionButton key={index} action={action} holder={this} /> )
						}
					});
				} else {
					var actionButtons = (null);
				}
				
				if( fieldModel.readOnly ) {
					var buttonAddHtml = null;
				} else {
					var buttonAddHtml = (
						<ButtonIconText
							style={{marginLeft: '10px'}} 
							onClick={this.addItem} useIcon={this.useIcons} styles={styles}
							tooltip={this.resource.buttonAddTooltip} text={this.resource.buttonAdd}
							icon={addIcon}
						/>
					)
				}
				
				return (
					<div className={styles.divFieldList}>
						<div style={{display: 'flex', alignItems: 'center'}}>
							<ButtonIconText 
								useIcon={this.useIcons} onClick={this.collapse} styles={styles}
								tooltip={this.resource.buttonCollapseTooltip} text={this.resource.buttonCollapse}
								icon={collapseIcon}
							/>
							{buttonAddHtml}
							{actionButtons}
							&nbsp;
							<FieldListLabel model={this.props.field.model} />&nbsp; 
						</div>
						<div className={styles.divSubfields}>
							{items}
						</div>
					</div>
				);
			}
		} catch(err) {
			console.log("Field", this.props.field.path);
			console.log(err);
			return (null);
		}
	}	
}

function FieldListLabel(props) {
	var label = props.model.title;
	var tooltip = props.model.description;
	if( tooltip)
		return (
			<div className={styles.divGroupLabelWithTooltip} style={{flexGrow: 1, marginLeft: '7px'}}>
				{label} 
				<span className={styles.tooltiptext}>{tooltip}</span>
			</div>
		);
	else {
		return (
			<div style={{flexGrow: '0', marginLeft: '7px'}}>
				{label}
			</div>
		);
	}
}


function ActionButton(props) {
	if( matchPath(props.action.path, props.holder.props.field.path) ) {
		return (
			<React.Fragment>
				&nbsp;<button type='button' onClick={props.action.perform} title={props.action.tooltip}>{props.action.title}</button>
			</React.Fragment>
		)
	} else {
		return (null);
	}
}

export default FieldList;

