import React from 'react';
import TableRow from './TableRow';

class TableBody extends React.Component {

	constructor(props) {
		super(props);
		props.tableView.componentTableBody = this;
		this.listComponentTableRow = [];
	}

	markPage = () => {
		var hasNotMarked = false;
		for( let r of this.props.tableData ) {
			if( ! r.checked ) {
				hasNotMarked = true;
				break;
			}
		}
		var newMarkedValue = hasNotMarked;
		for( let r of this.listComponentTableRow ) {
			if( r.state.marked != newMarkedValue)
				r.setMarked( newMarkedValue );
		}
	}

	render() {
		const {rateView, tableView, tableData} = this.props;
		const rows = this.props.tableData.map((row, index) => {
			return <TableRow rateView={rateView} tableView={tableView} tableData={tableData} tableBody={this} key={index} index={index} row={row} />
		});
		return (
			<tbody>
				{rows}
			</tbody>
		);
	}
}

export default TableBody;
