import React, { createRef } from 'react';
import SelectionList from '../../selectionList/SelectionList';
import { getCurrentResource } from '../../../util/tools';

class TabSorting extends React.Component {
	/*
		props
			rateView
			queryOptions
				...
				listActiveSorting
					[sortingId: 'id', title: 'title', tooltip: 'tooltip', ascending: true]
	*/	
	constructor(props) {
		super(props);

		this.resource = getCurrentResource().components.rateview.conditions.TabSorting;
		
		this.refSorting = React.createRef();
		this.refActiveSorting = React.createRef();
		
		this.state = {
			listSorting: this.createListSorting( props.queryOptions.listActiveSorting ),
			selectedSortingIndex: -1,
			listActiveSorting: props.queryOptions.listActiveSorting,
			selectedActiveSortingIndex: -1,
			selectedActiveSorting: null
		}
	}
	createListSorting = (listActiveSorting) => {
		var listSorting = [];
		for(let sorting of this.props.rateView.formInfo.listSorting) {
			var active = false;
			for(let activeSorting of listActiveSorting) {
				if( activeSorting.sortingId == sorting.sortingId ) {
					active = true;
					break;
				}
			}
			if( ! active )
				listSorting.push( sorting );
		}
		return listSorting;
	}

	handleSortingSelection = (index) => {
		this.setState({selectedSortingIndex: index});
	}
	handleActiveSortingSelection = (index) => {
		var activeSorting = index < 0 ? null : this.state.listActiveSorting[index]; 
		this.setState({
			selectedActiveSortingIndex: index, 
			selectedActiveSorting: activeSorting
		});
	}
	
	addActiveSorting = () => {
		if( this.state.selectedSortingIndex < 0 )
			return;
		var sorting = this.state.listSorting[ this.state.selectedSortingIndex ];
		var listActiveSorting = this.state.listActiveSorting;
		var activeSorting = {sortingId: sorting.sortingId, title: sorting.title, ascending: true};
		listActiveSorting.push( activeSorting );
		var listSorting = this.createListSorting( listActiveSorting );
		this.refSorting.current.setSelectedIndex( -1 );
		this.refActiveSorting.current.setSelectedIndex( listActiveSorting.length-1 );
		
		this.setState({
			listSorting: listSorting,
			selectedSortingIndex: -1,
			listActiveSorting: listActiveSorting,
			selectedActiveSortingIndex: listActiveSorting.length-1,
			selectedActiveSorting: activeSorting
		});
	}
	moveUpActiveSorting = () => {
		var listActiveSorting = this.state.listActiveSorting;
		var asIndex = this.state.selectedActiveSortingIndex; 
		if( asIndex < 1 )
			return;
		var as = listActiveSorting[ asIndex ];
		listActiveSorting[ asIndex ] = listActiveSorting[ asIndex-1 ];
		listActiveSorting[ asIndex-1 ] = as;			
		this.refActiveSorting.current.setSelectedIndex( asIndex-1 );

		this.setState({
			listActiveSorting: listActiveSorting,
			selectedActiveSortingIndex: asIndex-1,
			selectedActiveSorting: as
		});
	}
	moveDownActiveSorting = () => {
		var listActiveSorting = this.state.listActiveSorting;
		var asIndex = this.state.selectedActiveSortingIndex; 
		if( asIndex >= listActiveSorting-1 )
			return;
		var as = listActiveSorting[ asIndex ];
		listActiveSorting[ asIndex ] = listActiveSorting[ asIndex+1 ];
		listActiveSorting[ asIndex+1 ] = as;			
		this.refActiveSorting.current.setSelectedIndex( asIndex+1 );

		this.setState({
			listActiveSorting: listActiveSorting,
			selectedActiveSortingIndex: asIndex+1,
			selectedActiveSorting: as
		});
	}
	deleteActiveSorting = () => {
		if( this.state.selectedActiveSortingIndex < 0 )
			return;
		var listActiveSorting = this.state.listActiveSorting;
		listActiveSorting.splice(this.state.selectedActiveSortingIndex, 1);
		var listSorting = this.createListSorting( listActiveSorting );
		this.refActiveSorting.current.setSelectedIndex( -1 );
		
		this.setState({
			listSorting: listSorting,
			selectedSortingIndex: -1,
			listActiveSorting: listActiveSorting,
			selectedActiveSortingIndex: -1,
			selectedActiveSorting: null
		});
	}
	deleteAllActiveSorting = () => {
		var listActiveSorting = this.state.listActiveSorting;
		listActiveSorting.length = 0;
		var listSorting = this.createListSorting( listActiveSorting );
		this.refActiveSorting.current.setSelectedIndex( -1 );
		this.setState({
			listSorting: listSorting,
			selectedSortingIndex: -1,
			listActiveSorting: listActiveSorting,
			selectedActiveSortingIndex: -1,
			selectedActiveSorting: null
		});
	}
	handleRadioButtonChange = (e) => {
		const {name, value} = e.target;
		var as = this.state.selectedActiveSorting;
		if( name == 'asc' )
			as.ascending = true;
		else
			as.ascending = false;
		this.setState({selectedActiveSorting: as});
	}
	
	render() {
		var res = this.resource;
		var as = this.state.selectedActiveSorting;
		var asIndex = this.state.selectedActiveSortingIndex;
		var isAsc = as && as.ascending;
		var isDesc = as && ! as.ascending;
		return(
			<div style={{display: 'flex', minHeight: '500px'}}>
				<div style={{width: '35%'}}>
					<div style={{minHeight: '20px'}}>
						{res.labelColumns}
					</div>
					<div style={{minHeight: '480px', border: '1px solid black'}}>
						<SelectionList ref={this.refSorting} items={this.state.listSorting} handleItemSelection={this.handleSortingSelection} />
					</div>
				</div>
				<div style={{width: '90px', marginTop: '20px'}}>
					<div style={{marginBottom: '20px'}}>
						<button disabled={this.state.selectedSortingIndex < 0} 
							style={{width: '50px', marginLeft: '20px', textWeight: 'bold'}}
							onClick={this.addActiveSorting}
						>
							&gt;
						</button>
					</div>
					<div style={{marginBottom: '20px'}}>
						<button disabled={asIndex < 0}
							style={{width: '50px', marginLeft: '20px', textWeight: 'bold'}}
							onClick={this.deleteActiveSorting}
						>
							&lt;
						</button>
					</div>
					<div style={{marginBottom: '20px'}}>
						<button disabled={this.state.listActiveSorting.length == 0}
							style={{width: '50px', marginLeft: '20px', textWeight: 'bold'}}
							onClick={this.deleteAllActiveSorting}
						>
							&lt;&lt;
						</button>
					</div>
				</div>
				<div style={{width: '35%'}}>
					<div style={{minHeight: '20px'}}>
						{res.labelSorting}
					</div>
					<div style={{minHeight: '480px', border: '1px solid black'}}>
						<SelectionList ref={this.refActiveSorting} items={this.state.listActiveSorting} handleItemSelection={this.handleActiveSortingSelection} />
					</div>
				</div>
				<div style={{margin: '20px 10px 0 10px'}}>
					<div style={{marginBottom: '10px'}}>
						<button style={{width: '100px'}}
							disabled={asIndex < 1}
							onClick={this.moveUpActiveSorting}
						>
							{res.buttonMoveUp}
						</button>
					</div>
					<div>
						<button style={{width: '100px'}}
							disabled={asIndex < 0 || asIndex >= this.state.listActiveSorting.length-1}
							onClick={this.moveDownActiveSorting}
						>
							{res.buttonMoveDown}
						</button>
					</div>
					<div style={{marginTop: '40px'}}>
						<input type="radio" id="asc" name="asc" disabled={as == null} checked={isAsc} 
							onChange={this.handleRadioButtonChange} 
						/>
						<label for="asc">{res.rbAscending}</label>
					</div>
					<div style={{marginTop: '10px'}}>
						<input type="radio" id="desc" name="desc" disabled={as == null} checked={isDesc} 
							onChange={this.handleRadioButtonChange} 
						/>
						<label for="desc">{res.rbDescending}</label>
					</div>
				</div>
			</div>
		)
	}
}

export default TabSorting;
