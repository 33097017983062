import React from 'react';
import styles from '../components.module.css';

class SelectionList extends React.Component {
	
	constructor(props) {
		super(props);
		
		var selectedIndex = props.selectedIndex;
		if(selectedIndex == undefined || selectedIndex == null)
			selectedIndex = -1;
		this.state = {
			selectedIndex: selectedIndex
		}
	}

	handleItemClick = (itemIndex, eventClick) => {
		if( itemIndex == this.state.selectedIndex ) {
			if( eventClick.ctrlKey )
				this.setSelectedIndex( -1 )
		} else {
			this.setSelectedIndex( itemIndex )
		}
	}
	handleKeyDown = (event) => {
		if( event.key == 'ArrowUp' ) {
			event.preventDefault(); 
			event.stopPropagation();
			if( this.state.selectedIndex >= 1 ) {
				this.setSelectedIndex( this.state.selectedIndex-1 );
			}
		}
		if( event.key == 'ArrowDown' ) {
			event.preventDefault(); 
			event.stopPropagation();
			if( this.state.selectedIndex >= 0 && this.state.selectedIndex < this.props.items.length-1 ) {
				this.setSelectedIndex( this.state.selectedIndex+1 );
			}
		}
	}
	setSelectedIndex = (index) => {
		this.setState({selectedIndex: index});
		if( this.props.handleItemSelection )
			this.props.handleItemSelection( index );
	}	
	
	render() {
		var itemsHtml = this.props.items.map((item, index) => {
			var tooltipText = item.tooltip;
			var tooltipHtml = (tooltipText) ? <span className={styles.tooltiptext}>{tooltipText}</span> : null;
			var itemStyle = (index == this.state.selectedIndex) ?
					localStyles.divItemSelected : localStyles.divItem; 
			return (
				<div 
					key={index} style={itemStyle} 
					onClick={ (eventClick) => {this.handleItemClick(index, eventClick)}}
				>
					<div className={styles.divWithTooltip}>
						{item.title}
						{tooltipHtml}
					</div>
				</div>
			);
		});
		return (
			<div tabIndex={0} onKeyDown={this.handleKeyDown} style={localStyles.divMain}>
				{itemsHtml}
			</div>
		);
	}
}

const localStyles = {
	divMain: {
		padding: '7px'
	},
	divItem: {
		cursor: 'pointer'
	},
	divItemSelected: {
		backgroundColor: 'lightgray',
		cursor: 'pointer'
	}
}

export default SelectionList;