import React, { Component } from 'react';
import { getCurrentResource } from '../../util/tools';
import PlainFullDescription from '../../components/fulldescription/PlainFullDescription';
import styles from '../../components/fulldescription/FullDescription.module.css';
import { FORM_TOPIC_FT } from '../../util/consts/forms';
import { CLASS_TOPIC } from '../../util/consts/classes';

class TopicPage extends Component {

	constructor(props) {
		super(props);
		this.resource = getCurrentResource().pages.plain.Topic;

		var param = {};
		var encodedParam = props.match.params.param;
		if( encodedParam ) {
			var strParam = decodeURIComponent(encodedParam);
			param = JSON.parse( strParam );
		}

		this.plainInfo = {
			app: props.app,
			page: this,
			class: CLASS_TOPIC,
			instanceModelId: FORM_TOPIC_FT,
			instanceId: props.match.params.id,
			param: param,
			model: {
				fields: [
					{
						path: ["root", "classifierRef"],
						selectInstanceRvContext: {
							disableTree: true,
							explicitConditions: [
								{
									predicateId: "isRoot",
									conditionId: "",
									valueFrom: 1,
									valueTo: 1
								}
							]
						}
					}
				]
			}
		};
	}


	render() {
		return (
			<React.Fragment>
				<div className={styles.divPlainTitle}>
					{this.resource.title}
				</div>
				<PlainFullDescription plain={this.plainInfo} />
			</React.Fragment>
		)
	}
}

export default TopicPage;
