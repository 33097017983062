import React, { createRef } from 'react';

import ButtonIconText from '../../button/ButtonIconText';
import SelectionList from '../../selectionList/SelectionList';
import { getCurrentResource } from '../../../util/tools';

import clearIcon from '../../../assets/icons/png24x24/Delete.png';

class ActiveFilterList extends React.Component {

	/*
		props
			holder
			listActiveFilter
	*/
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.rateview.conditions.ActiveFilterList;
		this.refSelectionList = React.createRef();
	}

	clearSelection = () => {
		this.refSelectionList.current.setSelectedIndex(-1);
	}
	
	clearSelectedFilter = () => {
		var index = this.refSelectionList.current.state.selectedIndex;
		this.clearSelection();
		this.props.holder.clearActiveFilter( index );
	}
	
	handleFilterSelection = (index) => {
		this.props.holder.handleActiveFilterSelection(index);
	}

	render() {
		var res = this.resource;
		var filters = [];
		for(let f of this.props.listActiveFilter)
			filters.push( {id: f.id, title: f.bodyText} );

		return (
			<div style={{marginTop: '10px', marginBottom: '10px'}}>
				<div style={{display: 'flex', alignItems: 'flex-end'}}>
					{res.labelActiveFilterList}
					<div style={{flexGrow: '100'}}>&nbsp;</div>
					<ButtonIconText 
						onClick={this.clearSelectedFilter}
						text={res.buttonClear} tooltip={res.buttonClearTooltip} icon={clearIcon}
					/>
				</div>			
				<div style={{width: '100%', minHeight: '100px', marginTop: '10px', border: '1px solid black'}}>
					<SelectionList ref={this.refSelectionList} items={filters} handleItemSelection={this.handleFilterSelection} />
				</div>
			</div>
		);
	}
}

export default ActiveFilterList;
